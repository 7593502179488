import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { i18nInstance } from '@signifyd/components'
import { listPredictionLists, PredictionList } from '@signifyd/http'

export const PREDICTION_LIST_DETAILS_QUERY_KEY = 'predictionListDetails'

const usePredictionListDetails = (
  listId: number
): UseQueryResult<PredictionList | null> => {
  const navigate = useNavigate()

  return useQuery({
    queryKey: [PREDICTION_LIST_DETAILS_QUERY_KEY, listId],
    enabled: !!listId,
    queryFn: async () => {
      const { data: predictionList } = await listPredictionLists({
        ids: [listId],
        limit: 1,
      })

      return predictionList.data[0]
    },
    onError: () => {
      message.error(
        i18nInstance.t('listCommon.fallbackErrorMessage.getCurrentList')
      )

      navigate('/lists')
    },
  })
}

export default usePredictionListDetails
