import cx from 'classnames'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from 'use-query-params'
import { Layout, PageSpinner, useIsThirdGen } from '@signifyd/components'
import { RuleResponse, RuleSet, SIMULATION_TYPE } from '@signifyd/http'
import useUpdateRuleSet from 'core/queries/useUpdateRuleSet/useUpdateRuleSet'
import { useTypedRouteParams } from 'pages/PublishWithSimulatorPage/hooks'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import HeaderExtra from './HeaderExtra'
import HeaderExtraThirdGen from './HeaderExtraThirdGen'
import PilotHeaderExtra from './PilotHeaderExtra'
import styles from './PublishWithSimulatorContainer.less'
import PublishWithSimulatorPageContent from './PublishWithSimulatorPageContent'
import StepsBar from './StepsBar'

const { Header, Content } = Layout

interface Props {
  isLoading: boolean
  expiredRules: Array<RuleResponse>
  ruleSet?: RuleSet
}

const PublishWithSimulatorPageContainer: FC<Props> = ({
  isLoading,
  expiredRules,
  ruleSet,
}) => {
  const { t } = useTranslation()
  const isThirdGen = useIsThirdGen()

  const { checkpoint } = useTypedRouteParams()
  const [{ editPilot, editedPolicyId, simulationType, step }] = useQueryParams(
    PublishPageQueryParams
  )

  const {
    error,
    isError,
    isLoading: isLoadingMutateRuleSet,
    mutate,
  } = useUpdateRuleSet()

  if (!checkpoint || !step || (!ruleSet && !isLoading)) {
    return null
  }

  const isInPilot = simulationType === SIMULATION_TYPE.PILOT_POLICY

  if (isLoading) {
    return (
      <div data-test-id="publishWithSimulatorPageContainer-spinner">
        <Header
          className={cx({ [styles.pilotHeader]: isInPilot })}
          productName={t('ruleCommon.appName')}
          title={t(`publishWithSimulatorPage.header.title.${checkpoint}`)}
          theme="dark"
          marginBottom="none"
          extra=""
        />
        <PageSpinner />
      </div>
    )
  }

  let headerExtra

  if (isInPilot) {
    headerExtra = <PilotHeaderExtra isEditingPilot={!!editPilot} />
  } else if (isThirdGen) {
    headerExtra = <HeaderExtraThirdGen />
  } else {
    headerExtra = <HeaderExtra />
  }

  return (
    <>
      <Header
        className={cx({ [styles.pilotHeader]: isInPilot })}
        theme="dark"
        productName={t('ruleCommon.appName')}
        title={t(
          isInPilot
            ? 'publishWithSimulatorPage.pilotSimulation.header.title'
            : `publishWithSimulatorPage.header.title.${checkpoint}`
        )}
        marginBottom="none"
        extra={headerExtra}
      />
      <Header paddingTop="none" paddingBottom="none" marginBottom="none">
        <StepsBar
          current={step}
          expiredRules={expiredRules}
          error={error}
          isError={isError}
          isUpdatingRuleSet={isLoadingMutateRuleSet}
        />
      </Header>
      <Content
        className={styles[`contentStep${step}`]}
        innerClassName={styles.innerContent}
      >
        <PublishWithSimulatorPageContent
          isEditing={!!editedPolicyId}
          mutate={mutate}
        />
      </Content>
    </>
  )
}

export default PublishWithSimulatorPageContainer
