import { FC } from 'react'
import { useIsThirdGen } from '@signifyd/components'
import { PredictionList } from '@signifyd/http'
import { PredictionListCard } from 'pages/ListPage/components/PredictionListCard'
import PredictionListCardThirdGen from 'pages/ListPage/components/PredictionListCard/PredictionListCardThirdGen'
import styles from './ListGrids.less'

interface Props {
  predictionLists: Array<PredictionList>
}

const ListGrids: FC<Props> = ({ predictionLists }) => {
  const isThirdGen = useIsThirdGen()

  if (!isThirdGen) {
    return (
      <div className={styles.container}>
        {predictionLists.map((predictionList) => (
          <PredictionListCard
            key={predictionList.id}
            predictionList={predictionList}
          />
        ))}
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {predictionLists.map((predictionList) => (
        <PredictionListCardThirdGen
          key={predictionList.id}
          predictionList={predictionList}
        />
      ))}
    </div>
  )
}

export default ListGrids
