import { FC } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { ListsIcon, Text } from '@signifyd/components'
import { PredictionList } from '@signifyd/http'
import StretchedNoResults from 'core/components/StretchedNoResults'
import useGetPredictionListEntries from 'core/queries/predictionListEntries/useGetPredictionListEntries'
import ListEntriesTable from '../ListEntriesTable/ListEntriesTable'

export interface ListEntriesTableContainerProps {
  predictionList: PredictionList
}

/**
 * Wraps ListEntriesTable to avoid rendering the table if there are no entries yet
 */
const ListEntriesTableContainer: FC<ListEntriesTableContainerProps> = ({
  predictionList,
}) => {
  const { t } = useTranslation()

  const {
    data: predictionListEntries,
    isLoading,
    isSuccess,
  } = useGetPredictionListEntries(predictionList.id)

  const totalPredictionListEntries =
    predictionListEntries?.predictionListEntriesMeta?.totalRows

  const title = isLoading
    ? t('listDetailsPage.listEntriesTable.loading.title')
    : t('listDetailsPage.listEntriesTable.noListEntries.title')

  const description = isLoading ? (
    t('listDetailsPage.listEntriesTable.loading.description')
  ) : (
    <Trans
      i18nKey="listDetailsPage.listEntriesTable.noListEntries.description"
      components={{ bold: <Text weight="semibold" /> }}
    />
  )

  if (!!totalPredictionListEntries && isSuccess) {
    return (
      <ListEntriesTable
        hasAnyPredictionListEntries={!!totalPredictionListEntries}
        predictionList={predictionList}
      />
    )
  }

  return (
    <StretchedNoResults
      title={title}
      description={description}
      icon={ListsIcon}
    />
  )
}

export default ListEntriesTableContainer
