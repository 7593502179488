import cx from 'classnames'
import { FC } from 'react'
import { colorCerulean, colorSlate } from '@signifyd/colors'
import { FilterIcon } from '@signifyd/components'
import styles from './FilterButton.less'

export interface FilterButtonProps
  extends Pick<
    React.HTMLProps<HTMLDivElement>,
    'onMouseEnter' | 'onMouseLeave' | 'onFocus' | 'onClick'
  > {
  selected: boolean
  className?: string
  columnKey: string
}

const FilterButton: FC<FilterButtonProps> = ({
  selected,
  className,
  columnKey,
  ...eventHandlers
}) => {
  const classnames = cx([
    styles.filterIcon,
    selected && styles.filterIconSelected,
    className,
  ])

  return (
    <div
      data-analytics-id={`filterButton-${columnKey}`}
      data-test-id={`filterButton-${columnKey}`}
      className={classnames}
      {...eventHandlers}
    >
      <FilterIcon fill={selected ? colorCerulean : colorSlate} />
    </div>
  )
}

export default FilterButton
