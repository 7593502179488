import {
  isString,
  cond,
  matches,
  constant,
  stubTrue,
  cloneDeep,
  isEmpty,
  isArray,
} from 'lodash'
import { VariableFrontEndType } from '@signifyd/http'
import { LIST_COMPARISON_OPERATOR_NAME } from './conditionTree.constants'
import {
  Predicate,
  ConditionTreeNode,
  ConditionTreeLeafNode,
  SetNodePayload,
  UpdateOperatorAndAppendPayload,
  DeleteChildPayload,
  BOOLEAN_AS_STRING,
  COMPARISON_OPERATOR,
  NumberVariableTypes,
  LOGICAL_OPERATOR,
} from './types'
import {
  isConditionTreeNode,
  getEmptyLeafNode,
  getEmptyNode,
  findNodeById,
} from './utils'

const parseNumber = (value: string, type: NumberVariableTypes): number => {
  const isDouble = type === 'DOUBLE' || type === 'OPTION_DOUBLE'

  return isDouble ? Number(value) : parseInt(value, 10)
}

const parseNumberVariable = (
  value: string | Array<string>,
  type: NumberVariableTypes
): number | Array<number> | null => {
  if (isArray(value)) {
    return value.map((val) => parseNumber(val, type))
  }

  if (!isString(value)) {
    return null
  }

  return parseNumber(value, type)
}

type SerializedLeafValue =
  | boolean
  | number
  | Array<number>
  | string
  | Array<string>
  | null

export function serializeConditionTreeLeafValue({
  ruleFeature,
  value,
}: ConditionTreeLeafNode): SerializedLeafValue {
  if (!ruleFeature || value === null) {
    return null
  }

  switch (ruleFeature.frontEndType) {
    case VariableFrontEndType.BOOLEAN:
      return value === BOOLEAN_AS_STRING.true
    case VariableFrontEndType.CATEGORICAL:
      return value
    case VariableFrontEndType.NUMBER:
      return parseNumberVariable(value, ruleFeature.type as NumberVariableTypes)
    case VariableFrontEndType.BIN:
      return isString(value) ? parseInt(value, 10) : null
    case VariableFrontEndType.STRING:
      return isString(value) ? value : null
    case VariableFrontEndType.STRING_LIST:
      return isString(value) ? value : null
    default:
      return null
  }
}

export function serializeConditionTreeLeaf(
  node: ConditionTreeLeafNode
): Predicate {
  const { ruleFeature, operator, value } = node

  if (
    !operator ||
    !ruleFeature ||
    (value === null && operator !== COMPARISON_OPERATOR.isEmptyAndMeaningless)
  ) {
    return {}
  }

  const { name } = ruleFeature

  return cond<
    {
      isPredictionListFeature: boolean
      isEmptyOperator: boolean
      isNot: boolean
      valueFirst: boolean
    },
    Predicate
  >([
    [
      matches({ isEmptyOperator: true, isNot: false }),
      constant({
        [operator]: [{ property: name }],
      }),
    ],
    [
      matches({ isEmptyOperator: true, isNot: true }),
      constant({
        not: [
          {
            [operator]: [{ property: name }],
          },
        ],
      }),
    ],
    [
      matches({ isPredictionListFeature: true, isNot: false }),
      constant({
        [operator]: [
          { value: node.value },
          { property: node.ruleFeature?.predictionListFeature?.name },
        ],
      }),
    ],
    [
      matches({ isPredictionListFeature: true, isNot: true }),
      constant({
        not: [
          {
            [operator]: [
              { value: node.value },
              { property: node.ruleFeature?.predictionListFeature?.name },
            ],
          },
        ],
      }),
    ],
    [
      matches({ isNot: true, valueFirst: true }),
      constant({
        not: [
          {
            [operator]: [
              { value: serializeConditionTreeLeafValue(node) },
              { property: name },
            ],
          },
        ],
      }),
    ],
    [
      matches({ isNot: false, valueFirst: true }),
      constant({
        [operator]: [
          { value: serializeConditionTreeLeafValue(node) },
          { property: name },
        ],
      }),
    ],
    [
      matches({ isNot: true, valueFirst: false }),
      constant({
        not: [
          {
            [operator]: [
              { property: name },
              { value: serializeConditionTreeLeafValue(node) },
            ],
          },
        ],
      }),
    ],
    [
      stubTrue,
      constant({
        [operator]: [
          { property: name },
          { value: serializeConditionTreeLeafValue(node) },
        ],
      }),
    ],
  ])({
    isPredictionListFeature:
      node.operator === LIST_COMPARISON_OPERATOR_NAME &&
      !!node.ruleFeature?.predictionListFeature,
    isEmptyOperator:
      node.operator === COMPARISON_OPERATOR.isEmptyAndMeaningless &&
      value === null,
    isNot: node.isNot,
    valueFirst: node.operator === COMPARISON_OPERATOR.isSubstringIgnoreCase,
  })
}

export function serializeConditionTree(
  node: ConditionTreeNode | ConditionTreeLeafNode
): Predicate {
  let predicate: Predicate = {}

  if (isConditionTreeNode(node)) {
    if (node.operator && node.children.length > 1) {
      predicate[node.operator] = node.children.map(serializeConditionTree)

      if (node.operator === LOGICAL_OPERATOR.numberOfConditions) {
        predicate[node.operator].unshift({ value: node.numberOfConditions })
      }
    } else {
      const [childNode] = node.children.map(serializeConditionTree)

      predicate = childNode
    }
  } else {
    predicate = serializeConditionTreeLeaf(node)
  }

  return predicate
}

export function setNode(
  root: ConditionTreeNode,
  { nodeId, partialNode }: SetNodePayload
): ConditionTreeNode {
  const cloneRoot = cloneDeep(root)
  const node = findNodeById(cloneRoot, nodeId)

  if (node) {
    Object.assign(node, partialNode)

    return cloneRoot
  }

  return root
}

export function updateOperatorAndAppendNode(
  root: ConditionTreeNode,
  { nodeId, operator }: UpdateOperatorAndAppendPayload
): ConditionTreeNode {
  const cloneRoot = cloneDeep(root)
  const node = findNodeById(cloneRoot, nodeId)

  if (node && isConditionTreeNode(node)) {
    node.operator = operator
    node.children.push(getEmptyNode())

    return cloneRoot
  }

  return root
}

export function updateOperatorAndAppendLeafNode(
  root: ConditionTreeNode,
  { nodeId, operator }: UpdateOperatorAndAppendPayload
): ConditionTreeNode {
  const cloneRoot = cloneDeep(root)
  const node = findNodeById(cloneRoot, nodeId)

  if (node && isConditionTreeNode(node)) {
    node.operator = operator
    node.children.push(getEmptyLeafNode())

    return cloneRoot
  }

  return root
}

export function deleteChild(
  root: ConditionTreeNode,
  { nodeId, childNodeIndex }: DeleteChildPayload
): ConditionTreeNode {
  const cloneRoot = cloneDeep(root)
  const node = findNodeById(cloneRoot, nodeId)

  // Add an empty child if there is not children left, so
  // the UI can still have an empty Input for the user
  // to select the first feature
  if (
    node &&
    isConditionTreeNode(node) &&
    node.children.length >= 1 &&
    childNodeIndex >= 0 &&
    childNodeIndex < node.children.length
  ) {
    const [deletedChild] = node.children.splice(childNodeIndex, 1)

    if (node.children.length === 0) {
      const emptyChild = isConditionTreeNode(deletedChild)
        ? getEmptyNode()
        : getEmptyLeafNode()

      node.children.push(emptyChild)
    } else if (node.children.length === 1) {
      node.operator = null
    }

    return cloneRoot
  }

  return root
}

function isConditionTreeLeafValid({
  ruleFeature,
  operator,
  value,
}: ConditionTreeLeafNode): boolean {
  if (ruleFeature === null || operator === null) {
    return false
  }

  if (operator === COMPARISON_OPERATOR.isEmptyAndMeaningless) {
    return isEmpty(value)
  }

  if (operator === COMPARISON_OPERATOR.isBetween) {
    return isArray(value) && !value.some((val) => !val)
  }

  return !isEmpty(value)
}

export function isConditionTreeValid(root: ConditionTreeNode | null): boolean {
  if (!root) {
    return false
  }
  if (root.children.length < 1) {
    return false
  }

  const notValid = root.children.some((child) => {
    const childNotValid = isConditionTreeNode(child)
      ? !isConditionTreeValid(child)
      : !isConditionTreeLeafValid(child)

    return childNotValid
  })

  return !notValid
}
