import { FileSearchOutlined } from '@ant-design/icons'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ExternalLink,
  getCaseSearchUrl,
  getOrdersUrl,
} from '@signifyd/components'
import { CHECKPOINT } from '@signifyd/http'
import useTeamId from 'pages/DashboardPage/hooks/useTeamId'
import styles from './ViewAffectedOrdersButton.less'

interface Props {
  checkpoint: CHECKPOINT
  dateRange: [string, string]
  isPowerSearchEnabled: boolean
  policyId?: number
}

export const getRedirectUrl = (
  checkpoint: CHECKPOINT,
  dateRange: [string, string],
  isPowerSearchEnabled: boolean,
  teamId?: number,
  policyId?: number
): string => {
  const baseUrl = isPowerSearchEnabled ? getCaseSearchUrl() : getOrdersUrl()

  if (checkpoint === CHECKPOINT.RETURN) {
    return `${baseUrl}?${teamId ? `teamId=${teamId}&` : ''}${
      policyId
        ? `returnRecommendedActionRuleId=${policyId}&`
        : `returnRecommendedActionRuleId=NOT_NULL&`
    }normalizedPurchaseCreatedAt=${dateRange.join(',')}`
  }

  return `${baseUrl}?${teamId ? `teamId=${teamId}&` : ''}${
    policyId
      ? `guaranteeRecommendedActionRuleId=${policyId}&recommendedActionRuleId=${policyId}&`
      : `guaranteeRecommendedActionRuleId=NOT_NULL&recommendedActionRuleId=NOT_NULL&`
  }normalizedPurchaseCreatedAt=${dateRange.join(',')}`
}

const ViewAffectedOrdersButton: FC<Props> = ({
  policyId,
  checkpoint,
  dateRange,
  isPowerSearchEnabled,
}) => {
  const { t } = useTranslation()
  const teamId = useTeamId()

  const url = getRedirectUrl(
    checkpoint,
    dateRange,
    isPowerSearchEnabled,
    !policyId ? teamId : undefined,
    policyId
  )

  return (
    <ExternalLink url={url} className={styles.link}>
      <FileSearchOutlined />
      <span>
        {t(`ruleSummaryPage.policyReports.viewOrdersButton.${checkpoint}`)}
      </span>
    </ExternalLink>
  )
}

export default ViewAffectedOrdersButton
