import { Button, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { useUserContext, useUpdateUserState } from '@signifyd/components'
import { RuleResponse, PolicyMatchId } from '@signifyd/http'
import useFinishPolicyValidation from 'core/hooks/useFinishPolicyValidation/useFinishPolicyValidation'
import styles from './SubmitButton.less'

interface SubmitButtonProps {
  areInputsDirty: boolean
  didValidationsFail: boolean
  loading: boolean
  areValidationsPending: boolean
  areResultsInvalid: boolean
  isEditing: boolean
  policy: RuleResponse
  matchingPolicyMatchIds: Array<PolicyMatchId>
  nonMatchingPolicyMatchIds: Array<PolicyMatchId>
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  loading,
  areInputsDirty,
  didValidationsFail,
  areResultsInvalid,
  areValidationsPending,
  isEditing,
  matchingPolicyMatchIds,
  nonMatchingPolicyMatchIds,
  policy,
}) => {
  const { t } = useTranslation()
  const { user } = useUserContext()
  const { mutate: updateUserState } = useUpdateUserState(user)

  const { rankInActiveRuleSet } = policy

  const handleFinishPolicyValidation = useFinishPolicyValidation({
    isEditing,
    matchingPolicyMatchIds,
    nonMatchingPolicyMatchIds,
    policy,
  })

  const getTooltipTitle = (
    areInputsDirty: boolean,
    didValidationsFail: boolean
  ): string => {
    if (didValidationsFail) {
      return t('ruleValidationPage.tooltipTitles.validationFailure')
    }

    if (areInputsDirty) {
      return t('ruleValidationPage.tooltipTitles.dirtyInputs')
    }

    return ''
  }

  const getButtonText = (): string => {
    if (!isEditing || !rankInActiveRuleSet) {
      return t('ruleValidationPage.finish')
    }

    return t('ruleValidationPage.next')
  }

  const setHomepageToDashboard = (): void => {
    const homepageIsDashboard =
      user?.uiState?.decisionCenter?.homepageIsDashboard ?? false

    if (!user || homepageIsDashboard) {
      return
    }

    const currentUIState = user.uiState?.decisionCenter ?? {}
    const decisionCenterUIState = {
      ...currentUIState,
      homepageIsDashboard: true,
    }
    const uiState = { ...user.uiState, decisionCenter: decisionCenterUIState }

    updateUserState(uiState)
  }

  return (
    <Tooltip title={getTooltipTitle(areInputsDirty, didValidationsFail)}>
      <Button
        data-test-id="finishButton"
        type="primary"
        className={styles.nextBtn}
        loading={loading}
        disabled={
          areInputsDirty ||
          didValidationsFail ||
          areValidationsPending ||
          areResultsInvalid
        }
        onClick={() => {
          handleFinishPolicyValidation()
            .then(() => setHomepageToDashboard())
            .catch((err: Error) => console.log(err))
        }}
      >
        {getButtonText()}
      </Button>
    </Tooltip>
  )
}
