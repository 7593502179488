import { Card } from 'antd'
import { FC } from 'react'
import { colorCerulean } from '@signifyd/colors'
import { Text, IconProps } from '@signifyd/components'
import { joinClassNames } from '@signifyd/utils'
import styles from './CheckpointActionButton.less'

interface Props {
  title: string
  Icon: React.FC<IconProps>
  selected: boolean
  onClick: () => void
}

const CheckpointActionButton: FC<Props> = ({
  selected,
  onClick,
  Icon,
  title,
}) => {
  const cardClassName = joinClassNames([
    styles.card,
    selected && styles.selected,
  ])

  return (
    <Card
      onClick={onClick}
      hoverable={!selected}
      className={cardClassName}
      data-test-id={`checkpoint-action-button-${title}`}
    >
      <div>
        <Icon
          className={styles.icon}
          fill={selected ? colorCerulean : undefined}
        />
      </div>
      <Text className={joinClassNames([styles.label, styles.selectedText])}>
        {title}
      </Text>
    </Card>
  )
}

export default CheckpointActionButton
