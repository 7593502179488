import { ReactNode } from 'react'
import { Text, i18nInstance } from '@signifyd/components'
import { CHECKPOINT } from '@signifyd/http'
import { ColumnProps } from 'antd/es/table'
import { checkpointDistributionsMap } from 'stores/simulation'
import { DistributionTableData } from 'stores/simulation/simulation.types'
import { getDistributionColumnKey } from './SimulationDistributionTable.data'

type ColumnType = ColumnProps<DistributionTableData>

const titleColumnIndex = 'titleColumn'

const totalColumnIndex = 'totalColumn'

const renderCell = (text: string): ReactNode => <Text size="sm">{text}</Text>

const renderLastRowBold =
  (rowCount: number) =>
  (text: string, record: any): ReactNode => {
    if (Number(record.key) === rowCount) {
      return (
        <Text size="sm" weight="semibold">
          {text}
        </Text>
      )
    }

    return renderCell(text)
  }

export const getDistributionTableColumns = (
  checkpoint: CHECKPOINT
): Array<ColumnProps<DistributionTableData>> => {
  const distributionsForCheckpoint = checkpointDistributionsMap[checkpoint]

  // Table has rows for each distribution plus a title and total row
  const rowCount = distributionsForCheckpoint.length + 2

  const titleCol: ColumnType = {
    title: '',
    dataIndex: titleColumnIndex,
    align: 'right',
    render: renderCell,
  }
  const distributionColumns: Array<ColumnType> = distributionsForCheckpoint.map(
    (distribution) => ({
      title: i18nInstance.t(
        `publishWithSimulatorPage.simulationDistributionTable.${distribution}`
      ),
      dataIndex: [
        'distributionColumns',
        getDistributionColumnKey(distribution),
      ],
      align: 'right',
      render: renderLastRowBold(rowCount),
    })
  )
  const totalCol: ColumnType = {
    title: i18nInstance.t(
      'publishWithSimulatorPage.simulationDistributionTable.total'
    ),
    dataIndex: totalColumnIndex,
    align: 'right',
    render: renderLastRowBold(rowCount),
  }

  return [titleCol, ...distributionColumns, totalCol]
}

export const getDistributionTitleRow = (
  checkpoint: CHECKPOINT
): DistributionTableData => {
  const distributionsForCheckpoint = checkpointDistributionsMap[checkpoint]
  const distributionColumns = distributionsForCheckpoint.reduce<
    Record<string, string>
  >(
    (columnsSoFar, distribution) => ({
      ...columnsSoFar,
      [getDistributionColumnKey(distribution)]: i18nInstance.t(
        `publishWithSimulatorPage.simulationDistributionTable.${distribution}`
      ),
    }),
    {}
  )

  return {
    key: '1',
    titleColumn: ' ',
    distributionColumns,
    totalColumn: i18nInstance.t(
      'publishWithSimulatorPage.simulationDistributionTable.total'
    ),
  }
}
