import { Spin, Layout, Flex } from 'antd'
import { FC } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { spacingLG } from '@signifyd/ant'
import { ListsIcon, Text, TitleThirdGen } from '@signifyd/components'
import StretchedNoResults from 'core/components/StretchedNoResults'
import TeamSelect from 'core/components/TeamSelect'
import TestTeamBanner from 'core/components/TestTeamBanner'
import PageWrapper from 'core/containers/PageWrapper/PageWrapper'
import useUserPermissions from 'core/hooks/useUserPermissions'
import CreateListButtonWithModal from 'pages/ListPage/containers/CreateListButtonWithModal'
import ListFilters from 'pages/ListPage/containers/ListFilters'
import { ListGrids, ListLoadingGrid } from 'pages/ListPage/containers/ListGrids'
import { useGetLists } from 'pages/ListPage/useGetLists'
import { useListPageQueryParams } from 'pages/ListPage/useListPageQueryParams'
import { Filters } from 'stores/predictionList'
import styles from './ListPageContainer.less'

const { Content } = Layout

interface Props {
  filters: Filters
}

const ListPageContainerThirdGen: FC<Props> = ({ filters }) => {
  const { t } = useTranslation()

  const [, setQuery] = useListPageQueryParams()

  const { data, isLoading, fetchStatus } = useGetLists(filters)
  const { userCanEdit } = useUserPermissions()

  let pageContent

  if (!data || isLoading) {
    pageContent = <ListLoadingGrid />
  } else if (data.predictionLists.length === 0) {
    // Show no lists page if the team doesn't have any lists at all.
    pageContent = (
      <StretchedNoResults
        icon={ListsIcon}
        title={t('listPage.placeholderTitle')}
        description={
          <Trans
            i18nKey="listPage.placeholderDescription"
            components={{ bold: <Text weight="semibold" /> }}
          />
        }
      />
    )
  } else if (data.filteredPredictionLists.length === 0) {
    // Show no results page when the GET call finishes but no lists returned.
    pageContent = (
      <StretchedNoResults
        title={t('listPage.noResultsTitle')}
        description={t('listPage.noResultsDescription')}
      />
    )
  } else {
    // Show real lists when the GET call finishes and there are lists returned.
    pageContent = <ListGrids predictionLists={data.filteredPredictionLists} />
  }

  const { listPageSortByFilter, listPageTypeFilter, listPageExpirationFilter } =
    filters

  const teamId = Number(filters.teamId)

  return (
    <PageWrapper teamId={teamId}>
      <div className={styles.pageContainer}>
        <TestTeamBanner teamId={teamId} />
        <Flex className={styles.header} justify="space-between" wrap>
          <TitleThirdGen>{t('listCommon.appName')}</TitleThirdGen>
          <Flex justify="flex-start" gap={spacingLG} wrap>
            <TeamSelect
              label={t('common.teamSelect.label')}
              selectedTeamId={filters.teamId}
              onChange={(teamId) => {
                if (teamId) {
                  setQuery({ teamId: +teamId })
                }
              }}
            />
            {userCanEdit && <CreateListButtonWithModal filters={filters} />}
          </Flex>
        </Flex>
        <div data-test-id="listPage" className={styles.content}>
          {!!data?.predictionLists.length && (
            <ListFilters
              predictionLists={data.predictionLists}
              predictionListSortBy={listPageSortByFilter}
              predictionListType={listPageTypeFilter}
              predictionListExpiration={listPageExpirationFilter}
              onChangeSortBy={(predictionListSortBy) =>
                setQuery({ sortBy: predictionListSortBy })
              }
              onChangeType={(predictionListType) =>
                setQuery({ type: predictionListType })
              }
              onChangeExpiration={(predictionListExpiration) =>
                setQuery({
                  hasExpiration: predictionListExpiration,
                })
              }
            />
          )}
        </div>
        {fetchStatus === 'fetching' && data ? (
          <Spin>
            <Content className={styles.content}>{pageContent}</Content>
          </Spin>
        ) : (
          <Content className={styles.listPageContainer}>{pageContent}</Content>
        )}
      </div>
    </PageWrapper>
  )
}

export default ListPageContainerThirdGen
