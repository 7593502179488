import { FC } from 'react'
import { Unauthorized, useIsThirdGen } from '@signifyd/components'
import NavBar from '@signifyd/components/src/components/Layout/_NavBar'
import AppNavBar from 'core/containers/AppNavBar'

interface UnauthorizedLayout {
  message?: string
}

export const UnauthorizedLayout: FC<UnauthorizedLayout> = ({ message }) => {
  const isThirdGen = useIsThirdGen()

  if (!isThirdGen) {
    return (
      <NavBar>
        <AppNavBar />
        <Unauthorized message={message} />
      </NavBar>
    )
  }

  return (
    <>
      <Unauthorized message={message} />
    </>
  )
}
