import { Button, Popover, Select } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Space } from '@signifyd/components'
import useGetVariableGroups from 'pages/AdminPages/queries/useGetDecisionCenterGroups'
import { useAddVariableToGroup } from 'pages/AdminPages/queries/useGetDecisionCenterVariables'
import styles from './AddVariableToGroupPopover.less'

interface Props {
  variableId: number
  variableName: string
}

const AddVariableToGroupPopover: FC<Props> = ({ variableId, variableName }) => {
  const { t } = useTranslation()

  const { data: groups = [] } = useGetVariableGroups()

  const addVariableToGroup = useAddVariableToGroup()

  const [isOpen, setIsOpen] = useState(false)
  const [groupIds, setGroupIds] = useState<Array<number>>([])

  useEffect(() => {
    const alreadySelectedGroups = groups.filter((group) =>
      group.variableIds.includes(variableId)
    )

    setGroupIds(alreadySelectedGroups.map((group) => group.id))
  }, [groups, setGroupIds, variableId])

  const handleAddToGroups = async (): Promise<void> => {
    await addVariableToGroup.mutateAsync({
      groupIds,
      variableId,
    })

    setIsOpen(false)
  }

  const handleCancel = (): void => {
    setIsOpen(false)
  }

  return (
    <Popover
      visible={isOpen}
      title={
        <div className={styles.title}>
          <div className={styles.label}>
            {t('variablePage.addVariableToGroupPopover.title')}
          </div>
          <div className={styles.variableName} title={variableName}>
            {variableName}
          </div>
        </div>
      }
      placement="left"
      trigger="click"
      overlayClassName={styles.overlay}
      onOpenChange={(visible) => {
        if (!visible) {
          setGroupIds([])
        }

        setIsOpen(visible)
      }}
      destroyTooltipOnHide
      content={
        <div>
          <Space size="sm" />
          <div>
            <Select
              value={groupIds}
              mode="multiple"
              className={styles.groupSelect}
              onChange={(value) => {
                setGroupIds(value)
              }}
            >
              {groups?.map(({ id, name }) => (
                <Select.Option value={id} key={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <Space size="sm" />
          <div className={styles.buttons}>
            <Button type="primary" onClick={handleAddToGroups}>
              {t('variablePage.addVariableToGroupPopover.updateButton')}
            </Button>
            <Button onClick={handleCancel}>
              {t('variablePage.addVariableToGroupPopover.cancelButton')}
            </Button>
          </div>
        </div>
      }
    >
      <Button onClick={() => setIsOpen(true)}>
        {t('variablePage.addVariableToGroupPopover.editButton')}
      </Button>
    </Popover>
  )
}

export default AddVariableToGroupPopover
