import { message } from 'antd'
import { AxiosError } from 'axios'
import { action, thunk, computed } from 'easy-peasy'
import { i18nInstance } from '@signifyd/components'
import {
  RuleSimulation,
  CreateSimulationRequest,
  createRuleSimulation as createRuleSimulationHTTP,
  cancelRuleSimulation as cancelRuleSimulationHTTP,
  getRuleSimulation as getRuleSimulationHTTP,
  pollSimulation as pollSimulationHTTP,
  PollSimulationParams,
  SIMULATION_STATUS,
  SIMULATION_TYPE,
} from '@signifyd/http'
import { getHTTPErrorStatus } from 'core/http'
import { DEFAULT_POLL_INTERVAL_MS } from './simulation.constants'
import { DEFAULT_STATE } from './simulation.store.state'
import {
  getFormattedCountImpact,
  getFormattedGmvImpact,
  getFormattedCountImpactPerRule,
  getFormattedGmvImpactPerRule,
} from './simulation.store.utils'
import { SimulationState, SimulationModel } from './simulation.types'

const NO_ORDERS_KEYS = 'noOrdersInRange'

const mergeStateAndActions = (state: SimulationState): SimulationModel => ({
  ...state,

  // ANCHOR actions
  resetState: action((state) => {
    Object.assign(state, DEFAULT_STATE)
  }),

  setState: action((state, newState) => {
    Object.assign(state, newState)
  }),

  setLoading: action((state, newLoadingStatus) => {
    state.isLoading = { ...state.isLoading, ...newLoadingStatus }
  }),

  stopPollAction: action((state) => {
    if (state.stopPoll) {
      state.stopPoll()
    }
  }),

  // ANCHOR computed
  formattedCountImpact: computed((state) => {
    return getFormattedCountImpact(
      state.ruleSimulation?.originalSimulationMetrics,
      state.ruleSimulation?.newSimulationMetrics,
      i18nInstance.language
    )
  }),

  formattedGmvImpact: computed((state) => {
    return getFormattedGmvImpact(
      state.ruleSimulation?.originalSimulationMetrics,
      state.ruleSimulation?.newSimulationMetrics,
      i18nInstance.language
    )
  }),

  formattedCountImpactPerRule: computed((state) => {
    return getFormattedCountImpactPerRule(
      state.ruleSimulation?.ruleTriggerMetrics || [],
      i18nInstance.language
    )
  }),

  formattedGmvImpactPerRule: computed((state) => {
    return getFormattedGmvImpactPerRule(
      state.ruleSimulation?.ruleTriggerMetrics || [],
      i18nInstance.language
    )
  }),

  // ANCHOR thunks
  createRuleSimulation: thunk(
    (actions, createSimulationRequest: CreateSimulationRequest) => {
      actions.setLoading({ createRuleSimulation: true })

      let noInvestigations = false

      return createRuleSimulationHTTP(createSimulationRequest)
        .then(async ({ data }) => {
          const { simulationId } = data

          actions.setState({ simulationId })

          await actions.getRuleSimulation(simulationId)

          switch (createSimulationRequest.simulationType) {
            case SIMULATION_TYPE.CUSTOMER_POLICY:
              actions.pollSimulation({ simulationId })
              break
            case SIMULATION_TYPE.PILOT_POLICY:
              message.success(
                i18nInstance.t(
                  'publishWithSimulatorPage.pilotSimulation.pilotCreatedMessage'
                )
              )
              break
            default:
              break
          }
        })
        .catch((error) => {
          if (error?.response?.status === 404) {
            noInvestigations = Object.keys(
              error?.response?.data?.errors
            ).includes(NO_ORDERS_KEYS)
          } else {
            const fallback = i18nInstance.t(
              'publishWithSimulatorPage.fallbackErrorMessage.pollSimulation'
            )
            const status = getHTTPErrorStatus(error, fallback)

            message.error({ content: status.error })
          }

          actions.setState({ ruleSimulation: null })
        })
        .finally(() => {
          actions.setState({ noInvestigations })
          actions.setLoading({ createRuleSimulation: false })
        })
    }
  ),

  cancelRuleSimulation: thunk((actions, simulationId: number) => {
    actions.setLoading({ cancelRuleSimulation: true })

    return cancelRuleSimulationHTTP(simulationId)
      .then(() => {
        actions.resetState()
      })
      .catch((error) => {
        // TODO FET-853 https://signifyd.atlassian.net/browse/FET-853
        //  need to handle get rule simulation error better in React query migration
        console.log(error)
      })
      .finally(() => {
        actions.setLoading({ cancelRuleSimulation: false })
      })
  }),

  getRuleSimulation: thunk((actions, simulationId: number) => {
    actions.setLoading({ getRuleSimulation: true })

    return getRuleSimulationHTTP(simulationId)
      .then(({ data }) => {
        if (data.status === SIMULATION_STATUS.CANCELED) {
          return
        }

        actions.setState({ ruleSimulation: data })
      })
      .catch((error) => {
        // TODO FET-853 https://signifyd.atlassian.net/browse/FET-853
        //  need to handle get rule simulation error better in React query migration
        console.log(error)
      })
      .finally(() => {
        actions.setLoading({ getRuleSimulation: false })
      })
  }),

  // pollSimulation is a thunk b/c the callback functions(onProgress, onFinish,
  // onError) need the ability to modify the state at a later time(via actions.setState)
  pollSimulation: thunk(
    (actions, { simulationId, pollIntervalMS = DEFAULT_POLL_INTERVAL_MS }) => {
      const { FINISHED, CANCELED } = SIMULATION_STATUS

      const pollSimulationParams: PollSimulationParams = {
        onProgress: (progressInDecimal: number) => {
          actions.setState({
            progressInDecimal,
            noInvestigations: false,
          })
        },
        onFinish: (
          ruleSimulation: RuleSimulation,
          status:
            | SIMULATION_STATUS.FINISHED
            | SIMULATION_STATUS.CANCELED
            | SIMULATION_STATUS.FAILED
        ) => {
          let progressInDecimal = null
          if (status === FINISHED) {
            progressInDecimal = 1
          } else if (status === CANCELED) {
            progressInDecimal = null
            message.error({
              content: i18nInstance.t(
                'publishWithSimulatorPage.fallbackErrorMessage.cancelSimulation'
              ),
            })
          } else {
            progressInDecimal = null
            message.error({
              content: i18nInstance.t(
                'publishWithSimulatorPage.fallbackErrorMessage.pollSimulation'
              ),
            })
          }

          actions.setState({
            stopPoll: null,
            ruleSimulation,
            progressInDecimal,
            noInvestigations: false,
          })
        },
        onError: (error) => {
          actions.setState({
            stopPoll: null,
            progressInDecimal: null,
            noInvestigations: false,
          })

          const status = getHTTPErrorStatus(
            error as AxiosError,
            i18nInstance.t(
              'publishWithSimulatorPage.fallbackErrorMessage.pollSimulation'
            )
          )

          message.error({ content: status.error })
        },
        pollIntervalMS,
      }
      const stopPoll = pollSimulationHTTP(simulationId, pollSimulationParams)

      actions.setState({ stopPoll })
    }
  ),
})

export default mergeStateAndActions
