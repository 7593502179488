import { Select } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { InputLabel } from '@signifyd/components'
import { getTimeframeOptions, TIMEFRAME } from '../PolicyReporting.utils'
import styles from './TimeframeSelect.less'

interface Props {
  setTimeframe: (value: TIMEFRAME) => void
  timeframe: TIMEFRAME
}

const TimeframeSelect: FC<Props> = ({ setTimeframe, timeframe }) => {
  const { t } = useTranslation()

  return (
    <>
      <InputLabel className={styles.timeframeLabel}>
        {t('common.policyReporting.timeframe.label')}
      </InputLabel>
      <Select<TIMEFRAME>
        className={styles.timeframeSelect}
        value={timeframe}
        onChange={setTimeframe}
      >
        {getTimeframeOptions().map(({ value, label }) => (
          <Select.Option
            key={value}
            value={value}
            title={label}
            data-test-id={`selectOption-${value}`}
          >
            {label}
          </Select.Option>
        ))}
      </Select>
    </>
  )
}

export default TimeframeSelect
