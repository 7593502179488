import { UseMutationResult, useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from 'use-query-params'
import {
  CHECKPOINT,
  RULE_SET_STAGE,
  RULE_SET_TYPE,
  RULE_STATUS,
  RuleResponse,
  RuleSet,
  createRuleSet,
} from '@signifyd/http'
import { rejectOnError } from 'core/http'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'

export const PUBLISHED_STATUSES = [
  RULE_STATUS.ACTIVE,
  RULE_STATUS.EXPIRED,
  RULE_STATUS.SCHEDULED,
]

interface CreateRuleSetPayload {
  rules: Array<RuleResponse>
  teamId: number
  checkpoint: CHECKPOINT | undefined
  isForPilotSimulation?: boolean
}

const useCreateRuleSet = (): UseMutationResult<
  RuleSet,
  AxiosError,
  CreateRuleSetPayload
> => {
  const { t } = useTranslation()
  const [{ selectedRuleId, selectedRuleAction }] = useQueryParams(
    PublishPageQueryParams
  )

  const isChangeRulePublishedStatus = selectedRuleAction !== 'CHANGE_RANK'

  return useMutation({
    mutationFn: async (createRuleSetPayload) => {
      const { rules, teamId, checkpoint, isForPilotSimulation } =
        createRuleSetPayload

      const selectedRule = rules.find((rule) => rule.ruleId === selectedRuleId)

      if (selectedRule && isChangeRulePublishedStatus) {
        selectedRule.ruleStatus = PUBLISHED_STATUSES.includes(
          selectedRule.ruleStatus
        )
          ? RULE_STATUS.INACTIVE
          : RULE_STATUS.ACTIVE
      }

      const publishedRules = rules.filter((rule) =>
        PUBLISHED_STATUSES.includes(rule.ruleStatus)
      )

      const ruleIds = isForPilotSimulation
        ? rules.map(({ ruleId }) => ruleId)
        : publishedRules.map(({ ruleId }) => ruleId)

      const { data: ruleSet } = await createRuleSet({
        ruleSetStage: RULE_SET_STAGE.DRAFT,
        ruleSetType: RULE_SET_TYPE.EXTERNAL_RULES,
        ruleIds,
        teamId,
        checkpoint,
      })

      return ruleSet
    },
    onError: () => {
      rejectOnError(
        t('ruleCommon.fallbackErrorMessage.createDraftRuleSetAndListAllRules')
      )
    },
  })
}

export default useCreateRuleSet
