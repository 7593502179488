import { useQuery, UseQueryResult } from '@tanstack/react-query'
import {
  getRuleDuplicationAnalysis,
  RuleDuplicationAnalysisResponse,
} from '@signifyd/http'

export const GET_RULE_DUPLICATION_ANALYSIS_QUERY_KEY =
  'getRuleDuplicationAnalysis'

const useGetRuleDuplicationAnalysis = (
  policyId?: string | number
): UseQueryResult<RuleDuplicationAnalysisResponse> => {
  return useQuery<RuleDuplicationAnalysisResponse>({
    queryKey: [GET_RULE_DUPLICATION_ANALYSIS_QUERY_KEY, policyId],
    queryFn: async () => {
      const { data } = await getRuleDuplicationAnalysis(policyId!)

      return data
    },
    enabled: !!policyId,
  })
}

export default useGetRuleDuplicationAnalysis
