import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from 'use-query-params'
import {
  RULE_SET_STAGE,
  RULE_SET_UPDATABLE_FIELD,
  RuleSet,
  updateRuleSet,
} from '@signifyd/http'
import { messageOnError } from 'core/http'
import { GET_RULE_SET } from 'core/queries/useGetRuleSet/useGetRuleSet'
import { LIST_ALL_RULES } from 'pages/PublishWithSimulatorPage/hooks/useGetAllRules/useGetAllRules'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'

const usePublishRuleSet = (
  editedRuleId?: number | null
): UseMutationResult<
  RuleSet,
  AxiosError<unknown>,
  {
    ruleSet: RuleSet
    editedRuleId?: number | null
  }
> => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const [, setFilters] = useQueryParams(PublishPageQueryParams)

  return useMutation({
    mutationFn: async ({ ruleSet }) => {
      const publishRuleSetPayload = {
        ruleSetStage: RULE_SET_STAGE.PUBLISHED,
        ruleIds: ruleSet.ruleIds,
        updateMask: [RULE_SET_UPDATABLE_FIELD.RULE_SET_STAGE],
        editedRuleId: editedRuleId ?? undefined,
      }

      const { data } = await updateRuleSet(
        ruleSet.ruleSetId,
        publishRuleSetPayload
      )

      return data
    },
    onSuccess: async (ruleSet) => {
      await queryClient.invalidateQueries([GET_RULE_SET, ruleSet.ruleSetId])
      await queryClient.invalidateQueries([LIST_ALL_RULES])
      setFilters({ simulationId: undefined })
    },
    onError: () => {
      messageOnError(t('ruleCommon.fallbackErrorMessage.publishRuleSet'))
    },
  })
}

export default usePublishRuleSet
