import { Col, Row } from 'antd'
import { FC } from 'react'
import { useStoreState } from 'stores'
import { Space, useIsThirdGen } from '@signifyd/components'
import { RuleSet, SIMULATION_STATUS, SIMULATION_TYPE } from '@signifyd/http'
import { PilotDashboardBanner } from 'pages/DashboardPage/components/PilotDashboardBanner/PilotDashboardBanner'
import { hasMatchingRuleIds } from 'stores/simulation/simulation.store.utils'
import DistributionCard from '../DistributionCard'
import ImpactPerPolicyCard from '../ImpactPerPolicyCard'
import SimulationResultInfoMessage from '../SimulationResultInfoMessage'
import SimulationResultWarning from '../SimulationResultWarning'
import TotalImpactCard from '../TotalImpactCard'
import TotalImpactCardThirdGen from '../TotalImpactCard/TotalImpactCardThirdGen'

interface Props {
  ruleSet?: RuleSet
}

const SimulationResultContainer: FC<Props> = ({ ruleSet, children }) => {
  const isThirdGen = useIsThirdGen()

  const { ruleSimulation } = useStoreState((state) => state.simulationModel)

  const matchingRuleIds = hasMatchingRuleIds(ruleSimulation, ruleSet)

  if (!ruleSimulation || !ruleSet) {
    return null
  }

  const {
    checkpoint,
    simulationSize,
    simulationSizeLimit,
    simulationType,
    status,
  } = ruleSimulation

  const isPilotRunning =
    simulationType === SIMULATION_TYPE.PILOT_POLICY &&
    status === SIMULATION_STATUS.RUNNING

  const noNewPilotUpdates = isPilotRunning && simulationSize === 0

  const matchingRuleAndNotPilot =
    matchingRuleIds && simulationType !== SIMULATION_TYPE.PILOT_POLICY

  const showInfoMessage =
    simulationSize === simulationSizeLimit &&
    simulationType !== SIMULATION_TYPE.PILOT_POLICY

  return (
    <div data-test-id="simulationResultContainer">
      <Space size="lg" />
      {children}
      <Space size="lg" />
      {showInfoMessage && (
        <SimulationResultInfoMessage
          simulationSizeLimit={simulationSizeLimit}
          notMatchingRuleIds={!matchingRuleIds}
        />
      )}
      <SimulationResultWarning
        hasMatchingRuleIds={matchingRuleIds}
        simulationType={simulationType}
      />
      {simulationType === SIMULATION_TYPE.PILOT_POLICY && (
        <PilotDashboardBanner
          pilotSimulation={ruleSimulation}
          checkpoint={checkpoint!}
        />
      )}
      {(matchingRuleAndNotPilot ||
        simulationType === SIMULATION_TYPE.PILOT_POLICY) && <Space size="lg" />}
      <Row align="stretch" gutter={[32, 32]}>
        <Col span={24}>
          {isThirdGen ? (
            <TotalImpactCardThirdGen
              ruleSimulation={ruleSimulation}
              isPilotRunning={isPilotRunning}
            />
          ) : (
            <TotalImpactCard
              ruleSimulation={ruleSimulation}
              isPilotRunning={isPilotRunning}
            />
          )}
        </Col>
        <Col span={24}>
          <DistributionCard pilotHasNoData={noNewPilotUpdates} />
        </Col>
        <Col span={24}>
          <ImpactPerPolicyCard pilotHasNoData={noNewPilotUpdates} />
        </Col>
      </Row>
      <Space size="lg" />
    </div>
  )
}

export default SimulationResultContainer
