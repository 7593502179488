import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { message } from 'antd'
import { AxiosError } from 'axios'
import { i18nInstance } from '@signifyd/components'
import { RuleResponse, updateRule } from '@signifyd/http'
import { getHTTPErrorStatus } from 'core/http'
import { GET_DASHBOARD_POLICIES_QUERY_KEY } from './useGetDashboardPolicies/useGetDashboardPolicies'

type UpdatedPolicyPayload = Partial<RuleResponse> & {
  ruleId: number
}

export const useUpdatePolicyInDashboard = (): UseMutationResult<
  void,
  AxiosError,
  UpdatedPolicyPayload
> => {
  const queryClient = useQueryClient()

  return useMutation(
    async (updatedPolicy) => {
      const { ruleId, ...rule } = updatedPolicy

      await updateRule(ruleId, rule)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_DASHBOARD_POLICIES_QUERY_KEY])
      },
      onError: (error) => {
        const status = getHTTPErrorStatus(
          error,
          i18nInstance.t('ruleCommon.fallbackErrorMessage.updateRule')
        )

        message.error({ content: status.error })
      },
    }
  )
}
