import { Modal } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'stores'
import { useIsThirdGen } from '@signifyd/components'
import { RuleResponse } from '@signifyd/http'
import PolicyDetailsForm, {
  PolicyDetails,
  usePolicyDetailsFormInstance,
} from 'pages/DashboardPage/components/PolicyDetailsForm/PolicyDetailsForm'

interface Props {
  visible: boolean
  policyToEdit: RuleResponse
  onCancel: () => void
  onSubmit: (policyDetails: PolicyDetails) => void
}

const EditPolicyModal: FC<Props> = ({
  visible,
  onCancel,
  onSubmit,
  policyToEdit,
}) => {
  const { t } = useTranslation()
  const isThirdGen = useIsThirdGen()

  const title = t('ruleCommon.editRuleModal.title')

  const { error, pending: loading } = useStoreState(
    (state) => state.ruleModel.policyHTTPStatuses.updatePolicy
  )

  const editPolicyFormInstance = usePolicyDetailsFormInstance(
    policyToEdit,
    onSubmit
  )

  return (
    <Modal
      width={604}
      open={visible}
      closable={false}
      title={title}
      maskClosable={false}
      okText={t('ruleCommon.editRuleModal.okText')}
      okButtonProps={{
        loading,
        type: isThirdGen ? 'primary' : 'link',
        disabled:
          !editPolicyFormInstance.dirty || !editPolicyFormInstance.isValid,
      }}
      cancelButtonProps={{
        type: 'link',
      }}
      cancelText={t('ruleCommon.ruleDetailsModal.cancelText')}
      onCancel={onCancel}
      onOk={() => {
        editPolicyFormInstance.handleSubmit()
      }}
      afterClose={() => {
        editPolicyFormInstance.resetForm()
      }}
    >
      <PolicyDetailsForm
        loading={loading}
        error={error}
        formInstance={editPolicyFormInstance}
      />
    </Modal>
  )
}

export default EditPolicyModal
