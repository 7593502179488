import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { getCheckpointActionData } from '@signifyd/components'
import { CHECKPOINT_ACTION } from '@signifyd/http'

interface Props {
  action: CHECKPOINT_ACTION | null
}

const RuleRecommendedActionDisplay: FC<Props> = ({ action }) => {
  const { t } = useTranslation()

  return (
    action && (
      <span
        style={{
          color: getCheckpointActionData(action).textColor,
        }}
      >
        {t(`checkpointAction.${action}`)}
      </span>
    )
  )
}

export default RuleRecommendedActionDisplay
