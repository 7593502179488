import { Modal, Alert, Button, Flex } from 'antd'
import { cond, constant, matches, stubTrue } from 'lodash'
import { FC, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Space, useIsThirdGen } from '@signifyd/components'
import { PredictionList } from '@signifyd/http'
import CloseListEntriesModalPopconfirm from 'core/components/CloseListEntriesModalPopconfirm'
import {
  MAX_MANUAL_LIST_ENTRY_ITEMS,
  SIG_MODAL_PROPS,
  UPLOAD_ENTRIES_METHOD,
} from 'core/constants'
import {
  useReplacePredictionListEntriesWithCSV,
  useReplacePredictionListEntriesWithJSON,
} from 'core/queries/predictionListEntries/useReplacePredictionListEntries'
import ListEntriesForm from 'pages/ListDetailsPage/components/ListEntriesForm'
import { RcFile } from 'antd/es/upload'
import styles from './ReplaceListEntriesModal.less'

export interface Props {
  visible: boolean
  onClose: () => void
  predictionList: PredictionList
}

const ReplaceListEntriesModal: FC<Props> = ({
  onClose,
  predictionList,
  visible,
}) => {
  const { t } = useTranslation()
  const isThirdGen = useIsThirdGen()
  const [uploadEntriesMethod, setUploadEntriesMethod] = useState<
    UPLOAD_ENTRIES_METHOD | undefined
  >()
  const [manualInputValue, setManualInputValue] = useState<Array<string>>([])
  const [csvFile, setCSVFile] = useState<RcFile | undefined>()
  const [isPartialComplete, setIsPartialComplete] = useState(false)

  const replacePredictionListEntriesWithCSV =
    useReplacePredictionListEntriesWithCSV()

  const replacePredictionListEntriesWithJSON =
    useReplacePredictionListEntriesWithJSON()

  const resetState = useCallback((): void => {
    setUploadEntriesMethod(undefined)
    setManualInputValue([])
    setCSVFile(undefined)
  }, [setUploadEntriesMethod, setManualInputValue, setCSVFile])

  const okButtonEnabled = cond([
    [
      matches({
        uploadEntriesMethod: UPLOAD_ENTRIES_METHOD.MANUAL,
        manualInputValueValid: true,
      }),
      constant(true),
    ],
    [
      matches({
        uploadEntriesMethod: UPLOAD_ENTRIES_METHOD.CSV,
        csvValid: true,
      }),
      constant(true),
    ],
    [stubTrue, constant(false)],
  ])({
    uploadEntriesMethod,
    manualInputValueValid:
      manualInputValue.length > 0 &&
      manualInputValue.length <= MAX_MANUAL_LIST_ENTRY_ITEMS,
    csvValid: !!csvFile,
  })

  const onUploadWithJSON = (values: Array<string>): void => {
    replacePredictionListEntriesWithJSON.mutate({
      predictionListId: predictionList.id,
      type: predictionList.type,
      values,
      onSuccessCallback: onClose,
    })
  }

  const onUploadWithCSV = (file: RcFile): void => {
    replacePredictionListEntriesWithCSV.mutate({
      predictionListId: predictionList.id,
      type: predictionList.type,
      file,
      onSuccessCallback: onClose,
    })
  }

  const onOk = (): void => {
    if (
      uploadEntriesMethod === UPLOAD_ENTRIES_METHOD.MANUAL &&
      okButtonEnabled
    ) {
      onUploadWithJSON(manualInputValue)
    } else if (uploadEntriesMethod === UPLOAD_ENTRIES_METHOD.CSV && csvFile) {
      onUploadWithCSV(csvFile)
    }
  }

  const onCancel = (): void => {
    if (!isPartialComplete) {
      onClose()
    }
  }

  return (
    <Modal
      {...SIG_MODAL_PROPS}
      okButtonProps={{
        disabled: !okButtonEnabled,
        loading:
          replacePredictionListEntriesWithJSON.isLoading ||
          replacePredictionListEntriesWithCSV.isLoading,
      }}
      title={t('listDetailsPage.replaceListEntriesModal.title')}
      open={visible}
      afterClose={resetState}
      footer={
        <Flex justify="space-between" className={styles.modalFooter}>
          <CloseListEntriesModalPopconfirm
            disabled={!isPartialComplete}
            onConfirm={onCancel}
          >
            <Button onClick={onCancel} type="link">
              {t('listDetailsPage.replaceListEntriesModal.cancelText')}
            </Button>
          </CloseListEntriesModalPopconfirm>
          <Button
            disabled={!okButtonEnabled}
            type={isThirdGen ? 'primary' : 'link'}
            onClick={onOk}
          >
            {t('listDetailsPage.replaceListEntriesModal.okText')}
          </Button>
        </Flex>
      }
    >
      <Alert
        message={t('listDetailsPage.replaceListEntriesModal.info')}
        type="info"
        showIcon
      />
      <Space size="md" />
      <ListEntriesForm
        disabled={
          replacePredictionListEntriesWithJSON.isLoading ||
          replacePredictionListEntriesWithCSV.isLoading
        }
        title={t('listDetailsPage.replaceListEntriesModal.description')}
        uploadEntriesMethod={uploadEntriesMethod}
        manualInputValue={manualInputValue}
        manualInputValueCountLimit={MAX_MANUAL_LIST_ENTRY_ITEMS}
        manualInputError={replacePredictionListEntriesWithJSON.error?.message}
        csvFile={csvFile}
        csvError={replacePredictionListEntriesWithCSV.error?.message}
        onChangeUploadEntriesMethod={setUploadEntriesMethod}
        onChangeManualInputValue={setManualInputValue}
        onChangeCSVFile={setCSVFile}
        onPartialEntry={setIsPartialComplete}
        predictionListType={predictionList.type}
      />
    </Modal>
  )
}

export default ReplaceListEntriesModal
