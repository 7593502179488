import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { message } from 'antd'
import { AxiosError, AxiosResponse } from 'axios'
import { i18nInstance } from '@signifyd/components'
import { deletePredictionListEntries } from '@signifyd/http'
import { getHTTPErrorStatus } from 'core/http'
import { PREDICTION_LIST_ENTRIES_QUERY_KEY } from 'core/queries/queryKeys'

export interface DeletePredictionListEntries {
  predictionListId: number
  ids: Array<number>
}

export const useDeletePredictionListEntries = (): UseMutationResult<
  AxiosResponse<unknown>,
  AxiosError,
  DeletePredictionListEntries
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ predictionListId, ids }) => {
      return deletePredictionListEntries(predictionListId, {
        listEntryIds: ids,
      })
    },
    onSuccess: async (_, { predictionListId, ids }) => {
      queryClient.invalidateQueries([
        PREDICTION_LIST_ENTRIES_QUERY_KEY,
        predictionListId.toString(),
      ])

      message.success(
        i18nInstance.t('listCommon.successMessage.deleteListEntries', {
          count: ids.length,
        })
      )
    },
    onError: (error) => {
      const status = getHTTPErrorStatus(
        error,
        i18nInstance.t('listCommon.fallbackErrorMessage.deleteListEntries')
      )

      message.error({ content: status.error })
    },
  })
}

export default useDeletePredictionListEntries
