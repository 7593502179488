import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { message } from 'antd'
import { AxiosError, AxiosResponse } from 'axios'
import { i18nInstance } from '@signifyd/components'
import { downloadPredictionListEntries } from '@signifyd/http'
import { fileDownload } from '@signifyd/utils'
import { getHTTPErrorStatus } from 'core/http'

export interface DownloadPredictionListEntries {
  predictionListId: number
}

export const useDownloadPredictionListEntries = (): UseMutationResult<
  AxiosResponse<Array<number>>,
  AxiosError,
  DownloadPredictionListEntries
> => {
  return useMutation({
    mutationFn: async ({ predictionListId }) => {
      return downloadPredictionListEntries(predictionListId)
    },
    onSuccess: async (res) => {
      const contentDisposition = res.headers?.['content-disposition']
      const filename = contentDisposition
        ? contentDisposition.replace(/^attachment; filename="(.+)"$/, '$1')
        : 'listEntries.csv'

      fileDownload(res.data, filename, 'text/csv')
    },
    onError: (error) => {
      const status = getHTTPErrorStatus(
        error,
        i18nInstance.t('listCommon.fallbackErrorMessage.downloadListEntries')
      )

      message.error({ content: status.error })
    },
  })
}

export default useDownloadPredictionListEntries
