import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
  UseMutationResult,
} from '@tanstack/react-query'
import {
  Variable,
  getDecisionCenterVariables,
  updateDecisionCenterVariable,
} from '@signifyd/http'
import { ExtendedVariableGroup } from './adminVariables.types'
import useVariableManagerQueryParams from './useVariableManagerQueryParams'

const useGetDecisionCenterVariables = (): UseQueryResult<Array<Variable>> => {
  const [{ checkpoint, teamId }] = useVariableManagerQueryParams()

  return useQuery(['allVariables', checkpoint, teamId], async () => {
    const {
      data: { data },
    } = await getDecisionCenterVariables({
      teamIds: teamId ? `${teamId}` : undefined,
      checkpoints: checkpoint,
    })

    return data
  })
}

export default useGetDecisionCenterVariables

interface AddVariablePayload {
  groupIds: Array<number>
  variableId: number
}

export const useAddVariableToGroup = (): UseMutationResult<
  unknown,
  unknown,
  AddVariablePayload
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ groupIds, variableId }: AddVariablePayload) => {
      await updateDecisionCenterVariable(variableId, { groupIds })
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['allVariables'])
    },
  })
}

interface EditGroupPayload {
  group: ExtendedVariableGroup
  newVariableSelection: Array<number>
}

export const useEditVariablesInGroup = (): UseMutationResult<
  unknown,
  unknown,
  EditGroupPayload
> => {
  const queryClient = useQueryClient()

  const { data: variables = [] } = useGetDecisionCenterVariables()

  return useMutation({
    mutationFn: async (payload: EditGroupPayload) => {
      const { newVariableSelection, group } = payload

      // Add any newly selected variables
      const promises = newVariableSelection.map((variableId) => {
        const selectedVariable = variables.find(
          (variable) => variable.id === variableId
        )
        const currentGroupIds = selectedVariable?.groupIds ?? []

        const groupIdsSet = new Set([...currentGroupIds, group.id])

        return updateDecisionCenterVariable(variableId, {
          groupIds: [...groupIdsSet],
        })
      })

      // Delete any previously selected variables that are no longer selected
      group.variableIds.forEach((variableId) => {
        if (newVariableSelection.includes(variableId)) {
          return
        }

        const groupIdsSet = new Set(
          variables.find((variable) => variable.id === variableId)?.groupIds ??
            []
        )

        groupIdsSet.delete(group.id)

        promises.push(
          updateDecisionCenterVariable(variableId, {
            groupIds: [...groupIdsSet],
          })
        )
      })

      await Promise.all(promises)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['allVariables'])
      await queryClient.invalidateQueries(['allVariableGroups'])
    },
  })
}
