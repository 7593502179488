import { PlusCircleFilled, PlusCircleOutlined } from '@ant-design/icons'
import cx from 'classnames'
import { FunctionComponent, ReactNode } from 'react'
import { useIsThirdGen } from '@signifyd/components'
import styles from './ToggleBtn.less'

interface Props {
  className?: string
  children: ReactNode
  onClick?: () => void
}

const ToggleBtn: FunctionComponent<Props> = ({
  className,
  children,
  onClick,
}) => {
  const isThirdGen = useIsThirdGen()

  return (
    <div
      className={cx([className, styles.toggleBtn])}
      onClick={onClick || undefined}
    >
      {isThirdGen ? (
        <PlusCircleOutlined className={styles.icon} />
      ) : (
        <PlusCircleFilled className={styles.icon} />
      )}
      <div className={styles.label}>{children}</div>
    </div>
  )
}

export default ToggleBtn
