import { Collapse } from 'antd'
import { FC } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { colorMarengo } from '@signifyd/colors'
import {
  ChevronDown,
  ChevronRight,
  Space,
  TextThirdGen,
} from '@signifyd/components'
import styles from './BestPracticeThirdGen.less'

const BestPracticesThirdGen: FC = () => {
  const { t } = useTranslation()

  return (
    <Collapse
      defaultActiveKey={[1]}
      bordered={false}
      destroyInactivePanel
      expandIcon={({ isActive }) =>
        isActive ? (
          <ChevronDown fill={colorMarengo} />
        ) : (
          <ChevronRight fill={colorMarengo} />
        )
      }
      items={[
        {
          label: (
            <TextThirdGen
              weight="semibold"
              data-test-id="bestPracticesPanelTitle"
            >
              {t('ruleValidationPage.bestPracticesPanel.title')}
            </TextThirdGen>
          ),
          children: (
            <div className={styles.content}>
              <TextThirdGen data-test-id="bestPracticesPanelDescription">
                {t('ruleValidationPage.bestPracticesPanel.description1')}
              </TextThirdGen>

              <Space size="sm" />

              <TextThirdGen>
                <Trans
                  i18nKey="ruleValidationPage.bestPracticesPanel.description2"
                  components={{ bold: <TextThirdGen weight="semibold" /> }}
                />
              </TextThirdGen>
            </div>
          ),
        },
      ]}
    />
  )
}

export default BestPracticesThirdGen
