import { useMemo } from 'react'
import { useQueryParams } from 'use-query-params'
import { Variable } from '@signifyd/http'
import { ExtendedVariableGroup } from 'pages/AdminPages/queries/adminVariables.types'
import {
  columnFilterConfig,
  variableTableQueryParams,
} from './VariableTableFilterConfig'

const useVariableTableFiltering = (
  variables: Array<Variable> = [],
  groups: Array<ExtendedVariableGroup> = []
): Array<Variable> => {
  const [query] = useQueryParams(variableTableQueryParams)

  return useMemo(() => {
    const configValues = Object.values(columnFilterConfig)

    return variables.filter((variable) => {
      return configValues.every(({ filter, queryKey }) => {
        const searchValue = query[queryKey]

        if (!searchValue) {
          return true
        }

        return filter(variable, searchValue, groups)
      })
    })
  }, [query, variables, groups])
}

export default useVariableTableFiltering
