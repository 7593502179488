import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { FC } from 'react'
import styles from './HeaderTooltip.less'

interface Props {
  title: string
  tooltipText: string
}

const HeaderTooltip: FC<Props> = ({ title, tooltipText }) => (
  <div className={styles.tooltip}>
    {title}
    <Tooltip
      title={tooltipText}
      arrow={{ pointAtCenter: true }}
      placement="topLeft"
      destroyTooltipOnHide
    >
      <QuestionCircleOutlined
        data-test-id={`${title}-tooltip`}
        className={styles.icon}
      />
    </Tooltip>
  </div>
)

export default HeaderTooltip
