import { DefinedUseQueryResult, useQuery } from '@tanstack/react-query'
import {
  AggregatePolicyReportResponse,
  CHECKPOINT,
  getAggregatePolicyReport,
} from '@signifyd/http'
import {
  getPolicyReportDateRange,
  TIMEFRAME,
} from 'core/components/PolicyReporting/PolicyReporting.utils'

const AGGREGATE_POLICY_REPORT_QUERY_KEY = 'aggregatePolicyReportResponse'

const useGetAggregatePolicyReport = (
  teamId?: number | null,
  checkpoint?: CHECKPOINT | null,
  timeframe?: TIMEFRAME
): DefinedUseQueryResult<AggregatePolicyReportResponse> => {
  return useQuery<AggregatePolicyReportResponse>({
    queryKey: [
      AGGREGATE_POLICY_REPORT_QUERY_KEY,
      teamId,
      checkpoint,
      timeframe,
    ],
    enabled: !!teamId && !!checkpoint && !!timeframe,
    queryFn: async () => {
      const [startDate, endDate] = getPolicyReportDateRange(timeframe!)
      const { data } = await getAggregatePolicyReport(
        teamId!,
        checkpoint,
        startDate,
        endDate
      )

      return data
    },
    initialData: {
      policies: [],
      hitsByCount: 0,
      percentageOfTotalCount: 0,
      hitsByGmv: 0,
      percentageOfTotalGmv: 0,
      gmvAccept: 0,
      gmvReject: 0,
      gmvHold: 0,
      gmvCredit: 0,
      countAccept: 0,
      countReject: 0,
      countHold: 0,
      countCredit: 0,
      startDate: '',
      endDate: '',
      acceptHistory: [],
      rejectHistory: [],
      holdHistory: [],
      creditHistory: [],
    },
  })
}

export default useGetAggregatePolicyReport
