import { ReactNode } from 'react'
import { i18nInstance } from '@signifyd/components'
import { ValidateRuleResultItem } from '@signifyd/http'
import ExternalLink from 'core/components/ExternalLink'
import {
  DeleteButton,
  FailIconWithTooltip,
  SuccessIcon,
} from 'core/components/Icons'
import { ID_TYPE } from 'core/hooks/useValidatePolicies/utils'
import { ColumnProps } from 'antd/es/table'

function renderInvestigationLink(investigationId: number): ReactNode {
  const baseUrl =
    process.env.BACKEND_ENV === 'production'
      ? 'https://app.signifyd.com'
      : 'https://app.staging.signifyd.com'

  return (
    <ExternalLink url={`${baseUrl}/cases/${investigationId}`}>
      {investigationId}
    </ExternalLink>
  )
}

function renderResult(
  rowData: ValidateRuleResultItem,
  idType: ID_TYPE
): ReactNode {
  return rowData.conditionTestResult ? (
    <SuccessIcon />
  ) : (
    <FailIconWithTooltip
      failureReason={rowData.validationFailureReason}
      idType={idType}
    />
  )
}

interface GetColumnsProps {
  onDeleteItem: (index: number) => void
  idType: ID_TYPE
}

type GetColumns = (
  props: GetColumnsProps
) => Array<ColumnProps<ValidateRuleResultItem>>

const getColumns: GetColumns = ({ onDeleteItem, idType }) => [
  {
    title: i18nInstance.t(`ruleValidationPage.columnTitles.orderID`),
    dataIndex: 'orderId',
    key: 'orderId',
  },
  {
    title: i18nInstance.t(`ruleValidationPage.columnTitles.caseID`),
    dataIndex: 'investigationId',
    key: 'investigationId',
    render: renderInvestigationLink,
  },
  {
    title: i18nInstance.t(`ruleValidationPage.columnTitles.result`),
    dataIndex: 'conditionTestResult',
    key: 'result',
    align: 'center',
    width: 80,
    render: (_item, rowData: ValidateRuleResultItem) =>
      renderResult(rowData, idType),
  },
  {
    key: 'delete',
    align: 'center',
    width: 40,
    render: function renderDelete(_row, _validateRuleResultItem, index) {
      return <DeleteButton onClick={() => onDeleteItem(index)} />
    },
  },
]

export default getColumns
