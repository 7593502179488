import { Alert, Flex, Button } from 'antd'
import cx from 'classnames'
import moment from 'moment-timezone'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Text, PlaneIcon } from '@signifyd/components'
import {
  CHECKPOINT,
  RuleSimulation,
  SIMULATION_STATUS,
  SIMULATION_TYPE,
} from '@signifyd/http'
import styles from './PilotDashboardBanner.less'

type Props = {
  teamId?: number
  pilotSimulation?: RuleSimulation
  checkpoint: CHECKPOINT
  includePilotLink?: boolean
}
export const PilotDashboardBanner: FC<Props> = ({
  teamId,
  pilotSimulation,
  checkpoint,
  includePilotLink,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation(undefined, {
    keyPrefix: 'ruleDashboardPage.pilotBanner',
  })

  if (!pilotSimulation) {
    return null
  }

  const { status, endDate, simulationId } = pilotSimulation

  const addLink = includePilotLink && teamId

  const now = moment().utc()
  const dayDifference = moment(endDate).diff(now, 'days')
  const hourDifference = moment(endDate).diff(now, 'hours')

  const pilotUrl = `/policies/publish/${teamId}/${checkpoint.toLowerCase()}?simulationType=${SIMULATION_TYPE.PILOT_POLICY}&simulationId=${simulationId}`

  const getInProgressText = (
    dayDifference: number,
    hourDifference: number
  ): string => {
    const isAfterEndDate =
      now > moment(endDate) && status === SIMULATION_STATUS.RUNNING

    if (isAfterEndDate || hourDifference === 0) {
      return t('isAfterEndDate')
    }

    if (dayDifference > 0) {
      return t('pilotInProgress', { count: dayDifference })
    }

    return t('pilotInProgressHours', { count: hourDifference })
  }

  if (status === SIMULATION_STATUS.FINISHED) {
    return (
      <Alert
        className={cx([
          { [styles.banner]: includePilotLink },
          styles.bannerStyle,
        ])}
        type="warning"
        showIcon={false}
        message={
          <Flex className={styles.bannerContents} align="center" gap={8}>
            <PlaneIcon className={styles.icon} />
            <Text>{t('pilotCompleted')}</Text>
            {addLink && (
              <Button
                data-analytics-id="view-pilot-link"
                className={styles.link}
                type="link"
                onClick={() => navigate(pilotUrl)}
              >
                {t('viewPilotResults')}
              </Button>
            )}
          </Flex>
        }
      />
    )
  }

  if (status === SIMULATION_STATUS.RUNNING) {
    return (
      <Alert
        className={cx([
          { [styles.banner]: includePilotLink },
          styles.bannerStyle,
        ])}
        type="warning"
        showIcon={false}
        description={
          <Flex align="center" className={styles.bannerContents} gap={8}>
            <PlaneIcon className={styles.icon} />
            <Text>{getInProgressText(dayDifference, hourDifference)}</Text>
            {addLink && (
              <Button
                className={styles.link}
                type="link"
                onClick={() => navigate(pilotUrl)}
              >
                {t('viewActivePilot')}
              </Button>
            )}
          </Flex>
        }
      />
    )
  }

  return null
}
