import {
  CheckoutCheckpointIcon,
  LoginCheckpointIcon,
  ReturnCheckpointMiniIcon,
  IconProps,
  i18nInstance,
} from '@signifyd/components'
import { CHECKPOINT, RuleResponse } from '@signifyd/http'
import { TIMEFRAME } from 'core/components/PolicyReporting/PolicyReporting.utils'

export interface CheckpointTabsData {
  Icon: React.FC<IconProps>
  label: string
  key: CHECKPOINT
}

export const getCheckpointTabsData = (): Array<CheckpointTabsData> => {
  return [
    {
      Icon: CheckoutCheckpointIcon,
      label: i18nInstance.t('common.checkpoint.CHECKOUT'),
      key: CHECKPOINT.CHECKOUT,
    },
    {
      Icon: ReturnCheckpointMiniIcon,
      label: i18nInstance.t('common.checkpoint.RETURN'),
      key: CHECKPOINT.RETURN,
    },
    {
      Icon: LoginCheckpointIcon,
      label: i18nInstance.t('common.checkpoint.LOGIN'),
      key: CHECKPOINT.LOGIN,
    },
  ]
}

export const getFirstCheckpoint = (
  checkpointsForTeam: Array<CHECKPOINT>
): CHECKPOINT => {
  const checkpointTabsData = getCheckpointTabsData()
  const [firstCheckpoint] = checkpointTabsData.filter(({ key }) =>
    checkpointsForTeam.includes(key)
  )

  return firstCheckpoint?.key ?? CHECKPOINT.CHECKOUT
}

export const getDefaultDashboardUrl = (
  policy: RuleResponse | null,
  timeframe: TIMEFRAME
): string => {
  const baseURL = '/policies/dashboard'

  if (!policy) {
    return baseURL
  }

  return `${baseURL}?teamId=${policy.teamId}&checkpoint=${policy.checkpoint}&timeframe=${timeframe}`
}
