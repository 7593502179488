import { Flex, Table } from 'antd'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'stores'
import { useQueryParams } from 'use-query-params'
import { Space, Text, TextThirdGen, useIsThirdGen } from '@signifyd/components'
import { SIMULATION_TYPE } from '@signifyd/http'
import { useTypedRouteParams } from 'pages/PublishWithSimulatorPage/hooks'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import { VIEW_TYPE } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.types'
import { getDistributionTableColumns } from './SimulationDistributionTable.config'
import { getDistributionTableData } from './SimulationDistributionTable.data'
import styles from './SimulationDistributionTable.less'

const SimulationDistributionTable: FC = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'publishWithSimulatorPage.simulationDistributionTable',
  })
  const isThirdGen = useIsThirdGen()

  const { checkpoint } = useTypedRouteParams()
  const [{ simulationType, viewType }] = useQueryParams(PublishPageQueryParams)

  const ruleSimulation = useStoreState(
    (state) => state.simulationModel.ruleSimulation
  )

  const data = useMemo(() => {
    if (!checkpoint) {
      return null
    }

    return getDistributionTableData(
      ruleSimulation,
      viewType as VIEW_TYPE,
      checkpoint
    )
  }, [checkpoint, ruleSimulation, viewType])

  const isPilot = simulationType === SIMULATION_TYPE.PILOT_POLICY

  if (!checkpoint || !data) {
    return null
  }

  const columns = getDistributionTableColumns(checkpoint)

  return (
    <div
      className={styles.container}
      data-test-id="simulationDistributionTable"
    >
      {isThirdGen ? (
        <TextThirdGen type="secondary" className={styles.before}>
          {t(isPilot ? 'beforePilot' : 'before')}
        </TextThirdGen>
      ) : (
        <Text
          className={styles.before}
          block
          type="secondary"
          size="xs"
          transform="uppercase"
        >
          {t(isPilot ? 'beforePilot' : 'before')}
        </Text>
      )}
      <div className={styles.tableContainer}>
        <Space size="xs" />
        {isThirdGen ? (
          <Flex align="center" justify="center">
            <TextThirdGen type="secondary">
              {t(isPilot ? 'titlePilot' : 'title')}
            </TextThirdGen>
          </Flex>
        ) : (
          <Text
            className={styles.title}
            block
            type="secondary"
            size="xs"
            transform="uppercase"
          >
            {t(isPilot ? 'titlePilot' : 'title')}
          </Text>
        )}
        <Space size="xs" />
        <Table
          columns={columns}
          dataSource={data}
          size="small"
          bordered={false}
          showHeader={false}
          pagination={false}
        />
      </div>
    </div>
  )
}

export default SimulationDistributionTable
