import { Button, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { T2, Text, Space } from '@signifyd/components'
import { RuleResponse, CHECKPOINT } from '@signifyd/http'
import styles from './RuleSuccessModal.less'
import RuleSuccessModalHelp from './RuleSuccessModalHelp'
import RuleSuccessModalRule from './RuleSuccessModalRule'

interface Props {
  visible: boolean
  handleCancel: () => void
  rules: Array<RuleResponse>
  setVisible: (isVisible: boolean) => void
  onClickCreateRule?: () => void
  teamId: string
  checkpoint: CHECKPOINT
  isCreate: boolean
}

const RuleSuccessModal: React.FC<Props> = ({
  visible,
  handleCancel,
  rules,
  setVisible,
  onClickCreateRule,
  teamId,
  checkpoint,
  isCreate,
}) => {
  const { t } = useTranslation()

  const labelKey = isCreate ? 'createPolicy' : 'editPolicy'

  return (
    <Modal
      visible={visible}
      closable={false}
      footer={null}
      className={styles.modalStyle}
      width={640}
      title={
        <div className={styles.title}>
          <T2>{t(`ruleDashboardPage.ruleSuccessModal.${labelKey}.title`)}</T2>
        </div>
      }
      maskClosable={false}
      onCancel={handleCancel}
    >
      <Text size="sm" type="secondary" block>
        {t(`ruleDashboardPage.ruleSuccessModal.${labelKey}.ruleListLabel`)}
      </Text>
      <Space size="xs" />
      {rules.map((rule: RuleResponse) => (
        <div key={rule.ruleId}>
          <RuleSuccessModalRule rule={rule} />
          <Space size="xs" />
        </div>
      ))}
      {isCreate && <RuleSuccessModalHelp />}
      <div className={styles.footer}>
        <Button
          type="link"
          onClick={handleCancel}
          data-analytics-id="rule-success-modal-cancel"
          data-test-id="ruleModalSuccessCancelButton"
        >
          {t('ruleDashboardPage.ruleSuccessModal.cancelText')}
        </Button>
        <div>
          {isCreate && (
            <Button
              onClick={() => {
                setVisible(false)
                onClickCreateRule?.()
              }}
              data-analytics-id="rule-success-modal-create"
            >
              {t('ruleDashboardPage.ruleSuccessModal.createNewRule')}
            </Button>
          )}
          <Button
            type="primary"
            className={styles.publishBtn}
            data-test-id="publishingSettingsModalBtn"
            data-analytics-id="rule-success-modal-publishing"
          >
            <Link to={`/policies/publish/${teamId}/${checkpoint}`}>
              {t('ruleDashboardPage.ruleSuccessModal.publishingSettings')}
            </Link>
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default RuleSuccessModal
