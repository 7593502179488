import { FC } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { ListsIcon, Text } from '@signifyd/components'
import { PredictionList } from '@signifyd/http'
import StretchedNoResults from 'core/components/StretchedNoResults'
import useGetPredictionListEntries from 'core/queries/predictionListEntries/useGetPredictionListEntries'
import ListEntriesTableSkeleton from './ListEntriesTableSkeleton'
import ListEntriesTable from '../ListEntriesTable/ListEntriesTable'

export interface ListEntriesTableContainerProps {
  predictionList: PredictionList
}

const ListEntriesTableContainerThirdGen: FC<ListEntriesTableContainerProps> = ({
  predictionList,
}) => {
  const { t } = useTranslation()

  const {
    data: predictionListEntries,
    isLoading,
    isSuccess,
  } = useGetPredictionListEntries(predictionList.id)

  const totalPredictionListEntries =
    predictionListEntries?.predictionListEntriesMeta?.totalRows

  const title = isLoading
    ? t('listDetailsPage.listEntriesTable.loading.title')
    : t('listDetailsPage.listEntriesTable.noListEntries.title')

  if (isLoading) {
    return <ListEntriesTableSkeleton />
  }

  if (!!totalPredictionListEntries && isSuccess) {
    return (
      <ListEntriesTable
        hasAnyPredictionListEntries={!!totalPredictionListEntries}
        predictionList={predictionList}
      />
    )
  }

  return (
    <StretchedNoResults
      title={title}
      description={
        <Trans
          i18nKey="listDetailsPage.listEntriesTable.noListEntries.description"
          components={{ bold: <Text weight="semibold" /> }}
        />
      }
      icon={ListsIcon}
    />
  )
}

export default ListEntriesTableContainerThirdGen
