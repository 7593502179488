import { Popconfirm } from 'antd'
import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Space, Text } from '@signifyd/components'

interface Props {
  children: ReactNode
  disabled?: boolean
  onConfirm: () => void
}

const CloseListEntriesModalPopconfirm: FC<Props> = ({
  children,
  disabled,
  onConfirm,
}) => {
  const { t } = useTranslation()

  return (
    <Popconfirm
      placement="topLeft"
      overlayClassName="sig-popconfirm"
      title={
        <>
          <Text block weight="semibold">
            {t(
              'listDetailsPage.listEntriesForm.closeListEntriesModalPopconfirm.title'
            )}
          </Text>
          <Space size={12} />
          <Text block type="secondary" size="sm">
            {t(
              'listDetailsPage.listEntriesForm.closeListEntriesModalPopconfirm.description'
            )}
          </Text>
        </>
      }
      icon={null}
      onConfirm={onConfirm}
      okText={t(
        'listDetailsPage.listEntriesForm.closeListEntriesModalPopconfirm.okText'
      )}
      cancelText={t(
        'listDetailsPage.listEntriesForm.closeListEntriesModalPopconfirm.cancelText'
      )}
      disabled={disabled}
    >
      {children}
    </Popconfirm>
  )
}

export default CloseListEntriesModalPopconfirm
