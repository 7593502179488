import { Modal, Alert } from 'antd'
import { FC } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Text, Space, useIsThirdGen } from '@signifyd/components'
import { SIG_MODAL_PROPS } from 'core/constants'
import { HTTPStatus } from 'core/http'

interface Props {
  httpStatus: HTTPStatus
  visible: boolean
  onOk: () => void
  onCancel: () => void
}

const DeleteListModal: FC<Props> = ({
  httpStatus,
  visible,
  onOk,
  onCancel,
}) => {
  const { t } = useTranslation()
  const isThirdGen = useIsThirdGen()

  return (
    <Modal
      {...SIG_MODAL_PROPS}
      title={t('listCommon.deleteListModal.title')}
      open={visible}
      okText={t('listCommon.deleteListModal.ok')}
      cancelText={t('listCommon.deleteListModal.cancel')}
      closable={false}
      destroyOnClose
      onCancel={onCancel}
      onOk={onOk}
      okButtonProps={{
        loading: httpStatus.pending,
        type: isThirdGen ? 'primary' : 'link',
        danger: isThirdGen,
      }}
    >
      <Alert
        message={t('listCommon.deleteListModal.info')}
        type="info"
        showIcon
      />
      <Space size="md" />
      <Text
        data-test-id="deleteListDescription"
        size="sm"
        weight="semibold"
        block
      >
        {t('listCommon.deleteListModal.description')}
      </Text>
      <Space size="md" />
      <Text type="secondary">
        <Trans
          i18nKey="listCommon.deleteListModal.note"
          components={{ bold: <Text weight="semibold" type="secondary" /> }}
        />
      </Text>
    </Modal>
  )
}

export default DeleteListModal
