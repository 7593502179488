import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useIsThirdGen } from '@signifyd/components'
import { PredictionList } from '@signifyd/http'
import CreateListEntriesModal from 'pages/ListDetailsPage/containers/CreateListEntriesModal'
import { useListDetailsPageQueryParams } from 'pages/ListDetailsPage/useListDetailsPageQueryParams'

interface Props {
  predictionList: PredictionList
}

const CreateItemsButtonWithModal: FC<Props> = ({ predictionList }) => {
  const isThirdGen = useIsThirdGen()
  const { t } = useTranslation()
  const [searchParams] = useListDetailsPageQueryParams()
  const [listEntriesModalVisible, setListEntriesModalVisible] = useState(
    searchParams.isNewList === 'true'
  )

  return (
    <div data-test-id="createListEntriesButtonWithModal">
      <Button
        block
        type={isThirdGen ? 'primary' : 'default'}
        icon={<PlusOutlined />}
        onClick={() => setListEntriesModalVisible(true)}
        data-test-id="addItemsToListButton"
      >
        {t('listDetailsPage.sidePanel.addItems')}
      </Button>
      <CreateListEntriesModal
        onClose={() => setListEntriesModalVisible(false)}
        predictionList={predictionList}
        visible={listEntriesModalVisible}
      />
    </div>
  )
}

export default CreateItemsButtonWithModal
