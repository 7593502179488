import {
  CheckOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { Switch, Tooltip, message, Popconfirm } from 'antd'
import { FC, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Space, Text } from '@signifyd/components'
import {
  PredictionList,
  PREDICTION_LIST_UPDATABLE_FIELD,
  PREDICTION_LIST_TYPE,
} from '@signifyd/http'
import useUpdatePredictionList from 'core/hooks/useUpdatePredictionList'
import { getHTTPErrorMessage } from 'core/http'
import styles from './ListDetailsPageOrdersToggle.less'

export interface Props {
  predictionList: PredictionList
}

const ENABLE_IN_ORDERS_ALLOW_LIST = [
  PREDICTION_LIST_TYPE.EMAIL,
  PREDICTION_LIST_TYPE.DEVICE_ID,
  PREDICTION_LIST_TYPE.IP_ADDRESS,
  PREDICTION_LIST_TYPE.ADDRESS,
  PREDICTION_LIST_TYPE.PHONE_NUMBER,
  PREDICTION_LIST_TYPE.TAX_ID,
  PREDICTION_LIST_TYPE.MEMBERSHIP_ID,
  PREDICTION_LIST_TYPE.ACCOUNT_NUMBER,
]

const ListDetailsPageOrdersToggle: FC<Props> = ({ predictionList }) => {
  const { type, isShownInOrdersApp } = predictionList
  const { t } = useTranslation()
  const [toggle, setToggle] = useState(isShownInOrdersApp)
  const [isPopconfirmVisible, setPopconfirmVisible] = useState(false)

  const { mutateAsync: updatePredictionList } = useUpdatePredictionList()

  if (!ENABLE_IN_ORDERS_ALLOW_LIST.includes(type)) {
    return null
  }

  const listId = predictionList.id

  const onToggle = async (shouldShowInOrdersApp: boolean): Promise<void> => {
    setToggle(shouldShowInOrdersApp)

    try {
      await updatePredictionList({
        listId,
        payload: {
          updateMask: [PREDICTION_LIST_UPDATABLE_FIELD.IS_SHOWN_IN_ORDERS_APP],
          isShownInOrdersApp: shouldShowInOrdersApp,
        },
      })
    } catch (e) {
      message.error(
        getHTTPErrorMessage(
          e,
          t(
            `listDetailsPage.listPageOrders.${
              shouldShowInOrdersApp ? 'enableError' : 'disableError'
            }`
          )
        )
      )
      setToggle(!shouldShowInOrdersApp)
    }

    setPopconfirmVisible(false)
  }

  const translationKey = !isShownInOrdersApp ? 'enable' : 'disable'

  return (
    <div className={styles.wrapper}>
      <Text data-test-id="pageOrdersToggleLabel" className={styles.toggleLabel}>
        {t('listDetailsPage.listPageOrders.toggleLabel')}
        <Tooltip
          placement="bottom"
          title={
            <Trans
              i18nKey="listDetailsPage.listPageOrders.toggleTooltip"
              components={{ p: <p /> }}
            />
          }
        >
          <QuestionCircleOutlined data-test-id="toggleTooltipIcon" />
        </Tooltip>
      </Text>
      <Popconfirm
        overlayClassName="sig-popconfirm"
        title={
          <>
            <Text block weight="semibold" size="lg">
              {t(
                `listDetailsPage.listPageOrders.popover.${translationKey}.title`
              )}
            </Text>
            <Space size={12} />
            <Text block type="secondary" size="md">
              {t(
                `listDetailsPage.listPageOrders.popover.${translationKey}.description`
              )}
            </Text>
          </>
        }
        open={isPopconfirmVisible}
        onOpenChange={setPopconfirmVisible}
        onConfirm={() => onToggle(!isShownInOrdersApp)}
        placement="bottomLeft"
        okText={
          <span data-test-id="okText">
            {t(
              `listDetailsPage.listPageOrders.popover.${translationKey}.okButton`
            )}
          </span>
        }
        cancelText={t(
          `listDetailsPage.listPageOrders.popover.${translationKey}.cancelButton`
        )}
        overlayStyle={{ maxWidth: 292 }}
        icon={false}
      >
        <Switch
          className={styles.toggle}
          data-test-id="pageOrdersToggle"
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked={isShownInOrdersApp}
          checked={toggle}
          onClick={() => setPopconfirmVisible(true)}
        />
      </Popconfirm>
    </div>
  )
}

export default ListDetailsPageOrdersToggle
