import { DeleteOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useDeletePredictionList from 'core/hooks/useDeletePredictionList'
import { HTTPStatus } from 'core/http'
import DeleteListModal from 'pages/ListDetailsPage/containers/DeleteListModal'

interface Props {
  predictionListId: number
  disabled?: boolean
}

const DeleteListButtonWithModal: FC<Props> = ({
  predictionListId,
  disabled,
}) => {
  const { t } = useTranslation()

  const [deleteListModalVisible, setDeleteListModalVisible] = useState(false)

  const {
    mutateAsync: deletePredictionList,
    isLoading,
    isSuccess,
    error,
  } = useDeletePredictionList()

  const deletePredictionListHTTPStatus: HTTPStatus = {
    pending: isLoading,
    success: isSuccess,
    error: error?.message,
  }

  return (
    <>
      {disabled ? (
        <Tooltip
          placement="left"
          title={t('listDetailsPage.sidePanel.deleteListTooltip')}
        >
          <Button
            data-test-id="deleteButtonDisabled"
            disabled
            icon={<DeleteOutlined />}
            danger
            block
            onClick={() => setDeleteListModalVisible(true)}
          >
            {t('listDetailsPage.sidePanel.deleteList')}
          </Button>
        </Tooltip>
      ) : (
        <Button
          data-test-id="deleteButtonEnabled"
          danger
          icon={<DeleteOutlined />}
          block
          onClick={() => setDeleteListModalVisible(true)}
        >
          {t('listDetailsPage.sidePanel.deleteList')}
        </Button>
      )}
      <DeleteListModal
        httpStatus={deletePredictionListHTTPStatus}
        visible={deleteListModalVisible}
        onCancel={() => setDeleteListModalVisible(false)}
        onOk={async () => {
          await deletePredictionList(predictionListId)

          setDeleteListModalVisible(false)
        }}
      />
    </>
  )
}

export default DeleteListButtonWithModal
