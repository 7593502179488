import { ExperimentOutlined } from '@ant-design/icons'
import { FC } from 'react'
import { Trans } from 'react-i18next'
import { Text } from '@signifyd/components'
import useUserTeams from 'core/hooks/useUserTeams'
import styles from './TestTeamBanner.less'

interface Props {
  teamId?: number
  text?: string
}

const TestTeamBanner: FC<Props> = ({ teamId, text }) => {
  const { liveTeams } = useUserTeams()

  const selectedTeam = liveTeams?.find((team) => team.teamId === teamId)

  const translationKey = text
    ? 'common.testTeamBanner.testTeamSelectedWithNestedText'
    : 'common.testTeamBanner.testTeamSelected'

  if (!selectedTeam?.test) {
    return null
  }

  return (
    <div className={styles.banner}>
      <ExperimentOutlined />
      <Trans
        shouldUnescape
        i18nKey={translationKey}
        values={{
          teamName: selectedTeam?.teamName,
          text,
        }}
        components={{
          bold: <Text weight="semibold" />,
        }}
      />
    </div>
  )
}

export default TestTeamBanner
