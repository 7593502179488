import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { i18nInstance } from '@signifyd/components'
import {
  createPredictionList,
  CreatePredictionListPayload,
} from '@signifyd/http'
import { getHTTPErrorStatus } from 'core/http'
import { useDashboardPageFilters } from 'pages/DashboardPage/hooks/useDashboardPageFilters'

const useCreatePredictionList = (
  shouldRedirectToList = true
): UseMutationResult<number, AxiosError, CreatePredictionListPayload> => {
  const navigate = useNavigate()
  const [query] = useDashboardPageFilters()

  return useMutation({
    mutationFn: async (payload) => {
      try {
        const {
          data: { id: listId },
        } = await createPredictionList(payload)

        return listId
      } catch (error) {
        const status = getHTTPErrorStatus(
          error,
          i18nInstance.t('ruleCommon.fallbackErrorMessage.createPredictionList')
        )

        throw new Error(status.error)
      }
    },

    onSuccess: async (listId) => {
      if (shouldRedirectToList) {
        navigate(`/lists/${listId}?isNewList=true&teamId=${query.teamId}`)
      }
    },
  })
}

export default useCreatePredictionList
