import { Divider } from 'antd'
import moment from 'moment-timezone'
import { FC, CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { RuleSet } from '@signifyd/http'
import FormatDate from 'core/components/FormatDate'

interface Props {
  userTimeZone: string
  ruleSet: RuleSet | null
  style?: CSSProperties
}

const PublishedRuleSetInfo: FC<Props> = ({ userTimeZone, ruleSet, style }) => {
  const { t } = useTranslation()
  const timeZone = moment.tz.zone(userTimeZone)
  const now = new Date().getTime()

  if (!ruleSet) {
    return null
  }

  return (
    <Text
      data-test-id="publishedRuleSetInfo"
      block
      type="secondary"
      size="xs"
      style={style}
    >
      {t('ruleDashboardPage.publishedRuleSetInfo.lastPublished')}{' '}
      <FormatDate
        UTCTimestamp={ruleSet.createdAt}
        timeZone={userTimeZone}
        showTimeZoneAbbr={false}
      />
      <Divider type="vertical" />
      {timeZone && (
        <>
          {timeZone.abbr(now)} - {timeZone.name}
        </>
      )}
    </Text>
  )
}

export default PublishedRuleSetInfo
