import { Input, Button } from 'antd'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { PREDICTION_LIST_TYPE } from '@signifyd/http'
import { GetListEntriesFilters } from 'core/queries/predictionListEntries/useGetPredictionListEntries/useGetPredictionListEntries'
import DeleteListEntryPopconfirm from 'pages/ListDetailsPage/components/DeleteListEntryPopconfirm'
import { useListDetailsPageQueryParams } from 'pages/ListDetailsPage/useListDetailsPageQueryParams'
import styles from './ListEntriesTable.less'

const { Search } = Input

export interface ListEntriesTableHeaderProps {
  predictionListType: PREDICTION_LIST_TYPE
  filters: GetListEntriesFilters | null
  onDeleteSelectedEntries: () => void
  selectedEntryIds: Array<number>
  userCanEdit?: boolean
}

const ListEntriesTableHeader: FC<ListEntriesTableHeaderProps> = ({
  filters = {},
  predictionListType,
  onDeleteSelectedEntries,
  selectedEntryIds,
  userCanEdit,
}) => {
  const [searchValue, setSearchValue] = useState(
    () => filters?.searchTerm || ''
  )
  const { t } = useTranslation()

  const [, setQuery] = useListDetailsPageQueryParams()

  const onSearch = (value: string): void => {
    setQuery({
      searchTerm: value,
      page: 0,
    })
  }

  return (
    <div className={styles.tableHeader}>
      <Search
        value={searchValue}
        allowClear
        className={styles.search}
        placeholder={t(
          `listDetailsPage.listEntriesTable.searchPlaceholder.${predictionListType}`
        )}
        onSearch={onSearch}
        onChange={(e) => setSearchValue(e.target.value)}
        data-test-id="list-entries-table-search"
      />
      <div className={styles.tableHeaderButton}>
        <Button
          type="primary"
          disabled={!searchValue.length}
          onClick={() => onSearch(searchValue)}
          data-test-id="listEntriesTableBulkSearchButton"
          data-analytics-id="list-entries-table-bulk-search-button"
        >
          {t('listDetailsPage.listEntriesTable.searchButton')}
        </Button>
      </div>
      {userCanEdit && (
        <div className={styles.tableHeaderButton}>
          <DeleteListEntryPopconfirm
            count={selectedEntryIds.length}
            placement="right"
            onConfirm={onDeleteSelectedEntries}
          >
            <Button
              type="primary"
              disabled={!selectedEntryIds.length}
              data-test-id="listEntriesTableBulkDeleteButton"
            >
              {t(
                'listDetailsPage.listEntriesTable.deleteListEntryPopconfirm.bulkDeleteEntriesBtn',
                {
                  count: selectedEntryIds.length,
                }
              )}
            </Button>
          </DeleteListEntryPopconfirm>
        </div>
      )}
      <div className={styles.selectionTextWrapper}>
        {selectedEntryIds.length > 0 && (
          <Text>
            {t('listDetailsPage.listEntriesTable.selections.count', {
              count: selectedEntryIds.length,
            })}
          </Text>
        )}
      </div>
    </div>
  )
}

export default ListEntriesTableHeader
