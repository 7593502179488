import { SyncOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import styles from './RuleValidationCardFooter.less'

interface Props {
  loading: boolean
  icon: JSX.Element
  message: string
  onRefresh: () => void
  canRerunValidation: boolean
}

const RuleValidationCardFooter: FunctionComponent<Props> = ({
  loading,
  icon,
  message,
  onRefresh,
  canRerunValidation,
}) => {
  const { t } = useTranslation()

  return (
    <div data-test-id="footer" className={styles.footer}>
      {loading ? (
        <div data-test-id="loading" />
      ) : (
        <div>
          {icon}
          <Text type="secondary" className={styles.message}>
            {message}
          </Text>
        </div>
      )}
      {canRerunValidation && (
        <Button
          data-test-id="refresh-button"
          className={styles.button}
          icon={!loading && <SyncOutlined />}
          type="link"
          size="small"
          disabled={loading}
          onClick={onRefresh}
        >
          {t('ruleValidationPage.refreshResult')}
        </Button>
      )}
    </div>
  )
}

export default RuleValidationCardFooter
