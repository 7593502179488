import { RedoOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useIsThirdGen } from '@signifyd/components'
import { PredictionList } from '@signifyd/http'
import ReplaceListEntriesModal from 'pages/ListDetailsPage/containers/ReplaceListEntriesModal'

interface Props {
  disabled?: boolean
  predictionList: PredictionList
}

const ReplaceListEntriesButtonWithModal: FC<Props> = ({
  disabled,
  predictionList,
}) => {
  const { t } = useTranslation()
  const [listEntriesModalVisible, setListEntriesModalVisible] = useState(false)
  const isThirdGen = useIsThirdGen()

  return (
    <>
      {disabled ? (
        <Tooltip
          placement="left"
          title={t('listDetailsPage.sidePanel.noEntriesTooltip')}
        >
          <Button
            disabled
            block
            icon={<RedoOutlined />}
            data-test-id="replaceButtonWithTooltip"
            type={isThirdGen ? 'link' : 'default'}
          >
            {t('listDetailsPage.sidePanel.replaceAll')}
          </Button>
        </Tooltip>
      ) : (
        <Button
          block
          icon={<RedoOutlined />}
          onClick={() => setListEntriesModalVisible(true)}
          data-test-id="replaceButton"
          type={isThirdGen ? 'link' : 'default'}
        >
          {t('listDetailsPage.sidePanel.replaceAll')}
        </Button>
      )}
      <ReplaceListEntriesModal
        onClose={() => {
          setListEntriesModalVisible(false)
        }}
        predictionList={predictionList}
        visible={listEntriesModalVisible}
      />
    </>
  )
}

export default ReplaceListEntriesButtonWithModal
