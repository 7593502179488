import { Theme as NivoTheme } from '@nivo/core'
import { DefaultNode, DefaultLink, SankeySvgProps } from '@nivo/sankey'
import { colorMarengo } from '@signifyd/colors'

export const SANKEY_CONFIG: Partial<SankeySvgProps<DefaultNode, DefaultLink>> =
  {
    linkOpacity: 0.2,
    linkHoverOpacity: 0.5,
    linkHoverOthersOpacity: 0.2,
    nodeOpacity: 1,
    nodeHoverOpacity: 1,
    nodeHoverOthersOpacity: 1,
    align: 'justify',
    sort: 'input',
    nodeThickness: 8,
    nodeInnerPadding: 0,
    nodeSpacing: 8,
    nodeBorderWidth: 0,
    labelPadding: 8,
    margin: { top: 8, bottom: 8 },
    labelTextColor: colorMarengo,
  }

export const SANKEY_THEME_CONFIG: NivoTheme = {
  text: {
    fontFamily:
      'ProximaNova-Regular, "Helvetica Neue", Helvetica, Arial, sans-serif',
    fontSize: 13,
  },
}
