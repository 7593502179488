import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'stores'
import { Text } from '@signifyd/components'
import { DefaultOptionType } from 'antd/lib/select'
import { PredictionListFeature } from 'stores/conditionTree'
import styles from './ValueForListSelect.less'

const usePredictionListOptions = (
  predictionListFeature: PredictionListFeature | undefined
): Array<DefaultOptionType> | null => {
  const { t } = useTranslation()

  const {
    predictionListMap,
    predictionListsHTTPStatuses: { listPredictionLists },
  } = useStoreState((state) => state.ruleModel)

  if (!predictionListFeature || !predictionListMap) {
    return null
  }

  if (listPredictionLists.pending) {
    return [
      {
        key: 'loading',
        label: <Spin size="small" />,
        className: styles.listOption,
      },
    ]
  }

  if (listPredictionLists.error) {
    return [
      {
        key: 'error',
        label: (
          <Text type="danger" size="xs">
            {listPredictionLists.error}
          </Text>
        ),
        className: styles.listOptionError,
        disabled: true,
      },
    ]
  }

  if (listPredictionLists.success) {
    const predictionLists = predictionListMap[predictionListFeature.type]

    return predictionLists.map(({ id, name, size }) => ({
      key: name,
      label: (
        <div className={styles.listOptionContent}>
          <Text>{name}</Text>
          <Text type="secondary">
            {t(
              'ruleConditionsPage.conditionInputs.predictionListSelect.itemsCount',
              { count: size }
            )}
          </Text>
        </div>
      ),
      value: `${id}`,
      className: styles.listOption,
      'data-test-id': `predictionListOption-${id}`,
    }))
  }

  return null
}

export default usePredictionListOptions
