import { message } from 'antd'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { RuleResponse } from '@signifyd/http'
import {
  ConditionTreeNode,
  deserializePredicateToLegacyConditionTree,
  RuleFeaturesByName,
} from 'stores/conditionTree'

interface UseConditionTreeFromPolicyOptions {
  isEditing?: boolean
  policy?: RuleResponse | null
  policyFeaturesByName?: RuleFeaturesByName | null
}

const useConditionTreeFromPolicy = ({
  isEditing,
  policy,
  policyFeaturesByName,
}: UseConditionTreeFromPolicyOptions): ConditionTreeNode | null => {
  const { t } = useTranslation()

  return useMemo(() => {
    if (!policy || !policyFeaturesByName) {
      return null
    }

    try {
      const predicateToParse = isEditing
        ? policy.draftPredicate
        : policy.predicate

      const predicate = JSON.parse(predicateToParse || '{}')

      return deserializePredicateToLegacyConditionTree(
        predicate,
        policyFeaturesByName
      )
    } catch {
      message.error(
        t('ruleCommon.fallbackErrorMessage.deserializeRulePredicate')
      )

      return null
    }
  }, [isEditing, policy, policyFeaturesByName, t])
}

export default useConditionTreeFromPolicy
