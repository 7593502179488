import { Flex, Spin } from 'antd'
import moment from 'moment-timezone'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreActions, useStoreState } from 'stores'
import { DecodedValueMap, useQueryParams } from 'use-query-params'
import {
  RangePickerValue,
  Space,
  StretchToPageBottom,
  Text,
} from '@signifyd/components'
import { RuleSet, SIMULATION_STATUS, SIMULATION_TYPE } from '@signifyd/http'
import StretchedNoResults from 'core/components/StretchedNoResults'
import useGetPilotSimulations from 'core/hooks/useGetPilotSimulations/useGetPilotSimulations'
import useUserTimeZone from 'core/hooks/useUserTimeZone'
import CreateSimulationContainer from 'pages/PublishWithSimulatorPage/containers/CreateSimulationContainer'
import RunningSimulationContainer from 'pages/PublishWithSimulatorPage/containers/RunningSimulationContainer'
import SimulationResultContainer from 'pages/PublishWithSimulatorPage/containers/SimulationResultContainer'
import { useTypedRouteParams } from 'pages/PublishWithSimulatorPage/hooks'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import SimulationResultHeader from '../SimulationResultHeader'
import { NoResultsDescription } from './NoResultsDescription'
import styles from './SimulationContainer.less'

interface Props {
  ruleSet?: RuleSet
}

const SimulationContainer: FC<Props> = ({ ruleSet }) => {
  const { t } = useTranslation()
  const userTimeZone = useUserTimeZone()
  const {
    progressInDecimal,
    simulationId,
    noInvestigations,
    ruleSimulation,
    isLoading,
  } = useStoreState((state) => state.simulationModel)
  const [query] = useQueryParams(PublishPageQueryParams)

  const { checkpoint, teamId } = useTypedRouteParams()
  const { simulationType } = query

  const { data: pilotSimulation, isLoading: isPilotLoading } =
    useGetPilotSimulations(teamId, checkpoint)

  const isLoadingRuleSimulation =
    isLoading.getRuleSimulation ||
    isLoading.createRuleSimulation ||
    isLoading.cancelRuleSimulation

  const isNonPilotSimulation =
    ruleSimulation?.simulationType !== SIMULATION_TYPE.PILOT_POLICY &&
    ruleSimulation?.status === SIMULATION_STATUS.RUNNING

  const isRunningSimulation =
    progressInDecimal !== null && progressInDecimal < 1 && isNonPilotSimulation

  const isPilotFinished =
    ruleSimulation?.simulationType === SIMULATION_TYPE.PILOT_POLICY ||
    ruleSimulation?.status === SIMULATION_STATUS.FINISHED

  const isFinishedSimulation =
    (progressInDecimal !== null && progressInDecimal === 1) || isPilotFinished

  if (userTimeZone) {
    moment.tz.setDefault(userTimeZone)
  }

  const sevenDaysAgo = moment().subtract(7, 'days')
  const now = moment()

  const simulationDateRange = [
    sevenDaysAgo,
    now,
  ] as NonNullable<RangePickerValue>

  const [dateRangeValue, setDateRange] =
    useState<NonNullable<RangePickerValue>>(simulationDateRange)

  useEffect(() => {
    if (
      !ruleSimulation ||
      ruleSimulation.simulationType !== SIMULATION_TYPE.PILOT_POLICY
    ) {
      return
    }

    setDateRange([
      moment(ruleSimulation.startDate),
      moment(ruleSimulation.endDate),
    ])
  }, [ruleSimulation])

  const handleDateRange = (rangeValue: RangePickerValue): void => {
    setDateRange(rangeValue ?? [null, null])
  }

  const isPilotPolicy = simulationType === SIMULATION_TYPE.PILOT_POLICY

  const { createRuleSimulation } = useStoreActions(
    (actions) => actions.simulationModel
  )

  const createSimulationForTeam = (
    filters: DecodedValueMap<typeof PublishPageQueryParams>,
    simulationType = SIMULATION_TYPE.CUSTOMER_POLICY,
    teamId: number,
    startDate?: string,
    endDate?: string,
    emailAddresses?: Array<string>
  ): void => {
    const editedRuleId = filters.editedPolicyId ?? undefined

    if (startDate && endDate) {
      createRuleSimulation({
        ruleSetId: filters.ruleSetId!,
        teamId,
        startDate,
        endDate,
        editedRuleId,
        simulationType,
        emailAddresses,
      })
    }
  }

  if (
    (isLoadingRuleSimulation &&
      simulationType === SIMULATION_TYPE.PILOT_POLICY) ||
    isPilotLoading
  ) {
    return (
      <StretchToPageBottom>
        <Flex
          align="center"
          className={styles.simulationFetching}
          justify="center"
        >
          <Spin />
        </Flex>
      </StretchToPageBottom>
    )
  }

  if (noInvestigations) {
    return (
      <div data-test-id="noResultsFoundContainer">
        <Space size="lg" />
        <SimulationResultHeader
          setDateRange={handleDateRange}
          dateRangeValue={dateRangeValue}
          userTimeZone={userTimeZone}
        />
        <Space size="lg" />
        <StretchedNoResults
          title={
            <Text size="lg" weight="semibold">
              {t('publishWithSimulatorPage.noResultsFound.title')}
            </Text>
          }
          description={
            <NoResultsDescription
              pilotSimulation={pilotSimulation}
              createSimulationForTeam={createSimulationForTeam}
            />
          }
        />
      </div>
    )
  }

  if (isRunningSimulation) {
    return (
      <RunningSimulationContainer
        simulationId={simulationId}
        dateRange={dateRangeValue}
      />
    )
  }

  if (isFinishedSimulation) {
    return (
      <SimulationResultContainer ruleSet={ruleSet}>
        <SimulationResultHeader
          isPilotPolicy={isPilotPolicy}
          setDateRange={handleDateRange}
          dateRangeValue={dateRangeValue}
          userTimeZone={userTimeZone}
        />
      </SimulationResultContainer>
    )
  }

  return (
    <CreateSimulationContainer
      pilotSimulation={pilotSimulation}
      createSimulationForTeam={createSimulationForTeam}
      dateRangeValue={dateRangeValue}
      setDateRange={handleDateRange}
      userTimeZone={userTimeZone}
    />
  )
}

export default SimulationContainer
