import { Alert } from 'antd'
import cx from 'classnames'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Space } from '@signifyd/components'
import { SIMULATION_TYPE } from '@signifyd/http'
import styles from './SimulationResultWarning.less'

const SimulationResultWarning: FC<{
  hasMatchingRuleIds: boolean
  simulationType: SIMULATION_TYPE
}> = ({ hasMatchingRuleIds, simulationType }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'publishWithSimulatorPage.simulationResult',
  })

  if (hasMatchingRuleIds) {
    return null
  }

  const message = t(
    `${simulationType === SIMULATION_TYPE.PILOT_POLICY ? 'pilotRuleIdsWarningMessage' : 'ruleIdsWarningMessage'}`
  )

  return (
    <>
      <Alert
        className={cx({
          [styles.banner]: simulationType === SIMULATION_TYPE.PILOT_POLICY,
        })}
        data-test-id="rule-ids-warning-message"
        description={message}
        type="warning"
        showIcon
        banner={simulationType !== SIMULATION_TYPE.PILOT_POLICY}
      />
      <Space size="md" />
    </>
  )
}

export default SimulationResultWarning
