import { Select } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { InputLabel } from '@signifyd/components'
import { PredictionList } from '@signifyd/http'
import {
  LIST_PAGE_SORT_BY_FILTER,
  LIST_PAGE_TYPE_FILTER,
  LIST_PAGE_EXPIRATION_FILTER,
} from 'stores/predictionList'
import styles from './ListFilters.less'
import { getCountsForTypeFilters } from './ListFilters.utils'

interface Props {
  predictionListSortBy: LIST_PAGE_SORT_BY_FILTER
  predictionListType: LIST_PAGE_TYPE_FILTER
  predictionListExpiration: LIST_PAGE_EXPIRATION_FILTER
  onChangeSortBy: (predictionListSortBy: LIST_PAGE_SORT_BY_FILTER) => void
  onChangeType: (predictionListType: LIST_PAGE_TYPE_FILTER) => void
  onChangeExpiration: (predictionListType: LIST_PAGE_EXPIRATION_FILTER) => void
  predictionLists: Array<PredictionList>
}

const ListFilters: FC<Props> = ({
  predictionListSortBy,
  predictionListType,
  predictionListExpiration,
  onChangeSortBy,
  onChangeType,
  onChangeExpiration,
  predictionLists,
}) => {
  const { t } = useTranslation()

  const typeToCountMap = getCountsForTypeFilters(predictionLists)

  return (
    <div className={styles.wrapper}>
      <div>
        <InputLabel>{t('listPage.sortByLabel')}</InputLabel>
        <Select
          value={predictionListSortBy}
          className={styles.select}
          onChange={onChangeSortBy}
          data-test-id="sortByFilter"
          options={Object.values(LIST_PAGE_SORT_BY_FILTER)
            .sort()
            .map((key) => ({
              key,
              label: t(`listPage.sortByFilter.${key}`),
              value: key,
            }))}
        />
      </div>
      <div className={styles.typeSelector}>
        <InputLabel>{t('listPage.typeLabel')}</InputLabel>
        <Select
          value={predictionListType}
          className={styles.select}
          onChange={onChangeType}
          data-test-id="typeFilter"
          options={Object.values(LIST_PAGE_TYPE_FILTER)
            .sort()
            .map((filter) => ({
              key: filter,
              label: t(`listPage.listTypeFilter.${filter}`, {
                count: typeToCountMap[filter],
              }),
              value: filter,
            }))}
        />
      </div>
      <div className={styles.typeSelector}>
        <InputLabel>{t('listPage.expirationLabel')}</InputLabel>
        <Select
          value={predictionListExpiration}
          className={styles.select}
          onChange={onChangeExpiration}
          data-test-id="expirationFilter"
          options={Object.values(LIST_PAGE_EXPIRATION_FILTER)
            .sort()
            .map((key) => ({
              key,
              label: t(`listPage.listExpirationFilter.${key}`),
              value: key,
            }))}
        />
      </div>
    </div>
  )
}

export default ListFilters
