import { CaretRightOutlined } from '@ant-design/icons'
import { Collapse } from 'antd'
import { FC } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Text, Space } from '@signifyd/components'

const BestPractices: FC = () => {
  const { t } = useTranslation()

  return (
    <Collapse
      defaultActiveKey={[1]}
      bordered={false}
      destroyInactivePanel
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      items={[
        {
          label: (
            <Text weight="semibold" data-test-id="bestPracticesPanelTitle">
              {t('ruleValidationPage.bestPracticesPanel.title')}
            </Text>
          ),
          children: (
            <>
              <Text size="sm" data-test-id="bestPracticesPanelDescription">
                {t('ruleValidationPage.bestPracticesPanel.description1')}
              </Text>

              <Space size="sm" />

              <Text size="sm">
                <Trans
                  i18nKey="ruleValidationPage.bestPracticesPanel.description2"
                  components={{ bold: <Text weight="semibold" /> }}
                />
              </Text>
            </>
          ),
        },
      ]}
    />
  )
}

export default BestPractices
