import { FileDoneOutlined } from '@ant-design/icons'
import { Tag } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { colorIrisLight80, colorGlitter } from '@signifyd/colors'
import { TextThirdGen } from '@signifyd/components'
import { PREDICTION_LIST_TYPE } from '@signifyd/http'
import styles from './PredictionListTypeTagThirdGen.less'

interface Props {
  type: PREDICTION_LIST_TYPE
  className?: string
  bordered?: boolean
}

type ColorHex = `#${string}`
interface StyleMap {
  color: ColorHex
  showIcon: boolean
}

export const standardListTypeStyle = {
  color: colorGlitter,
  showIcon: false,
}

export const abuseListTypeStyle = {
  color: colorIrisLight80,
  typeClass: '',
  showIcon: true,
}

export const getTypeToStyles = (type: PREDICTION_LIST_TYPE): StyleMap =>
  ['PROMO_ABUSE', 'RESELLER_ABUSE'].includes(type)
    ? abuseListTypeStyle
    : standardListTypeStyle

const PredictionListTypeTagThirdGen: FC<Props> = ({ className, type }) => {
  const { t } = useTranslation()
  const { showIcon, color } = getTypeToStyles(type)

  if (!(type in PREDICTION_LIST_TYPE)) {
    return null
  }

  return (
    <Tag color={color} className={className}>
      {showIcon && <FileDoneOutlined className={styles.tag} />}
      <TextThirdGen weight="semibold" className={styles.tag}>
        {t(`listCommon.listTypeTag.${type}`)}
      </TextThirdGen>
    </Tag>
  )
}

export default PredictionListTypeTagThirdGen
