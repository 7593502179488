import { Modal, Select } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Space, Text } from '@signifyd/components'
import useGetVariableGroups from 'pages/AdminPages/queries/useGetDecisionCenterGroups'
import useGetDecisionCenterVariables, {
  useEditVariablesInGroup,
} from 'pages/AdminPages/queries/useGetDecisionCenterVariables'
import styles from './EditVariableGroupModal.less'
import { useVariableGroupModalContext } from '../VariableGroupTable/variableGroupModalContext'

const EditVariableGroupModal: FC = () => {
  const { t } = useTranslation()

  const [selectedVariables, setSelectedVariables] = useState<Array<number>>([])

  const {
    selectedVariableGroupId: variableGroupId,
    setSelectedVariableGroupId,
  } = useVariableGroupModalContext()

  const { data: groups = [] } = useGetVariableGroups()
  const { data: variables = [] } = useGetDecisionCenterVariables()

  const editVariablesInGroup = useEditVariablesInGroup()

  const selectedGroup = groups.find((group) => group.id === variableGroupId)

  useEffect(() => {
    if (!variableGroupId) {
      return
    }

    const variables = selectedGroup?.variableIds ?? []

    setSelectedVariables(variables)
  }, [variableGroupId, selectedGroup])

  const handleOk = async (): Promise<void> => {
    if (!selectedGroup) {
      return
    }

    await editVariablesInGroup.mutateAsync({
      group: selectedGroup,
      newVariableSelection: selectedVariables,
    })

    setSelectedVariableGroupId(undefined)
  }

  const handleCancel = (): void => {
    setSelectedVariableGroupId(undefined)
  }

  return (
    <>
      <Modal
        width={800}
        title={t('variableGroupPage.editGroupModal.title')}
        visible={!!variableGroupId}
        confirmLoading={editVariablesInGroup.isLoading}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Text strong>{selectedGroup?.name}</Text>
        <Space size="md" />
        <Select
          value={selectedVariables}
          mode="multiple"
          className={styles.variableSelect}
          onChange={(value) => {
            setSelectedVariables(value)
          }}
        >
          {variables.map(({ id, name }) => (
            <Select.Option value={id} key={id}>
              {name}
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </>
  )
}

export default EditVariableGroupModal
