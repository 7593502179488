import { Layout } from 'antd'
import { FC } from 'react'
import styles from './PageWrapper.less'
import { ThirdGenNavBar } from '../AppNavBar'

const { Content } = Layout

interface Props {
  teamId?: number
}

const PageWrapper: FC<Props> = ({ teamId, children }) => (
  <Layout className={styles.layout}>
    <ThirdGenNavBar teamId={teamId} />
    <Content>{children}</Content>
  </Layout>
)

export default PageWrapper
