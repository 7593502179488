import { Dropdown, Menu } from 'antd'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useStoreActions } from 'stores'
import { colorCerulean } from '@signifyd/colors'
import { Pencil, useIsThirdGen } from '@signifyd/components'
import { RuleResponse } from '@signifyd/http'
import DeletePolicyModal from 'core/components/DeletePolicyModal/DeletePolicyModal'
import DuplicatePolicyMenuItem from 'core/components/DuplicatePolicyMenuItem'
import EditPolicyMenuItem from 'core/components/EditPolicyMenuItem'
import { isPublishedPolicy } from 'core/constants'
import { PolicyDetails } from 'pages/DashboardPage/components/PolicyDetailsForm/PolicyDetailsForm'
import EditPolicyModal from 'pages/DashboardPage/containers/EditPolicyModal'
import { useDeletePolicyInDashboard } from 'pages/DashboardPage/queries/useDeletePolicyInDashboard'
import { HeaderMenuIcon } from '../HeaderMenuIcon/HeaderMenuIcon'

enum MENU_KEYS {
  EditDetails = 'EditDetails',
  EditConfiguration = 'EditConfiguration',
  Delete = 'Delete',
  Duplicate = 'Duplicate',
}

interface Props {
  policy: RuleResponse
}

const SummaryHeaderActions: FC<Props> = ({ policy }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isThirdGen = useIsThirdGen()

  const [editModalVisible, setEditModalVisible] = useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)

  const { updatePolicy } = useStoreActions((actions) => actions.ruleModel)

  const isPublished = isPublishedPolicy(policy)

  const deletePolicy = useDeletePolicyInDashboard()

  const handlePolicyEdited = async ({
    name,
    description,
  }: PolicyDetails): Promise<void> => {
    try {
      await updatePolicy({ name, description, ruleId: policy.ruleId })
      setEditModalVisible(false)
    } catch (e: unknown) {
      console.error(e)
    }
  }

  const handlePolicyDeleted = async (): Promise<void> => {
    if (!policy) {
      return
    }

    const { ruleId } = policy

    await deletePolicy.mutateAsync(ruleId)

    setDeleteModalVisible(false)

    navigate('/dashboard')
  }

  return (
    <>
      <Dropdown
        placement="bottomLeft"
        trigger={['click']}
        overlay={
          <Menu>
            <Menu.Item
              key={MENU_KEYS.EditDetails}
              onClick={() => setEditModalVisible(true)}
            >
              {t('ruleCommon.dropdown.editDetails')}
            </Menu.Item>

            <EditPolicyMenuItem
              menuKey={MENU_KEYS.EditConfiguration}
              policy={policy}
            />

            <DuplicatePolicyMenuItem
              policy={policy}
              menuKey={MENU_KEYS.Duplicate}
            />

            {!isPublished && (
              <Menu.Item
                key={MENU_KEYS.Delete}
                onClick={() => setDeleteModalVisible(true)}
              >
                {t('ruleSummaryPage.summaryHeaderActions.menu.delete')}
              </Menu.Item>
            )}
          </Menu>
        }
      >
        {isThirdGen ? (
          <div data-test-id="summaryHeaderActionsMenu">
            <Pencil fill={colorCerulean} />
          </div>
        ) : (
          <div data-test-id="summaryHeaderActionsMenu">
            <HeaderMenuIcon />
          </div>
        )}
      </Dropdown>
      <EditPolicyModal
        policyToEdit={policy}
        visible={editModalVisible}
        onCancel={() => setEditModalVisible(false)}
        onSubmit={handlePolicyEdited}
      />
      <DeletePolicyModal
        policyId={policy.ruleId}
        isVisible={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
        onDelete={handlePolicyDeleted}
      />
    </>
  )
}

export default SummaryHeaderActions
