import { useQueryClient } from '@tanstack/react-query'
import { Alert, Card, Table } from 'antd'
import { nanoid } from 'nanoid'
import { Dispatch, FunctionComponent, ReactNode, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, Space } from '@signifyd/components'
import { PolicyMatchId } from '@signifyd/http'
import { SuccessIcon, FailIcon } from 'core/components/Icons'
import { Results } from 'core/hooks/useValidatePolicies/useValidatePolicies'
import {
  getIdTypeFromResults,
  getInvalidIdsFromInvalidResults,
  getOutdatedIdsFromValidationResults,
} from 'core/hooks/useValidatePolicies/utils'
import getColumns from './columns'
import styles from './RuleValidationCard.less'
import RuleValidationCardFooter from './RuleValidationCardFooter'

interface Props {
  title: ReactNode
  testId: string
  loading: boolean
  validatedResults: Results
  clearInvalidIds: () => void
  setPolicyMatchIds: Dispatch<SetStateAction<Array<PolicyMatchId>>>
  queryKey: 'matchingValidatedIds' | 'nonMatchingValidatedIds'
}

const SCROLL_PROP = { y: 165 }

const makeDeletePolicyMatchId = (index: number) => {
  return (current: Array<PolicyMatchId>): Array<PolicyMatchId> => {
    const policyMatchIds = [...current]
    policyMatchIds.splice(index, 1)

    return policyMatchIds
  }
}

const RuleValidationCard: FunctionComponent<Props> = ({
  title,
  testId,
  loading,
  clearInvalidIds,
  setPolicyMatchIds,
  queryKey,
  children,
  validatedResults: { validInvestigationResults, invalidInvestigationResults },
}) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const idType = getIdTypeFromResults([
    ...invalidInvestigationResults,
    ...validInvestigationResults,
  ])

  const handleRerunValidation = (): void => {
    clearInvalidIds()

    queryClient.invalidateQueries([queryKey])
  }

  const columns = getColumns({
    idType,
    onDeleteItem: (index) => setPolicyMatchIds(makeDeletePolicyMatchId(index)),
  })

  const total = validInvestigationResults.length
  const numFailed = validInvestigationResults.reduce((accumulator, curr) => {
    if (!curr.conditionTestResult) {
      accumulator += 1
    }

    return accumulator
  }, 0)
  const validationFailed = numFailed > 0

  const footerMessage = validationFailed
    ? `${numFailed} ${t('ruleValidationPage.validationFailed', {
        count: total,
      })}`
    : t('ruleValidationPage.validationSuccess', {
        count: total,
      })

  const footerIcon = validationFailed ? <FailIcon /> : <SuccessIcon />

  const invalidIds = getInvalidIdsFromInvalidResults(
    invalidInvestigationResults,
    idType
  )
  const outdatedIds = getOutdatedIdsFromValidationResults(
    validInvestigationResults,
    idType
  )

  const formattedInvalidIds = `${invalidIds
    .slice(0, invalidIds.length - 1)
    .join(', ')}`

  const lastInvalidId = invalidIds.slice(-1)

  return (
    <Card data-test-id={testId}>
      <Text weight="semibold" block>
        {title}
        <Text
          type="secondary"
          weight="normal"
          size="xs"
          className={styles.titleExtra}
        >
          {t('ruleValidationPage.optional')}
        </Text>
      </Text>
      <Text size="sm">{t('ruleValidationPage.durationNotice')}</Text>

      <Space size="sm" />

      {children}

      {invalidInvestigationResults?.length > 0 && (
        <>
          <Space size="sm" />
          <Alert
            closable
            type="error"
            data-test-id="invalidResultsAlert"
            onClose={clearInvalidIds}
            message={
              <Text size="sm">
                {t('ruleValidationPage.invalidResultsAlertMessage', {
                  count: invalidIds.length,
                  idType: t(`ruleValidationPage.idType.${idType}`),
                  invalidIds: formattedInvalidIds || lastInvalidId,
                  lastInvalidId,
                })}
              </Text>
            }
          />
        </>
      )}

      {outdatedIds.length > 0 && (
        <>
          <Space size="sm" />
          <Alert
            message={
              <Text size="sm">
                {t('ruleValidationPage.outdatedResultsAlertMessage', {
                  count: outdatedIds.length,
                  idType: t(`ruleValidationPage.idType.${idType}`),
                  outdatedIds,
                })}
              </Text>
            }
            type="error"
            data-test-id="outdatedResultsAlert"
          />
        </>
      )}

      {validInvestigationResults.length > 0 && (
        <>
          <Space size="sm" />
          <Table
            loading={loading}
            size="middle"
            bordered={false}
            pagination={false}
            columns={columns}
            dataSource={validInvestigationResults.map((item) => ({
              ...item,
              key: nanoid(),
            }))}
            scroll={SCROLL_PROP}
          />
          <Space size="sm" />
          <RuleValidationCardFooter
            loading={loading}
            icon={footerIcon}
            message={footerMessage}
            onRefresh={handleRerunValidation}
            canRerunValidation={!!total}
          />
        </>
      )}
    </Card>
  )
}

export default RuleValidationCard
