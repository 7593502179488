import { Serie } from '@nivo/line'
import moment, { Moment } from 'moment-timezone'
import { PolicyReportResponse } from '@signifyd/http'
import { TIMEFRAME } from 'core/components/PolicyReporting/PolicyReporting.utils'

export const convertPolicyHistorytoChartData = (
  data: PolicyReportResponse,
  type: 'Count' | 'Gmv'
): Array<Serie> => {
  return [
    {
      id: `hit${type}`,
      data: data.history
        .map((item) => ({
          x: item.timestamp,
          y: item[`hit${type}`],
        }))
        .sort((a, b) => new Date(a.x).getTime() - new Date(b.x).getTime()),
    },
  ]
}

export const formatDate = (
  date: Moment,
  timeframe: TIMEFRAME,
  isLastDate = false
): string => {
  let endDate = moment(date)
  const now = moment(moment.now())

  switch (timeframe) {
    case TIMEFRAME['24H']:
      return date.format('MMM[.] D[\n]h:mmA z')
    case TIMEFRAME['7D']:
    case TIMEFRAME['30D']:
      return date.format("MMM[. ]D,[\n']YY")
    case TIMEFRAME['90D']:
      // tooltip for 90 day timeframe should display 1 week range, i.e. Mar. 1 - 7, 8 - 14, etc.
      endDate = endDate.add(6, 'days')

      if (endDate.isSameOrAfter(now)) {
        endDate = now
      }

      return `${date.format('MMM[.] D')} - ${endDate.format("D,[\n']YY")}`
    case TIMEFRAME['180D']:
      // 180 day timeframe should display 2 week range
      endDate = endDate.add(13, 'days')

      if (endDate.isSameOrAfter(now)) {
        endDate = now
      }

      return `${date.format('MMM[.] D')} - ${endDate.format("D,[\n']YY")}`
    case TIMEFRAME['365D']:
      return isLastDate
        ? now.format("MMM[. ]D,[\n']YY")
        : date.format("MMM[. ]D,[\n']YY")
    default:
      return ''
  }
}

export const formatTooltipDate = (
  date: Moment,
  timeframe: TIMEFRAME,
  isFirstDate = false,
  isLastDate = false
): string => {
  let endDate = date.clone()
  const now = moment(moment.now())

  switch (timeframe) {
    case TIMEFRAME['24H']:
      return date.format("MMM[.] D,[ ']YY h[:00-]h[:59]A z")
    case TIMEFRAME['7D']:
    case TIMEFRAME['30D']:
      return date.format("MMM[. ]D,[ ']YY")
    case TIMEFRAME['90D']:
      // tooltip for 90 day timeframe should display 1 week range, i.e. Mar. 1 - 7, 8 - 14, etc.
      endDate = endDate.add(6, 'days')

      if (endDate.isSameOrAfter(now)) {
        endDate = now
      }

      return `${date.format("MMM[.] D, [']YY")} - ${endDate.format(
        "MMM[.] D, [']YY"
      )}`
    case TIMEFRAME['180D']:
      // 180 day timeframe should display 2 week range
      endDate = endDate.add(13, 'days')

      if (endDate.isSameOrAfter(now)) {
        endDate = now
      }

      return `${date.format("MMM[.] D, [']YY")} - ${endDate.format(
        "MMM[.] D, [']YY"
      )}`
    case TIMEFRAME['365D']:
      if (isFirstDate) {
        endDate = endDate.endOf('month')

        return `${date.format('MMM[.] D')} - ${endDate.format(
          "MMM[.] D, [']YY"
        )}`
      }

      if (isLastDate) {
        date = date.startOf('month')

        return `${date.format('MMM[.] D')} - ${now.format("MMM[.] D, [']YY")}`
      }

      return date.format("MMMM [']YY")
    default:
      return ''
  }
}
