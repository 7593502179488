import { Input } from 'antd'
import { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import styles from './EditReasonTextArea.less'

const { TextArea } = Input

interface Props {
  editReason?: string
  onChange: (text: ChangeEvent<HTMLTextAreaElement>) => void
}

const EditReasonTextArea: FC<Props> = ({ editReason, onChange }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.editReason}>
      <Text weight="semibold">{t('ruleConditionsPage.editReason.title')}</Text>
      <TextArea
        placeholder={t('ruleConditionsPage.editReason.placeholder')}
        className={styles.editReasonTextArea}
        value={editReason}
        onChange={onChange}
        required
        data-test-id="editReasonTextArea"
        data-analytics-id="editReasonTextArea"
      />
    </div>
  )
}

export default EditReasonTextArea
