import cx from 'classnames'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { VariableGroups } from '../../GroupFilterSelect'
import baseStyles from '../../GroupFilterSelect.less'
import styles from './GroupMenu.less'
import Group from '../Group/Group'

export interface GroupMenuProps {
  data: VariableGroups
  activeTab: string
  defaultTab: string
  categoryHeader?: string
  handleTabClick: (value: string) => void
  dataAnalyticsPrefix: string
}

const GroupMenu: FC<GroupMenuProps> = ({
  data,
  activeTab,
  categoryHeader,
  handleTabClick,
  dataAnalyticsPrefix,
  defaultTab,
}) => {
  const { t } = useTranslation()

  const otherGroups = Object.keys(data).filter((key) => key !== defaultTab)

  return (
    <div className={cx([baseStyles.column, styles.menu])}>
      <div className={styles.allSubsection}>
        <Group
          key={data[defaultTab].name}
          description={data[defaultTab].description}
          name={data[defaultTab].name}
          handleTabClick={handleTabClick}
          activeTab={activeTab}
          dataAnalyticsPrefix={`${dataAnalyticsPrefix}-allButton`}
        />
      </div>
      {!!otherGroups.length && (
        <div className={styles.otherSubsection}>
          <Text size="xs" className={styles.title} transform="uppercase">
            {categoryHeader ?? t('groupFilterSelect.categoryHeader')}
          </Text>

          <div className={styles.groupSection}>
            {otherGroups.map((item) => {
              return (
                <Group
                  key={item}
                  name={item}
                  description={item}
                  handleTabClick={handleTabClick}
                  activeTab={activeTab}
                  dataAnalyticsPrefix={dataAnalyticsPrefix}
                />
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
}

export default GroupMenu
