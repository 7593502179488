import { round } from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { i18nInstance } from '@signifyd/components'

export enum TIMEFRAME {
  '24H' = '24H',
  '7D' = '7D',
  '30D' = '30D',
  '90D' = '90D',
  '180D' = '180D',
  '365D' = '365D',
}

export enum POLICY_REPORTING_TYPE {
  gmv = 'gmv',
  count = 'count',
}

const timeframeKey = 'common.policyReporting.timeframe'
const { t } = i18nInstance

export const getTimeframeOptions = (): Array<{
  value: string
  label: string
}> => {
  const TIMEFRAME_OPTIONS = [
    {
      value: TIMEFRAME['24H'],
      label: t(`${timeframeKey}.24H`),
    },
    {
      value: TIMEFRAME['7D'],
      label: t(`${timeframeKey}.7D`),
    },
    {
      value: TIMEFRAME['30D'],
      label: t(`${timeframeKey}.30D`),
    },
    {
      value: TIMEFRAME['90D'],
      label: t(`${timeframeKey}.90D`),
    },
    {
      value: TIMEFRAME['180D'],
      label: t(`${timeframeKey}.180D`),
    },
    {
      value: TIMEFRAME['365D'],
      label: t(`${timeframeKey}.365D`),
    },
  ]

  return TIMEFRAME_OPTIONS
}

export const getStartDate = (endDate: Moment, timeframe: TIMEFRAME): Moment => {
  const date = endDate.clone()

  switch (timeframe) {
    case TIMEFRAME['24H']:
      return date.subtract(1, 'days').startOf('hour')
    case TIMEFRAME['7D']:
      return date.subtract(6, 'days').startOf('day')
    case TIMEFRAME['30D']:
      return date.subtract(29, 'days').startOf('day')
    case TIMEFRAME['90D']:
      return date.subtract(89, 'days').startOf('day')
    case TIMEFRAME['180D']:
      return date.subtract(179, 'days').startOf('day')
    case TIMEFRAME['365D']:
      return date.subtract(364, 'days').startOf('day')
    default:
      return date.startOf('day')
  }
}

export const getPolicyReportDateRange = (
  timeframe: TIMEFRAME
): [string, string] => {
  const endDate = moment().utc()

  const startDate = getStartDate(endDate, timeframe)

  return [startDate.toISOString(), endDate.toISOString()]
}

export const formatNumber = (
  value: number,
  policyReportingType?: POLICY_REPORTING_TYPE,
  precision?: number
): string => {
  return `${
    policyReportingType === POLICY_REPORTING_TYPE.gmv ? '$' : ''
  }${round(value, precision).toLocaleString('en', {
    useGrouping: true,
  })}`
}
