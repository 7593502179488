import { DownloadOutlined } from '@ant-design/icons'
import { Card, Divider, Button, Tooltip, Flex } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'stores'
import { useQueryParams } from 'use-query-params'
import {
  Text,
  Space,
  useTokenDownload,
  useUserContext,
} from '@signifyd/components'
import {
  getRuleSimulationDownloadToken,
  RuleSimulation,
  SIMULATION_TYPE,
} from '@signifyd/http'
import { checkpointToActionMap } from 'core/constants'
import { useTypedRouteParams } from 'pages/PublishWithSimulatorPage/hooks'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import { VIEW_TYPE } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.types'
import TotalImpactStatistic from '../../components/TotalImpactStatistic'
import { checkpointActionToImpactKeysMap } from './constants'
import styles from './TotalImpactCard.less'

interface Props {
  ruleSimulation: RuleSimulation
  isPilotRunning: boolean
}

const TotalImpactCard: FC<Props> = ({ ruleSimulation, isPilotRunning }) => {
  const { t, i18n } = useTranslation(undefined, {
    keyPrefix: 'publishWithSimulatorPage',
  })

  const { checkpoint } = useTypedRouteParams()
  const [{ viewType }] = useQueryParams(PublishPageQueryParams)
  const { user } = useUserContext()

  const { formattedCountImpact, formattedGmvImpact } = useStoreState(
    (state) => state.simulationModel
  )

  const {
    downloadSearchResults,
    isBackgroundDownloadActive,
    isDownloadPending,
  } = useTokenDownload<number>({
    getTokenFunction: getRuleSimulationDownloadToken,
    getTokenPayload: ruleSimulation.simulationId,
    baseDownloadUrl: `${BASE_URL}/v2/ruleSimulations/download/${user.userId}`,
  })

  const { simulationSize } = ruleSimulation
  const formattedImpact =
    viewType === VIEW_TYPE.GMV ? formattedGmvImpact : formattedCountImpact
  const isPilot = ruleSimulation.simulationType === SIMULATION_TYPE.PILOT_POLICY

  if (!checkpoint) {
    return null
  }

  const actions = checkpointToActionMap[checkpoint]

  if (isPilotRunning && !simulationSize) {
    return (
      <Card
        data-test-id="pilotPlaceholderData"
        title={t('totalImpactCard.title')}
        extra={
          <Text type="secondary">
            {t(
              `totalImpactCard.statisticLabels.${checkpoint}.${isPilot ? 'extraContentPilot' : 'extraContent'}`,
              {
                formattedNumber: simulationSize.toLocaleString(i18n.language),
                count: simulationSize,
              }
            )}
          </Text>
        }
      >
        <Space size="sm" />
        <Flex align="middle" justify="space-between" gap={32}>
          <div className={styles.totalImpact}>
            <TotalImpactStatistic
              isPilot={isPilot}
              title={
                <div className={styles.totalCard}>
                  {t(`totalImpactCard.statisticLabels.${checkpoint}.total`)}
                </div>
              }
              value={t('pilotSimulation.incompletePilotData')}
              percentage={t('pilotSimulation.tbd')}
              originalNumber={t('pilotSimulation.tbd')}
            />
          </div>
          <Divider type="vertical" className={styles.mainDivider} />
          <Flex align="middle" justify="space-between" flex="1 0 auto">
            {actions.map((action) => {
              return (
                <TotalImpactStatistic
                  isPilot={isPilot}
                  key={action}
                  title={t(
                    `totalImpactCard.statisticLabels.${checkpoint}.${action}` as any
                  )}
                  value={t('pilotSimulation.incompletePilotData')}
                  percentage={t('pilotSimulation.tbd')}
                  originalNumber={t('pilotSimulation.tbd')}
                />
              )
            })}
          </Flex>
        </Flex>
      </Card>
    )
  }

  if (!formattedImpact) {
    return null
  }

  return (
    <Card
      title={t('totalImpactCard.title')}
      extra={
        <Flex vertical align="center">
          <Text type="secondary">
            {t(
              `totalImpactCard.statisticLabels.${checkpoint}.${isPilot ? 'extraContentPilot' : 'extraContent'}`,
              {
                formattedNumber: simulationSize.toLocaleString(i18n.language),
                count: simulationSize,
              }
            )}
          </Text>
          <Tooltip
            placement="top"
            title={t(
              `totalImpactCard.download.${isPilot ? 'tooltipPilot' : 'tooltip'}`
            )}
            overlayClassName={styles.downloadTooltip}
          >
            <Button
              type="link"
              onClick={downloadSearchResults}
              loading={isDownloadPending}
              disabled={isBackgroundDownloadActive}
            >
              {!isDownloadPending && <DownloadOutlined />}
              {t('totalImpactCard.download.title')}
            </Button>
          </Tooltip>
        </Flex>
      }
    >
      <Space size={12} />
      <Flex align="middle" justify="space-between" gap={32}>
        <div className={styles.totalImpact}>
          <TotalImpactStatistic
            isPilot={isPilot}
            title={t(`totalImpactCard.statisticLabels.${checkpoint}.total`)}
            value={formattedImpact.newTotal}
            percentage={formattedImpact.totalChange}
            originalNumber={formattedImpact.originalTotal}
          />
        </div>
        <Divider type="vertical" className={styles.mainDivider} />
        <Flex align="middle" justify="space-between" flex="1 0 auto">
          {actions.map((action) => {
            const impactKeys = checkpointActionToImpactKeysMap[action]

            return (
              <TotalImpactStatistic
                // TODO FET-1825 https://signifyd.atlassian.net/browse/FET-1825
                // casting is needed here as the current typing of this component implies that all CHECKPOINTS can have all ACTIONS, which is not true
                // CHECKPOINT_ACTION probably needs retyping in sig-http as a generic to return based on the CHECKPOINT type
                isPilot={isPilot}
                key={action}
                title={t(
                  `totalImpactCard.statisticLabels.${checkpoint}.${action}` as any
                )}
                value={formattedImpact[impactKeys.value]}
                percentage={formattedImpact[impactKeys.percentage]}
                originalNumber={formattedImpact[impactKeys.originalNumber]}
              />
            )
          })}
        </Flex>
      </Flex>
    </Card>
  )
}

export default TotalImpactCard
