import { Button, Card } from 'antd'
import { FC } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useStoreState } from 'stores'
import { Space, TextThirdGen } from '@signifyd/components'
import { CHECKPOINT_ACTION } from '@signifyd/http'
import RuleRank from 'core/components/RuleRank'
import RuleRecommendedActionDisplay from 'core/components/RuleRecommendedActionDisplay'
import useDisplayOnlyConditionTree from 'core/hooks/useDisplayOnlyConditionTree/useDisplayOnlyConditionTree'
import { ConditionTreeNode } from 'stores/conditionTree'
import styles from './RuleSummaryCardThirdGen.less'
import RenderRulesThirdGen from '../RenderRules/RenderRulesThirdGen'

interface Props {
  isRuleValid: boolean
  conditionTree: ConditionTreeNode
  recommendedAction: CHECKPOINT_ACTION | null
  rankInActiveRuleSet?: number | null
  linkToRuleConditions?: string
  editReason?: string
}

const RuleSummaryCardThirdGen: FC<Props> = ({
  isRuleValid,
  conditionTree,
  recommendedAction,
  rankInActiveRuleSet,
  linkToRuleConditions,
  editReason,
}) => {
  const { t } = useTranslation()
  const { policy, policyFeaturesByName, predictionListMap } = useStoreState(
    (state) => state.ruleModel
  )

  /**
   * NOTE - displayOnlyConditionTree should NOT be used as an input to any API calls(esp POST/PUT requests)
   */
  const displayOnlyConditionTree = useDisplayOnlyConditionTree(
    policy,
    conditionTree,
    policyFeaturesByName,
    predictionListMap
  )

  return (
    <Card
      data-test-id="ruleSummaryCard"
      bordered={false}
      className={styles.card}
    >
      <TextThirdGen weight="semibold" paragraph>
        {t('ruleValidationPage.ruleSummaryCard.title')}
      </TextThirdGen>
      <Space size="sm" />
      {isRuleValid ? (
        <>
          <RenderRulesThirdGen conditionTree={displayOnlyConditionTree} />
          <Space size="md" />
          <TextThirdGen size="sm" paragraph>
            {t('ruleCommon.ruleSummaryCard.then')}{' '}
            <RuleRecommendedActionDisplay action={recommendedAction} />
          </TextThirdGen>
        </>
      ) : (
        <TextThirdGen data-test-id="rule-not-valid" size="sm" paragraph>
          {t('ruleCommon.ruleSummaryCard.notValid')}
        </TextThirdGen>
      )}
      {rankInActiveRuleSet && (
        <>
          <Space size="sm" />
          <TextThirdGen size="sm" paragraph>
            <span>{t('ruleCommon.ruleSummaryCard.rank')}</span>{' '}
            <RuleRank rank={rankInActiveRuleSet} />
          </TextThirdGen>
        </>
      )}
      {linkToRuleConditions && (
        <>
          <Space size="lg" />
          <Button
            type="text"
            size="small"
            href={linkToRuleConditions}
            data-test-id="editConditionsLink"
          >
            {t('ruleCommon.ruleSummaryCard.editConditions')}
          </Button>
        </>
      )}
      {editReason && (
        <>
          <Space size="lg" />
          <TextThirdGen size="sm">
            <Trans
              i18nKey="ruleCommon.ruleSummaryCard.editReason"
              components={{
                bold: <TextThirdGen weight="semibold" />,
              }}
              values={{ editReason }}
            />
          </TextThirdGen>
        </>
      )}
    </Card>
  )
}

export default RuleSummaryCardThirdGen
