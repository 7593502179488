import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { message } from 'antd'
import { AxiosError, AxiosResponse } from 'axios'
import { i18nInstance } from '@signifyd/components'
import {
  PredictionList,
  updatePredictionList,
  UpdatePredictionListPayload,
} from '@signifyd/http'
import { getHTTPErrorStatus } from 'core/http'
import { PREDICTION_LIST_DETAILS_QUERY_KEY } from '../usePredictionListDetails/usePredictionListDetails'

interface UpdatePayload {
  listId: number
  payload: UpdatePredictionListPayload
}

const useUpdatePredictionList = (): UseMutationResult<
  AxiosResponse<PredictionList>,
  AxiosError,
  UpdatePayload
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ listId, payload }) => {
      return updatePredictionList(listId, payload)
    },
    onSuccess: async (data) => {
      const predictionList = data.data

      queryClient.invalidateQueries([
        PREDICTION_LIST_DETAILS_QUERY_KEY,
        predictionList.id,
      ])
    },
    onError: (error) => {
      const status = getHTTPErrorStatus(
        error,
        i18nInstance.t('ruleCommon.fallbackErrorMessage.createPredictionList')
      )

      message.error({ content: status.error })
    },
  })
}

export default useUpdatePredictionList
