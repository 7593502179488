import { cloneDeep, isEmpty, isNumber } from 'lodash'
import { nanoid } from 'nanoid'
import { PredictionList } from '@signifyd/http'
import {
  CONDITION_TREE_DEPTH,
  REVERSED_PREDICTION_LIST_FEATURE,
  REVERSED_PREDICTION_LIST_ID_FEATURES,
} from './conditionTree.constants'
import {
  LOGICAL_OPERATOR,
  COMPARISON_OPERATOR,
  Predicate,
  PredicateLeafValue,
  AndPredicate,
  OrPredicate,
  NotPredicate,
  ComparisonPredicate,
  BooleanPredicate,
  ConditionTreeNode,
  ConditionTreeLeafNode,
  ConditionTreeLeafNodeValue,
  BOOLEAN_AS_STRING,
  RuleFeaturesByName,
  RuleFeatureWithPredictionListMapping,
  EmptyPredicate,
  NumberOfConditionsPredicate,
} from './types'
import { getEmptyLeafNode } from './utils'

function isEmptyPredicate(predicate: Predicate): predicate is EmptyPredicate {
  const keys = Object.keys(predicate)
  const operator = keys[0]

  if (
    keys.length !== 1 ||
    operator !== COMPARISON_OPERATOR.isEmptyAndMeaningless
  ) {
    return false
  }

  const [firstChild] = predicate[operator]
  if (!firstChild) {
    return false
  }

  return Object.keys(firstChild)[0] === 'property'
}

function isBooleanPredicate(
  predicate: Predicate
): predicate is BooleanPredicate {
  const keys = Object.keys(predicate)

  return keys.length === 1 && keys[0] === 'property'
}

function isNotPredicate(predicate: Predicate): predicate is NotPredicate {
  const keys = Object.keys(predicate)

  return (
    keys.length === 1 &&
    keys[0] === LOGICAL_OPERATOR.not &&
    predicate.not.length === 1
  )
}

function isAndPredicate(predicate: Predicate): predicate is AndPredicate {
  const keys = Object.keys(predicate)

  return (
    keys.length === 1 &&
    keys[0] === LOGICAL_OPERATOR.and &&
    predicate[LOGICAL_OPERATOR.and].length > 1
  )
}

function isOrPredicate(predicate: Predicate): predicate is OrPredicate {
  const keys = Object.keys(predicate)

  return (
    keys.length === 1 &&
    keys[0] === LOGICAL_OPERATOR.or &&
    predicate[LOGICAL_OPERATOR.or]?.length > 1
  )
}

function isNumberOfConditions(
  predicate: Predicate
): predicate is NumberOfConditionsPredicate {
  const keys = Object.keys(predicate)

  return (
    keys.length === 1 &&
    keys[0] === LOGICAL_OPERATOR.numberOfConditions &&
    predicate[LOGICAL_OPERATOR.numberOfConditions]?.length > 1
  )
}

function isComparisonPredicate(
  predicate: Predicate
): predicate is ComparisonPredicate {
  const keys = Object.keys(predicate)
  if (keys.length !== 1) {
    return false
  }

  const [child1, child2] = predicate[keys[0]]
  if (!child1 || !child2) {
    return false
  }

  return (
    (Object.keys(child1)[0] === 'property' &&
      Object.keys(child2)[0] === 'value') ||
    (Object.keys(child1)[0] === 'value' &&
      Object.keys(child2)[0] === 'property')
  )
}

function isConditionTreeNode(
  node: ConditionTreeNode | ConditionTreeLeafNode
): node is ConditionTreeNode {
  return 'children' in node && 'operator' in node
}

function getNodeDepth(node: ConditionTreeNode | ConditionTreeLeafNode): number {
  return isConditionTreeNode(node)
    ? 1 + Math.max(...node.children.map(getNodeDepth))
    : 1
}

function injectEmptyLevels(
  node: ConditionTreeNode | ConditionTreeLeafNode,
  targetNumLevels: number
): ConditionTreeNode | ConditionTreeLeafNode {
  const cloneNode = cloneDeep(node)
  const nodeDepth = getNodeDepth(cloneNode)

  if (nodeDepth > targetNumLevels) {
    throw new Error(
      `Unable to parse predicate with more than ${CONDITION_TREE_DEPTH} levels`
    )
  }

  if (isConditionTreeNode(cloneNode)) {
    const childrenTarget = nodeDepth - 1

    cloneNode.children = cloneNode.children.map((childNode) =>
      injectEmptyLevels(childNode, childrenTarget)
    )
  }

  if (nodeDepth === targetNumLevels) {
    return cloneNode
  }

  let root: ConditionTreeNode = {
    id: nanoid(),
    operator: null,
    children: [cloneNode],
  }
  for (let i = 0; i < targetNumLevels - nodeDepth - 1; i += 1) {
    root = {
      id: nanoid(),
      operator: null,
      children: [root],
    }
  }

  return root
}

function deserializeValue(
  value: PredicateLeafValue
): ConditionTreeLeafNodeValue {
  if (Array.isArray(value)) {
    return value
  }
  if (typeof value === 'string') {
    return value
  }
  if (isNumber(value)) {
    return String(value)
  }
  if (typeof value === 'boolean') {
    return value ? BOOLEAN_AS_STRING.true : BOOLEAN_AS_STRING.false
  }

  return null
}

function getFeatureName(
  ruleFeaturesByName: RuleFeaturesByName,
  predicateProperty: string
): RuleFeatureWithPredictionListMapping | undefined {
  const key =
    predicateProperty in REVERSED_PREDICTION_LIST_FEATURE
      ? REVERSED_PREDICTION_LIST_FEATURE[predicateProperty]
      : predicateProperty

  return ruleFeaturesByName?.get(key)
}

function getFeatureIsPredictionListIdFeature(
  predicateProperty: string
): boolean {
  return predicateProperty in REVERSED_PREDICTION_LIST_ID_FEATURES
}

function getListNameFromId(
  isPredictionListIdFeature: boolean,
  value: PredicateLeafValue,
  predictionLists: Array<PredictionList>
): PredicateLeafValue {
  if (!isPredictionListIdFeature) {
    return value
  }

  const currentList = predictionLists.find((list) => {
    return list.id === +value
  })

  return currentList?.name || value
}

function deserialize(
  predicate: Predicate,
  ruleFeaturesByName: RuleFeaturesByName,
  isNot = false
): ConditionTreeNode | ConditionTreeLeafNode {
  if (isEmpty(predicate)) {
    return getEmptyLeafNode()
  }

  if (isEmptyPredicate(predicate)) {
    const [operator] = Object.keys(predicate) as [COMPARISON_OPERATOR]
    const [child1] = predicate[operator]
    const ruleFeature = getFeatureName(ruleFeaturesByName, child1.property)
    if (!ruleFeature) {
      throw new Error('Feature is not supported')
    }

    return {
      id: nanoid(),
      ruleFeature,
      operator: COMPARISON_OPERATOR.isEmptyAndMeaningless,
      value: null,
      isNot,
    }
  }

  if (isBooleanPredicate(predicate)) {
    const ruleFeature = getFeatureName(ruleFeaturesByName, predicate.property)
    if (!ruleFeature) {
      throw new Error('Feature is not supported')
    }

    return {
      id: nanoid(),
      ruleFeature,
      operator: COMPARISON_OPERATOR.equals,
      value: BOOLEAN_AS_STRING.true,
      isNot,
    }
  }

  if (isComparisonPredicate(predicate)) {
    const [operator] = Object.keys(predicate) as [COMPARISON_OPERATOR]
    const [child1, child2] = predicate[operator]

    if ('value' in child1 && 'property' in child2) {
      const ruleFeature = getFeatureName(ruleFeaturesByName, child2.property)
      if (!ruleFeature) {
        throw new Error('Feature is not supported')
      }

      return {
        id: nanoid(),
        ruleFeature,
        operator,
        value: deserializeValue(child1.value),
        isNot,
      }
    }

    if ('property' in child1 && 'value' in child2) {
      const ruleFeature = getFeatureName(ruleFeaturesByName, child1.property)
      if (!ruleFeature) {
        throw new Error('Feature is not supported')
      }

      return {
        id: nanoid(),
        ruleFeature,
        operator,
        value: deserializeValue(child2.value),
        isNot,
      }
    }
  }

  if (isNot) {
    throw new Error('"not" can only be above the leaf level')
  }

  if (isNotPredicate(predicate)) {
    const [childNode] = predicate[LOGICAL_OPERATOR.not].map((child) =>
      deserialize(child, ruleFeaturesByName, true)
    )

    return childNode
  }

  if (isAndPredicate(predicate)) {
    const children = predicate[LOGICAL_OPERATOR.and].map((child) =>
      deserialize(child, ruleFeaturesByName)
    )
    const node: ConditionTreeNode = {
      id: nanoid(),
      operator: LOGICAL_OPERATOR.and,
      children,
    }

    return node
  }

  if (isOrPredicate(predicate)) {
    const children = predicate[LOGICAL_OPERATOR.or].map((child) =>
      deserialize(child, ruleFeaturesByName)
    )
    const node: ConditionTreeNode = {
      id: nanoid(),
      operator: LOGICAL_OPERATOR.or,
      children,
    }

    return node
  }

  if (isNumberOfConditions(predicate)) {
    const [numberOfConditionsValueNode, ...rest] =
      predicate[LOGICAL_OPERATOR.numberOfConditions]

    const children = rest.map((child) => deserialize(child, ruleFeaturesByName))

    const node: ConditionTreeNode = {
      id: nanoid(),
      operator: LOGICAL_OPERATOR.numberOfConditions,
      children,
      numberOfConditions: numberOfConditionsValueNode.value,
    }

    return node
  }

  throw new Error('Not a valid predicate')
}

/**
 * Similar to deserialize method above.
 * Replaces PredictionList id values with PredictionList name values.
 * Should only be used for displaying purposes.
 */
function deserializeWithListIdReplacement(
  predicate: Predicate,
  ruleFeaturesByName: RuleFeaturesByName,
  predictionLists: Array<PredictionList>,
  isNot = false
): ConditionTreeNode | ConditionTreeLeafNode {
  if (isEmpty(predicate)) {
    return getEmptyLeafNode()
  }

  if (isEmptyPredicate(predicate)) {
    const [operator] = Object.keys(predicate) as [COMPARISON_OPERATOR]
    const [child1] = predicate[operator]
    const ruleFeature = getFeatureName(ruleFeaturesByName, child1.property)
    if (!ruleFeature) {
      throw new Error('Feature is not supported')
    }

    return {
      id: nanoid(),
      ruleFeature,
      operator: COMPARISON_OPERATOR.isEmptyAndMeaningless,
      value: null,
      isNot,
    }
  }

  if (isBooleanPredicate(predicate)) {
    const ruleFeature = getFeatureName(ruleFeaturesByName, predicate.property)
    if (!ruleFeature) {
      throw new Error('Feature is not supported')
    }

    return {
      id: nanoid(),
      ruleFeature,
      operator: COMPARISON_OPERATOR.equals,
      value: BOOLEAN_AS_STRING.true,
      isNot,
    }
  }

  if (isComparisonPredicate(predicate)) {
    const [operator] = Object.keys(predicate) as [COMPARISON_OPERATOR]
    const [child1, child2] = predicate[operator]

    if ('value' in child1 && 'property' in child2) {
      const ruleFeature = getFeatureName(ruleFeaturesByName, child2.property)
      const isPredictionListIdFeature = getFeatureIsPredictionListIdFeature(
        child2.property
      )
      const value = getListNameFromId(
        isPredictionListIdFeature,
        child1.value,
        predictionLists
      )
      if (!ruleFeature) {
        throw new Error('Feature is not supported')
      }

      return {
        id: nanoid(),
        ruleFeature,
        operator,
        value: deserializeValue(value),
        isNot,
      }
    }

    if ('property' in child1 && 'value' in child2) {
      const ruleFeature = getFeatureName(ruleFeaturesByName, child1.property)
      const isPredictionListIdFeature = getFeatureIsPredictionListIdFeature(
        child1.property
      )
      const value = getListNameFromId(
        isPredictionListIdFeature,
        child2.value,
        predictionLists
      )
      if (!ruleFeature) {
        throw new Error('Feature is not supported')
      }

      return {
        id: nanoid(),
        ruleFeature,
        operator,
        value: deserializeValue(value),
        isNot,
      }
    }
  }

  if (isNot) {
    throw new Error('"not" can only be above the leaf level')
  }

  if (isNotPredicate(predicate)) {
    const [childNode] = predicate[LOGICAL_OPERATOR.not].map((child) =>
      deserializeWithListIdReplacement(
        child,
        ruleFeaturesByName,
        predictionLists,
        true
      )
    )

    return childNode
  }

  if (isAndPredicate(predicate)) {
    const children = predicate[LOGICAL_OPERATOR.and].map((child) =>
      deserializeWithListIdReplacement(
        child,
        ruleFeaturesByName,
        predictionLists
      )
    )
    const node: ConditionTreeNode = {
      id: nanoid(),
      operator: LOGICAL_OPERATOR.and,
      children,
    }

    return node
  }

  if (isOrPredicate(predicate)) {
    const children = predicate[LOGICAL_OPERATOR.or].map((child) =>
      deserializeWithListIdReplacement(
        child,
        ruleFeaturesByName,
        predictionLists
      )
    )
    const node: ConditionTreeNode = {
      id: nanoid(),
      operator: LOGICAL_OPERATOR.or,
      children,
    }

    return node
  }

  if (isNumberOfConditions(predicate)) {
    const [numberOfConditionsValueNode, ...rest] =
      predicate[LOGICAL_OPERATOR.numberOfConditions]

    const children = rest.map((child) =>
      deserializeWithListIdReplacement(
        child,
        ruleFeaturesByName,
        predictionLists
      )
    )

    const node: ConditionTreeNode = {
      id: nanoid(),
      operator: LOGICAL_OPERATOR.numberOfConditions,
      children,
      numberOfConditions: numberOfConditionsValueNode.value,
    }

    return node
  }

  throw new Error('Not a valid predicate')
}

export function deserializePredicateToLegacyConditionTree(
  predicate: Predicate,
  ruleFeaturesByName: RuleFeaturesByName
): ConditionTreeNode {
  const node = deserialize(predicate, ruleFeaturesByName)
  const tree = injectEmptyLevels(node, CONDITION_TREE_DEPTH)
  if (!isConditionTreeNode(tree)) {
    throw new Error('Not a valid predicate')
  }

  return tree
}

/**
 * Similar to deserializePredicateToLegacyConditionTree method above.
 * Replaces PredictionList id values with PredictionList name values.
 * Returning ConditionTreeNode should only be used for displaying purposes.
 */
export function deserializePredicateWithListIdReplacement(
  predicate: Predicate,
  ruleFeaturesByName: RuleFeaturesByName,
  predictionLists: Array<PredictionList>
): ConditionTreeNode {
  const node = deserializeWithListIdReplacement(
    predicate,
    ruleFeaturesByName,
    predictionLists
  )
  const tree = injectEmptyLevels(node, CONDITION_TREE_DEPTH)
  if (!isConditionTreeNode(tree)) {
    throw new Error('Not a valid predicate')
  }

  return tree
}

export function isPredicateUsingPredictionListFeatures(
  predicate: string
): boolean {
  return Object.keys(REVERSED_PREDICTION_LIST_ID_FEATURES).some((feature) =>
    predicate.includes(feature)
  )
}
