import { Button, Popconfirm, Progress } from 'antd'
import moment from 'moment-timezone'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreActions, useStoreState } from 'stores'
import { colorBondi } from '@signifyd/colors'
import {
  RangePickerValue,
  Space,
  StretchToPageBottom,
  Text,
} from '@signifyd/components'
import useUserTimeZone from 'core/hooks/useUserTimeZone'
import { runningSimulationDateFormat } from 'stores/simulation/simulation.constants'
import styles from './RunningSimulationContainer.less'

interface Props {
  simulationId: number | null
  dateRange: NonNullable<RangePickerValue>
}

const RunningSimulationContainer: FC<Props> = ({ simulationId, dateRange }) => {
  const { t } = useTranslation()
  const { cancelRuleSimulation } = useStoreActions(
    (actions) => actions.simulationModel
  )

  const progressInDecimal = useStoreState(
    (state) => state.simulationModel.progressInDecimal
  )
  const isLoading = useStoreState(
    (state) => state.simulationModel.isLoading.cancelRuleSimulation
  )
  const userTimeZone = useUserTimeZone()

  const [startDate, endDate] = dateRange

  if (!simulationId || !startDate || !endDate) {
    return null
  }

  return (
    <StretchToPageBottom centerChildren className={styles.container}>
      <div data-test-id="runningSimulationContainer">
        <div className={styles.progressBar}>
          <Progress
            strokeLinecap="round"
            strokeColor={colorBondi}
            status="active"
            percent={Math.floor((progressInDecimal || 0) * 100)}
          />
        </div>
        <Space size="lg" />
        <Space size="xs" />
        <Text block type="secondary" size="sm">
          {t('publishWithSimulatorPage.runningSimulation.description', {
            startDate: moment
              .tz(startDate, userTimeZone)
              .format(runningSimulationDateFormat),
            endDate: moment
              .tz(endDate, userTimeZone)
              .format(runningSimulationDateFormat),
          })}
        </Text>
        <Space size="lg" />
        <Space size="lg" />
        <Space size="md" />
        <Popconfirm
          overlayClassName="sig-popconfirm"
          placement="top"
          icon={null}
          title={
            <>
              <Text block weight="semibold">
                {t(
                  'publishWithSimulatorPage.runningSimulation.cancelSimulationPopconfirm.title'
                )}
              </Text>
              <Space size={12} />
              <Text block type="secondary" size="sm">
                {t(
                  'publishWithSimulatorPage.runningSimulation.cancelSimulationPopconfirm.description'
                )}
              </Text>
            </>
          }
          onConfirm={() => cancelRuleSimulation(simulationId)}
          okText={t(
            'publishWithSimulatorPage.runningSimulation.cancelSimulationPopconfirm.okText'
          )}
          cancelText={t(
            'publishWithSimulatorPage.runningSimulation.cancelSimulationPopconfirm.cancelText'
          )}
        >
          <Button type="default" loading={isLoading}>
            {t(
              'publishWithSimulatorPage.runningSimulation.cancelSimulationBtn'
            )}
          </Button>
        </Popconfirm>
      </div>
    </StretchToPageBottom>
  )
}

export default RunningSimulationContainer
