import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'stores'
import {
  GET_RULES_ORDER_BY,
  PolicyMatchId,
  RULE_STAGE,
  RuleResponse,
} from '@signifyd/http'
import { getHTTPErrorStatus, messageOnError } from 'core/http'
import { UpdatePolicyPayload } from 'stores/rule'

const createUpdatePolicyBody = (
  isEditing: boolean,
  policy: RuleResponse,
  matchingPolicyMatchIds: Array<PolicyMatchId>,
  nonMatchingPolicyMatchIds: Array<PolicyMatchId>
): UpdatePolicyPayload => {
  const isPublished = !!policy.rankInActiveRuleSet

  const updatePolicyBody: UpdatePolicyPayload = {
    ruleId: policy.ruleId,
  }

  if (isEditing) {
    // If user is editing the policy validation, we'll be using the match IDs decided in the UI and send them
    // as draft versions.

    updatePolicyBody.draftMatchingPolicyMatchIds = matchingPolicyMatchIds
    updatePolicyBody.draftNonMatchingPolicyMatchIds = nonMatchingPolicyMatchIds

    // If policy isn't published, supply "applyEdits" in the update mask, but no value.
    if (!isPublished) {
      updatePolicyBody.applyEdits = undefined
    }
  } else {
    // Otherwise, we send them as new.
    updatePolicyBody.ruleStage = RULE_STAGE.NEW
    updatePolicyBody.nonMatchingPolicyMatchIds = nonMatchingPolicyMatchIds
    updatePolicyBody.matchingPolicyMatchIds = matchingPolicyMatchIds
  }

  return updatePolicyBody
}

const createDashboardRedirectParams = (
  isEditing: boolean,
  { teamId, ruleId, checkpoint }: RuleResponse
): Array<string> => {
  const teamIdParam = `teamId=${teamId}`
  const orderByParam = `orderBy=${GET_RULES_ORDER_BY.SORT_CREATED_AT}`
  const successModalParam = isEditing
    ? `editedPolicyId=${ruleId}`
    : 'showNewRules=true'

  const checkpointParam = `checkpoint=${checkpoint}`
  const publishedOrderByParam = `publishedOrderBy=${GET_RULES_ORDER_BY.SORT_RANK}`
  const otherOrderByParam = `othersOrderBy=${GET_RULES_ORDER_BY.SORT_CREATED_AT}`

  return [
    teamIdParam,
    orderByParam,
    successModalParam,
    checkpointParam,
    publishedOrderByParam,
    otherOrderByParam,
  ]
}

interface UseFinishPolicyValidationOptions {
  isEditing: boolean
  policy: RuleResponse
  matchingPolicyMatchIds: Array<PolicyMatchId>
  nonMatchingPolicyMatchIds: Array<PolicyMatchId>
}

const useFinishPolicyValidation = ({
  isEditing,
  policy,
  matchingPolicyMatchIds,
  nonMatchingPolicyMatchIds,
}: UseFinishPolicyValidationOptions): (() => Promise<void>) => {
  const { checkpoint } = policy

  const { t } = useTranslation()
  const navigate = useNavigate()

  const { policyHTTPStatuses } = useStoreState((state) => state.ruleModel)
  const { updatePolicy, setState: setRuleStoreState } = useStoreActions(
    (actions) => actions.ruleModel
  )

  return async () => {
    const isPublished = !!policy.rankInActiveRuleSet

    const updatePolicyBody = createUpdatePolicyBody(
      isEditing,
      policy,
      matchingPolicyMatchIds,
      nonMatchingPolicyMatchIds
    )

    try {
      await updatePolicy(updatePolicyBody)

      // If user is editing a policy and said policy is already published, we redirect them straight to simulation.
      if (isEditing && isPublished) {
        navigate(
          `/policies/publish/${policy.teamId}/${checkpoint}?editedPolicyId=${policy.ruleId}`
        )

        return
      }

      // Otherwise, they go back to the dashboard.
      const params = createDashboardRedirectParams(isEditing, policy)

      navigate(`/policies/dashboard?${params.join('&')}`)
    } catch (e) {
      messageOnError(t('ruleCommon.fallbackErrorMessage.createRule'))(e)

      const status = getHTTPErrorStatus(
        e,
        t('ruleCommon.fallbackErrorMessage.updateRule')
      )

      setRuleStoreState({
        policyHTTPStatuses: {
          createPolicy: policyHTTPStatuses.createPolicy,
          updatePolicy: status,
        },
      })
    }
  }
}

export default useFinishPolicyValidation
