import { Card } from 'antd'
import { FunctionComponent } from 'react'
import { Text } from '@signifyd/components'
import { RuleResponse } from '@signifyd/http'
import RuleRecommendedActionDisplay from 'core/components/RuleRecommendedActionDisplay'
import styles from './RuleSuccessModalRule.less'

interface Props {
  rule: RuleResponse
}

const BODY_STYLE = {
  paddingTop: 8,
  paddingBottom: 8,
}

const RuleSuccessModalRule: FunctionComponent<Props> = ({ rule }) => (
  <Card size="small" styles={{ body: BODY_STYLE }}>
    <div className={styles.ruleDetails}>
      <Text weight="semibold">{rule.name}</Text>
      <RuleRecommendedActionDisplay
        action={rule.ruleOutcome.ruleRecommendedAction}
      />
    </div>
  </Card>
)

export default RuleSuccessModalRule
