import { Divider, Row, Col } from 'antd'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Space, StretchToPageBottom, Text } from '@signifyd/components'
import { PREDICTION_LIST_TYPE, PredictionList } from '@signifyd/http'
import { arrayOneOrMore } from '@signifyd/utils'

import Collapse from 'core/components/Collapse'
import FlippedStatistic from 'core/components/FlippedStatistic'
import { PAGE_PADDING } from 'core/constants'
import useUserPermissions from 'core/hooks/useUserPermissions'
import CreateListEntriesButtonWithModal from 'pages/ListDetailsPage/containers/CreateListEntriesButtonWithModal'
import DeleteListButtonWithModal from 'pages/ListDetailsPage/containers/DeleteListButtonWithModal'
import DownloadListEntriesButton from 'pages/ListDetailsPage/containers/DownloadListEntriesButton'
import ListLastEvent from 'pages/ListDetailsPage/containers/ListLastEvent'
import ListRuleDropdown from 'pages/ListDetailsPage/containers/ListRuleDropdown'
import ReplaceListEntriesButtonWithModal from 'pages/ListDetailsPage/containers/ReplaceListEntriesButtonWithModal'
import styles from './ListDetailsPageSidePanel.less'
import ListExpirations from './ListExpirations'
import { getExpirations } from './listExpirationUtils'

const { Panel } = Collapse

interface Props {
  predictionList: PredictionList
}

const ListDetailsPageSidePanel: FC<Props> = ({ predictionList }) => {
  const { t } = useTranslation()
  const [showExpiration, setShowExpiration] = useState<boolean>(false)

  const { expiration, type } = predictionList
  const expirationCount = getExpirations(expiration).length
  const { userCanEdit } = useUserPermissions()

  const renderExpiration = ![
    PREDICTION_LIST_TYPE.PROMO_ABUSE,
    PREDICTION_LIST_TYPE.RESELLER_ABUSE,
  ].includes(type)

  return (
    <StretchToPageBottom marginBottom={PAGE_PADDING}>
      <div className={styles.panel}>
        <Row gutter={6}>
          <Col span={12}>
            <FlippedStatistic
              title={t('listDetailsPage.sidePanel.listSize')}
              value={predictionList.size}
              data-test-id="listSize"
            />
          </Col>
          <Col span={12}>
            <FlippedStatistic
              title={t('listDetailsPage.sidePanel.numRulesInList')}
              value={predictionList.ruleIds.length}
              suffix={
                arrayOneOrMore(predictionList.ruleIds) ? (
                  <ListRuleDropdown ruleIds={predictionList.ruleIds} />
                ) : undefined
              }
              data-test-id="numRulesInList"
            />
          </Col>
          {renderExpiration && (
            <Col span={12}>
              <div onClick={() => setShowExpiration(true)}>
                <FlippedStatistic
                  title={t('listDetailsPage.sidePanel.expirationCount')}
                  value={expirationCount}
                  data-test-id="expirationCount"
                />
              </div>
            </Col>
          )}
        </Row>
        <div>
          <Collapse defaultActiveKey={[]} className={styles.collapse}>
            <Panel
              header={
                <Text weight="semibold" data-test-id="descriptionTitle">
                  {t('listDetailsPage.sidePanel.descriptionTitle')}
                </Text>
              }
              key="1"
              theme="transparent"
            >
              <div
                className={styles.description}
                data-test-id="descriptionValue"
              >
                {predictionList.description}
              </div>
            </Panel>
          </Collapse>
          <Divider />
          {renderExpiration && (
            <>
              <Collapse
                defaultActiveKey={[]}
                className={styles.collapse}
                activeKey={showExpiration ? ['2'] : []}
                onChange={() => setShowExpiration(!showExpiration)}
              >
                <Panel
                  header={
                    <Text weight="semibold" data-test-id="expirationTitle">
                      {t('listDetailsPage.sidePanel.expirationConfiguration')}
                    </Text>
                  }
                  key="2"
                  theme="transparent"
                >
                  <ListExpirations predictionList={predictionList} />
                </Panel>
              </Collapse>
              <Divider />
            </>
          )}
          <Text block weight="semibold">
            {t('listDetailsPage.sidePanel.lastActivity')}
          </Text>
          <Space size="md" />
          {predictionList.lastEvent && (
            <ListLastEvent
              updatedAt={predictionList.updatedAt}
              listType={predictionList.type}
              updatedByName={predictionList.updatedByName}
              lastEvent={predictionList.lastEvent}
            />
          )}
          <Divider />
        </div>
        <div className={styles.buttons}>
          <Text block weight="semibold">
            {t('listDetailsPage.sidePanel.actions')}
          </Text>
          <Space size="md" />
          {userCanEdit && (
            <>
              <CreateListEntriesButtonWithModal
                predictionList={predictionList}
              />
              <Space size="sm" />
            </>
          )}
          <DownloadListEntriesButton
            disabled={predictionList.size === 0}
            predictionListId={predictionList.id}
          />
          <Space size="sm" />
          {userCanEdit && (
            <>
              <ReplaceListEntriesButtonWithModal
                disabled={predictionList.size === 0}
                predictionList={predictionList}
              />
              <Space size="sm" />
              <DeleteListButtonWithModal
                predictionListId={predictionList.id}
                disabled={predictionList.ruleIds.length > 0}
              />
            </>
          )}
        </div>
      </div>
    </StretchToPageBottom>
  )
}

export default ListDetailsPageSidePanel
