import { ResponsiveLine, Serie } from '@nivo/line'
import moment from 'moment-timezone'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { colorYonder } from '@signifyd/colors'
import { Text } from '@signifyd/components'
import {
  formatNumber,
  POLICY_REPORTING_TYPE,
  TIMEFRAME,
} from 'core/components/PolicyReporting/PolicyReporting.utils'
import classnames from 'classnames/bind'
import {
  formatAxisDate,
  formatAxisNumber,
  formatTooltipDate,
  getTickValues,
  renderTick,
} from '../PolicyReportingChart.utils'
import DashedLine from './components/DashedLine'
import Points from './components/Points'
import styles from './LineGraph.less'

const cx = classnames.bind(styles)

interface LineGraphProps {
  data: Array<Serie>
  policyReportingType: POLICY_REPORTING_TYPE
  timeframe: TIMEFRAME
}

const LineGraph: FC<LineGraphProps> = ({
  data,
  policyReportingType,
  timeframe,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'ruleDashboardPage.reporting',
  })

  const [animate, setAnimate] = useState(false)

  const tickValues = getTickValues(data, timeframe)

  return (
    <div className={cx('container')}>
      <ResponsiveLine
        data={data}
        margin={{
          top: 5,
          bottom: 5,
          right: 40,
          left: 60,
        }}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: colorYonder,
                strokeWidth: 1,
              },
            },
            ticks: {
              line: { stroke: colorYonder, strokeWidth: 1 },
            },
          },
        }}
        curve="linear"
        enableGridX
        enableGridY={false}
        animate={animate}
        onMouseEnter={() => setAnimate(true)}
        onMouseLeave={() => setAnimate(false)}
        crosshairType="x"
        enableSlices="x"
        enableCrosshair
        useMesh
        layers={[
          'grid',
          'markers',
          'axes',
          'areas',
          'crosshair',
          DashedLine,
          Points,
          'slices',
          'mesh',
          'legends',
        ]}
        sliceTooltip={({ slice }) => (
          <div className={styles.tooltip}>
            <Text>
              {formatTooltipDate(
                moment(slice.points[0].data.x).utc(),
                timeframe
              )}
            </Text>
            {slice.points.some((point) => point.serieId !== 'None') && (
              <div className={styles.hits}>
                {slice.points.map((point) => (
                  <Text key={point.id}>
                    {t(`chart.tooltip`, {
                      context: point.serieId,
                      value: formatNumber(
                        +point.data.yFormatted,
                        policyReportingType
                      ),
                      defaultValue: '0',
                    })}
                  </Text>
                ))}
              </div>
            )}
          </div>
        )}
        axisBottom={{
          tickValues,
          renderTick: (tick) => {
            const isFirstPoint = tick.tickIndex === 0
            const isLastPoint = tick.tickIndex === tickValues.length - 1

            return renderTick(
              tick,
              formatAxisDate(
                moment(tick.value).utc(),
                timeframe,
                isFirstPoint,
                isLastPoint,
                tick.x
              ),
              'x'
            )
          },
        }}
        axisLeft={{
          tickValues: 5,
          renderTick: (tick) => {
            if (tick.value === 0) {
              return <></>
            }

            return renderTick(
              tick,
              formatAxisNumber(tick.value, policyReportingType),
              'y'
            )
          },
        }}
        yScale={
          data.every((datum) => datum.data.every(({ y }) => y === 0))
            ? {
                type: 'linear',
                min: 0,
                max: policyReportingType === 'gmv' ? 500 : 50,
              }
            : undefined
        }
      />
    </div>
  )
}

export default LineGraph
