import { ClockCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import moment from 'moment-timezone'
import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { RuleResponse } from '@signifyd/http'
import { joinClassNames } from '@signifyd/utils'
import FormatDate from 'core/components/FormatDate'
import useUserTimeZone from 'core/hooks/useUserTimeZone'
import styles from './RuleSchedule.less'

interface Props {
  rule: RuleResponse
  showIcon?: boolean
  onClick?: () => void
}

const RuleSchedule: FunctionComponent<Props> = ({
  rule,
  showIcon,
  onClick,
}) => {
  const { t } = useTranslation()
  const userTimeZone = useUserTimeZone()
  const { scheduleStart, scheduleEnd } = rule

  if (!scheduleStart && !scheduleEnd) {
    return onClick ? (
      <Button
        type="link"
        onClick={onClick}
        className={styles.addScheduleBtn}
        data-analytics-id="add-schedule-button"
      >
        + {t('ruleCommon.ruleSchedule.addScheduleButtonText')}
      </Button>
    ) : null
  }

  const startWrapper = scheduleStart && (
    <FormatDate timeZone={userTimeZone} UTCTimestamp={scheduleStart} />
  )

  const endWrapper = scheduleEnd && (
    <FormatDate timeZone={userTimeZone} UTCTimestamp={scheduleEnd} />
  )

  const expired =
    scheduleEnd && moment(scheduleEnd).isBefore(new Date(), 'second')

  return (
    <div
      className={joinClassNames([styles.schedule, expired && styles.expired])}
    >
      {showIcon && (
        <>
          <ClockCircleOutlined
            onClick={onClick || undefined}
            className={joinClassNames([
              styles.icon,
              !expired && styles.normalScheduleIcon,
            ])}
          />{' '}
        </>
      )}
      <div
        className={styles.datesWrapper}
        onClick={onClick}
        data-test-id={`ruleScheduleWrapper-${rule.ruleId}`}
      >
        {scheduleStart && <span className={styles.date}>{startWrapper}</span>}
        <span className={styles.hyphen}>—</span>
        {endWrapper && <span className={styles.date}>{endWrapper}</span>}
      </div>
    </div>
  )
}

export default RuleSchedule
