import { Steps, Tooltip } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'stores'
import { useQueryParams } from 'use-query-params'
import {
  RuleResponse,
  SIMULATION_STATUS,
  SIMULATION_TYPE,
} from '@signifyd/http'
import { getHTTPErrorMessage } from 'core/http'
import useGetRuleSet from 'core/queries/useGetRuleSet/useGetRuleSet'
import useGetRulesByRuleSet from 'pages/PublishWithSimulatorPage/hooks/useGetRulesByRuleSet/useGetRulesByRuleSet'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import { SPLIT_SCREEN_STEP } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.types'
import { getExpiredListMessage } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.utils'
import { maxRuleLimitExceededMessage } from 'locales/utils/maxRuleExceededMessage'
import {
  RULEDND_STEPS_GRID_SPAN,
  SIMULATIONCONTAINER_STEPS_GRID_SPAN,
} from './PublishWithSimulatorPageContent'
import styles from './StepsBar.less'

interface Props {
  current?: SPLIT_SCREEN_STEP
  isError: boolean
  isUpdatingRuleSet: boolean
  error: unknown
  expiredRules: Array<RuleResponse>
}

const StepsBar: FC<Props> = ({
  current = SPLIT_SCREEN_STEP.STEP_ONE,
  isError,
  isUpdatingRuleSet,
  expiredRules,
  error,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'publishWithSimulatorPage.publishStepsBar',
  })

  const [{ editedPolicyId, ruleSetId, simulationType }, setQueryParams] =
    useQueryParams(PublishPageQueryParams)

  // Combine the query param and make this useGetPublishPageRuleSet
  const { data: ruleSet } = useGetRuleSet(ruleSetId)
  const { isFetching } = useGetRulesByRuleSet(ruleSetId)

  const { ruleSimulation } = useStoreState((store) => store.simulationModel)

  const excessRuleCountError = ruleSet?.excessRuleCount
    ? maxRuleLimitExceededMessage(ruleSet.maxRuleLimit, ruleSet.excessRuleCount)
    : ''

  const expiryMessage = getExpiredListMessage(expiredRules)
  const errorMessage = getHTTPErrorMessage(error, excessRuleCountError)
  const isStepOne = current === SPLIT_SCREEN_STEP.STEP_ONE
  const isEditing = !!editedPolicyId
  const isPilot =
    ruleSimulation?.simulationType === SIMULATION_TYPE.PILOT_POLICY
  const isPilotCompleted =
    isPilot && ruleSimulation.status === SIMULATION_STATUS.FINISHED

  const stepOneDisabled = isEditing || isPilotCompleted
  const stepTwoDisabled =
    isError ||
    isFetching ||
    isUpdatingRuleSet ||
    !!ruleSet?.excessRuleCount ||
    !!expiredRules.length

  const stepOnePilotTooltipMessage =
    stepOneDisabled && t('stepOnePilotCompleteTooltip')
  const stepTwoTooltipMessage = expiryMessage || errorMessage

  return (
    <Steps
      type="navigation"
      size="small"
      current={isStepOne ? 0 : 1}
      onChange={() => {
        setQueryParams({
          step: isStepOne
            ? SPLIT_SCREEN_STEP.STEP_TWO
            : SPLIT_SCREEN_STEP.STEP_ONE,
          editPilot:
            simulationType === SIMULATION_TYPE.PILOT_POLICY ? true : undefined,
        })
      }}
      items={[
        {
          className: stepOneDisabled ? styles.disabled : undefined,
          disabled: stepOneDisabled,
          status: isStepOne ? 'process' : 'wait',
          style: { flexGrow: isStepOne ? 1 : RULEDND_STEPS_GRID_SPAN },
          title: isPilot ? (
            <Tooltip placement="top" title={stepOnePilotTooltipMessage}>
              {t('stepOnePilot')}
            </Tooltip>
          ) : (
            t('stepOne')
          ),
          ...{
            'data-test-id': 'stepsBarStep1',
            'data-analytics-id': 'steps-bar-step-1',
          },
        },
        {
          className: stepTwoDisabled ? styles.disabled : undefined,
          disabled: stepTwoDisabled,
          style: {
            flexGrow: isStepOne ? 1 : SIMULATIONCONTAINER_STEPS_GRID_SPAN,
          },
          title: (
            <Tooltip placement="top" title={stepTwoTooltipMessage}>
              {t('stepTwo')}
            </Tooltip>
          ),
          ...{
            'data-test-id': 'stepsBarStep2',
            'data-analytics-id': 'steps-bar-step-2',
          },
        },
      ]}
      className={styles.steps}
    />
  )
}

export default StepsBar
