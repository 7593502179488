import { Input, Tooltip, Form } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Space } from '@signifyd/components'
import FormError from 'core/components/FormError'
import styles from './ListDetailsForm.less'

const { Item } = Form

const NAME_MAX_LEN = 40
const DESCRIPTION_MAX_LEN = 255

export interface Props {
  name?: string
  description?: string
  disabled?: boolean
  errorMsg?: string
  onChangeName: (name: string) => void
  onChangeDescription: (description: string) => void
  usedInPolicy: boolean
}

const ListDetailsForm: FC<Props> = ({
  name,
  description,
  disabled,
  errorMsg,
  onChangeName,
  onChangeDescription,
  usedInPolicy,
}) => {
  const { t } = useTranslation()

  return (
    <Form colon={false} data-test-id="listDetailsForm" layout="vertical">
      <Item
        label={t('listCommon.listDetailsForm.nameLabel')}
        className={styles.formItem}
      >
        <Tooltip
          placement="bottomLeft"
          title={
            usedInPolicy && t('listCommon.listDetailsForm.nameUpdateDisabled')
          }
        >
          {/* the span is necessary to trigger the Tooltip on a disabled Input */}
          <span>
            <Input
              data-test-id="listDetailsNameInput"
              disabled={usedInPolicy || disabled}
              placeholder={t('listCommon.listDetailsForm.namePlaceholder', {
                max: NAME_MAX_LEN,
              })}
              maxLength={NAME_MAX_LEN}
              value={name ?? ''}
              onChange={(e) => onChangeName(e.target.value)}
            />
          </span>
        </Tooltip>
      </Item>
      <Space size="sm" />
      <Item
        label={t('listCommon.listDetailsForm.descriptionLabel')}
        className={styles.formItem}
      >
        <Input.TextArea
          data-test-id="listDetailsDescription"
          maxLength={DESCRIPTION_MAX_LEN}
          disabled={disabled}
          placeholder={t('listCommon.listDetailsForm.descriptionPlaceholder')}
          value={description}
          onChange={(e) => onChangeDescription(e.target.value)}
        />
      </Item>
      <FormError error={errorMsg} />
    </Form>
  )
}

export default ListDetailsForm
