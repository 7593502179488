import cx from 'classnames'
import { FC } from 'react'
import { Text } from '@signifyd/components'
import styles from './Group.less'

export interface GroupProps {
  name: string
  description: string
  activeTab: string
  handleTabClick: (value: string) => void
  dataAnalyticsPrefix: string
}

const Group: FC<GroupProps> = ({
  name,
  description,
  activeTab,
  handleTabClick,
  dataAnalyticsPrefix,
}) => {
  return (
    <button
      type="button"
      role="tab"
      key={name}
      data-analytics-id={`${dataAnalyticsPrefix}-${name}`}
      className={cx(styles.group, activeTab === name && styles.active)}
      onClick={() => handleTabClick(name)}
    >
      <Text weight={activeTab === name ? 'semibold' : 'normal'} size="sm">
        {description}
      </Text>
    </button>
  )
}

export default Group
