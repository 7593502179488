import { Button, Flex, Tooltip } from 'antd'
import moment from 'moment-timezone'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DecodedValueMap, useQueryParams } from 'use-query-params'
import { Text, Space } from '@signifyd/components'
import { RuleSimulation, SIMULATION_TYPE } from '@signifyd/http'
import { PilotPreferenceModal } from 'pages/PublishWithSimulatorPage/components/PilotPreferenceModal/PilotPreferenceModal'
import { useTypedRouteParams } from 'pages/PublishWithSimulatorPage/hooks'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import { simulationDateFormat } from 'stores/simulation'
import styles from './NoResultsDescription.less'

type Props = {
  pilotSimulation?: RuleSimulation
  createSimulationForTeam: (
    filters: DecodedValueMap<typeof PublishPageQueryParams>,
    simulationType: SIMULATION_TYPE | undefined,
    teamId: number,
    startDate?: string,
    endDate?: string,
    emailAddresses?: Array<string>
  ) => void
}

export const NoResultsDescription: FC<Props> = ({
  pilotSimulation,
  createSimulationForTeam,
}) => {
  const { t } = useTranslation()
  const [query, setQuery] = useQueryParams(PublishPageQueryParams)
  const [showModal, setShowModal] = useState(false)

  const { teamId } = useTypedRouteParams()

  return (
    <>
      <Flex vertical>
        <Text size="sm" className={styles.description}>
          {t(
            'publishWithSimulatorPage.pilotSimulation.simulationResults.noResults'
          )}
        </Text>
        <Tooltip
          title={
            pilotSimulation
              ? t(
                  'publishWithSimulatorPage.pilotSimulation.simulationResults.configurePilotDisabled'
                )
              : null
          }
        >
          <Button
            data-analytics-id="no-results-pilot-btn"
            type="link"
            disabled={!!pilotSimulation}
            onClick={() => setShowModal(true)}
          >
            {t(
              'publishWithSimulatorPage.pilotSimulation.simulationResults.configurePilot'
            )}
          </Button>
        </Tooltip>
        <Space size="xxl" />
        <Text size="sm">
          {t(
            'publishWithSimulatorPage.pilotSimulation.simulationResults.alternative'
          )}
        </Text>
      </Flex>
      <PilotPreferenceModal
        showModal={showModal}
        onOk={(endDate, emailAddresses) => {
          setShowModal(false)
          createSimulationForTeam(
            query,
            SIMULATION_TYPE.PILOT_POLICY,
            teamId!,
            moment().utc().startOf('day').format(simulationDateFormat),
            endDate,
            emailAddresses
          )
          setQuery({ ...query, simulationType: SIMULATION_TYPE.PILOT_POLICY })
        }}
        onCancel={() => setShowModal(false)}
      />
    </>
  )
}
