import { Radio, Alert } from 'antd'
import { FC } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Space, Text } from '@signifyd/components'
import { DUPLICATE_PREDICTION_LIST_ENTRY_ACTION } from '@signifyd/http'
import styles from './ResolveDuplicateEntriesForm.less'

interface Props {
  value?: DUPLICATE_PREDICTION_LIST_ENTRY_ACTION
  onChange: (value: DUPLICATE_PREDICTION_LIST_ENTRY_ACTION) => void
}

const ResolveDuplicateEntriesForm: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation()

  return (
    <>
      <Alert
        message={
          <Text size="sm">
            {t('listDetailsPage.resolveDuplicateEntriesForm.warningTitle')}
          </Text>
        }
        type="warning"
        showIcon
      />
      <Space size="md" />
      <Text size="sm" weight="semibold" data-test-id="resolveDuplicateFormHint">
        {t('listDetailsPage.resolveDuplicateEntriesForm.radioGroupHelpHint')}
      </Text>
      <Space size="md" />
      <Radio.Group onChange={(e) => onChange(e.target.value)} value={value}>
        <Radio value={DUPLICATE_PREDICTION_LIST_ENTRY_ACTION.DISCARD}>
          <span data-test-id="discard-duplicates">
            {t('listDetailsPage.resolveDuplicateEntriesForm.discardLabel')}
            <Text size="xs" block className={styles.radioDescription}>
              {t(
                'listDetailsPage.resolveDuplicateEntriesForm.discardDescription'
              )}
            </Text>
          </span>
        </Radio>
        <Radio value={DUPLICATE_PREDICTION_LIST_ENTRY_ACTION.OVERWRITE}>
          <span data-test-id="overwrite-duplicates">
            {t('listDetailsPage.resolveDuplicateEntriesForm.overwriteLabel')}
            <Text size="xs" block className={styles.radioDescription}>
              <Trans
                i18nKey="listDetailsPage.resolveDuplicateEntriesForm.overwriteDescription"
                components={{ bold: <Text weight="semibold" /> }}
              />
            </Text>
          </span>
        </Radio>
      </Radio.Group>
    </>
  )
}

export default ResolveDuplicateEntriesForm
