import { ResponsiveSankey } from '@nivo/sankey'
import { Flex } from 'antd'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'stores'
import { useQueryParams } from 'use-query-params'
import { Space, Text, TextThirdGen, useIsThirdGen } from '@signifyd/components'
import { SIMULATION_TYPE } from '@signifyd/http'
import { useTypedRouteParams } from 'pages/PublishWithSimulatorPage/hooks'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import { SANKEY_CONFIG, SANKEY_THEME_CONFIG } from './DistributionSankey.config'
import {
  getDistributionSankeyData,
  getNodeColumn,
  getNodeData,
} from './DistributionSankey.data'
import styles from './DistributionSankey.less'
import { getValueByViewType, mapIdToLabel } from './DistributionSankey.util'

const DistributionSankey: FC = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'publishWithSimulatorPage.distributionSankey',
  })
  const isThirdGen = useIsThirdGen()

  const { checkpoint } = useTypedRouteParams()
  const [{ simulationType, viewType }] = useQueryParams(PublishPageQueryParams)

  const ruleSimulation = useStoreState(
    (state) => state.simulationModel.ruleSimulation
  )

  const sankeyData = useMemo(() => {
    if (!checkpoint) {
      return null
    }

    return getDistributionSankeyData(ruleSimulation, viewType!, checkpoint)
  }, [ruleSimulation, viewType, checkpoint])

  const isPilot = simulationType === SIMULATION_TYPE.PILOT_POLICY

  if (!viewType || !sankeyData) {
    return null
  }

  if (sankeyData.links.length === 0 || sankeyData.nodes.length === 0) {
    return (
      <Flex justify="center">
        <Text size="lg">{t('noDistributionGraphData')}</Text>
      </Flex>
    )
  }

  return (
    <div data-test-id="distribution-sankey">
      <div className={styles.titleContainer}>
        {isThirdGen ? (
          <TextThirdGen type="secondary">
            {t(isPilot ? 'beforePilot' : 'before')}
          </TextThirdGen>
        ) : (
          <Text
            className={styles.title}
            type="secondary"
            size="xs"
            transform="uppercase"
          >
            {t(isPilot ? 'beforePilot' : 'before')}
          </Text>
        )}
        {isThirdGen ? (
          <TextThirdGen type="secondary">
            {t(isPilot ? 'afterPilot' : 'after')}
          </TextThirdGen>
        ) : (
          <Text
            className={styles.title}
            type="secondary"
            size="xs"
            transform="uppercase"
          >
            {t(isPilot ? 'afterPilot' : 'after')}
          </Text>
        )}
      </div>
      <Space size="xs" />
      <div className={styles.chartWrapper}>
        <ResponsiveSankey
          {...SANKEY_CONFIG}
          data={sankeyData}
          label={mapIdToLabel}
          linkTooltip={({ link }) => {
            const value = getValueByViewType(viewType, link.value)

            return (
              <div
                className={styles.tooltip}
              >{`${link.source.label} → ${link.target.label} : ${value}`}</div>
            )
          }}
          nodeTooltip={({ node }) => {
            const column = getNodeColumn(node)
            const value = getValueByViewType(viewType, node.value)

            return (
              <div
                className={styles.tooltip}
              >{`${node.label} (${column}): ${value}`}</div>
            )
          }}
          theme={SANKEY_THEME_CONFIG}
          animate={false}
          colors={(node) => {
            const { id } = node

            const { color } = getNodeData(id)

            return color
          }}
        />
      </div>
    </div>
  )
}

export default DistributionSankey
