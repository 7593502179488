import { FC } from 'react'
import {
  FeatureFlagConfig,
  FeatureFlagController,
  TestFeatureFlag,
  useUserContext,
} from '@signifyd/components'

export const flagConfig: Array<FeatureFlagConfig> = [
  {
    key: 'has-third-gen-ui',
    label: 'Third Gen',
  },
]

interface Props {
  testFeatureFlags?: TestFeatureFlag
}

const DecisionCenterFeatureFlagController: FC<Props> = ({
  children,
  testFeatureFlags,
}) => {
  const { user } = useUserContext()

  return (
    <FeatureFlagController
      user={user}
      featureFlagConfig={flagConfig}
      testFeatureFlags={testFeatureFlags}
    >
      {children}
    </FeatureFlagController>
  )
}

export default DecisionCenterFeatureFlagController
