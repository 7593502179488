import { Flex } from 'antd'
import { FC } from 'react'
import { TitleThirdGen } from '@signifyd/components'
import styles from './HeaderThirdGen.less'

interface Props {
  title: string
}

const HeaderThirdGen: FC<Props> = ({ title, children }) => {
  return (
    <Flex className={styles.header} justify="space-between" align="center" wrap>
      <TitleThirdGen>{title}</TitleThirdGen>

      {children}
    </Flex>
  )
}

export default HeaderThirdGen
