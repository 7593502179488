import { Button, Popconfirm } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Space, Text } from '@signifyd/components'

interface Props {
  disabled?: boolean
  onConfirm: () => void
}

const DiscardRuleCreationPopconfirm: FC<Props> = ({ disabled, onConfirm }) => {
  const { t } = useTranslation()

  return (
    <Popconfirm
      placement="topRight"
      overlayClassName="sig-popconfirm"
      title={
        <>
          <Text block weight="semibold">
            {t('ruleCommon.discardRuleCreationPopconfirm.title')}
          </Text>
          <Space size={12} />
          <Text block type="secondary" size="sm">
            {t('ruleCommon.discardRuleCreationPopconfirm.description')}
          </Text>
        </>
      }
      icon={null}
      onConfirm={onConfirm}
      okText={t('ruleCommon.discardRuleCreationPopconfirm.okText')}
      cancelText={t('ruleCommon.discardRuleCreationPopconfirm.cancelText')}
    >
      <Button danger disabled={disabled}>
        {t('ruleCommon.discardRuleCreationPopconfirm.triggerBtnText')}
      </Button>
    </Popconfirm>
  )
}

export default DiscardRuleCreationPopconfirm
