import { useQuery, UseQueryResult } from '@tanstack/react-query'
import axios from 'axios'
import { CHECKPOINT, listCheckpointsForTeam } from '@signifyd/http'
import useTeamId from '../hooks/useTeamId'

const useSelectedTeamCheckpoints = (): UseQueryResult<Array<CHECKPOINT>> => {
  const teamId = useTeamId()

  return useQuery(
    ['checkpoints', teamId],
    async () => {
      try {
        const {
          data: { checkpoints },
        } = await listCheckpointsForTeam(teamId!)

        return checkpoints
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === 404) {
          return []
        }

        console.error(e)

        return undefined
      }
    },
    {
      enabled: !!teamId,
      retry: false,
    }
  )
}

export default useSelectedTeamCheckpoints
