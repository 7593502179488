import { Loading3QuartersOutlined } from '@ant-design/icons'
import { Divider, Flex } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { spacingMD } from '@signifyd/ant'
import { TextThirdGen } from '@signifyd/components'
import getComparisonOperatorTranslation from 'core/utils/translations/getComparisonOperatorTranslation'
import {
  ALL_COMPARISON_OPERATORS,
  COMPARISON_OPERATOR,
  ConditionTreeLeafNode,
  ConditionTreeNode,
  LOGICAL_OPERATOR,
  isConditionTreeNode,
} from 'stores/conditionTree'
import styles from './RenderRulesThirdGen.less'

const RenderRulesThirdGen: FC<{ conditionTree: ConditionTreeNode | null }> = ({
  conditionTree,
}) => {
  const { t } = useTranslation()

  const getDisplayValues = ({
    ruleFeature,
    value,
    operator,
  }: Partial<ConditionTreeLeafNode>): string | Array<string> => {
    if (!ruleFeature || !value) {
      return ''
    }

    const { categorical } = ruleFeature

    if (categorical && Array.isArray(value)) {
      return value
        .map(
          (item) =>
            categorical.options.find(({ value }) => value === item)?.label
        )
        .filter(Boolean)
        .join(` ${t('ruleCommon.ruleSummaryCard.or')} `)
    }

    if (operator === COMPARISON_OPERATOR.isBetween && Array.isArray(value)) {
      return value.flat(1).join(` ${t('ruleCommon.ruleSummaryCard.and')} `)
    }

    return value
  }

  if (!conditionTree) {
    return <Loading3QuartersOutlined spin size={24} />
  }

  const conditionOperator =
    (conditionTree.operator !== LOGICAL_OPERATOR.not
      ? conditionTree.operator
      : null) ?? LOGICAL_OPERATOR.and

  return (
    <Flex vertical gap={spacingMD}>
      <TextThirdGen size="sm" paragraph data-test-id="ruleConditionMatchText">
        {t(
          `ruleCommon.ruleSummaryCard.conditionDisplayText.${conditionOperator}`
        )}
      </TextThirdGen>
      <Divider />
      {conditionTree.children.map((condition, conditionIndex) => {
        if (!isConditionTreeNode(condition)) {
          return null
        }

        const subConditionOperator =
          (condition.operator !== LOGICAL_OPERATOR.not
            ? condition.operator
            : null) ?? LOGICAL_OPERATOR.and

        return (
          <Flex
            vertical
            gap={spacingMD}
            key={condition.id}
            data-test-id={`ruleSubCondition-${conditionIndex}`}
          >
            <TextThirdGen
              size="sm"
              paragraph
              data-test-id={`ruleSubConditionMatchText-${conditionIndex}`}
            >
              {t(
                `ruleCommon.ruleSummaryCard.conditionDisplayText.${subConditionOperator}`,
                {
                  context: 'subCondition',
                  number: condition.numberOfConditions,
                  max: condition.children.length,
                }
              )}
            </TextThirdGen>
            {condition.children.map((subCondition, subConditionIndex) => {
              if (isConditionTreeNode(subCondition) || !subCondition.operator) {
                return null
              }

              const operator = ALL_COMPARISON_OPERATORS.find(
                ({ name, isNot }) =>
                  name === subCondition.operator && isNot === subCondition.isNot
              )

              const currentOperator = operator ?? {
                name: subCondition.operator,
                isNot: subCondition.isNot,
              }

              return (
                <TextThirdGen
                  key={subCondition.id}
                  size="sm"
                  paragraph
                  className={styles.subConditionText}
                  data-test-id={`ruleSubConditionDescriptionText-${conditionIndex}-${subConditionIndex}`}
                >
                  {`${
                    subCondition.ruleFeature?.description
                  } ${getComparisonOperatorTranslation(
                    currentOperator
                  ).toLowerCase()} ${getDisplayValues(subCondition)}`}
                </TextThirdGen>
              )
            })}
            <Divider />
          </Flex>
        )
      })}
    </Flex>
  )
}

export default RenderRulesThirdGen
