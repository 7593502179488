import { Layout, Flex } from 'antd'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { spacingXL } from '@signifyd/ant'
import TestTeamBanner from 'core/components/TestTeamBanner'
import PageWrapper from 'core/containers/PageWrapper/PageWrapper'
import usePredictionListDetails from 'core/hooks/usePredictionListDetails'
import ListDetailsPageHeaderThirdGen from './containers/ListDetailsPageComponents/ListDetailsPageHeaderThirdGen'
import ListDetailsPageSidePanelThirdGen from './containers/ListDetailsPageSidePanel/ListDetailsPageSidePanelThirdGen'
import ListEntriesTableContainerThirdGen from './containers/ListEntriesTableContainer/ListEntriesTableContainerThirdGen'
import styles from './ListDetailsPageThirdGen.less'

const { Content } = Layout

const ListDetailsPageThirdGen: FC = () => {
  const { listId } = useParams()
  const { data: predictionList } = usePredictionListDetails(Number(listId))

  if (!listId || !predictionList) {
    return null
  }

  return (
    <PageWrapper teamId={predictionList.teamId}>
      <TestTeamBanner teamId={predictionList.teamId} />

      <ListDetailsPageHeaderThirdGen predictionList={predictionList} />

      <Content className={styles.content}>
        <Flex gap={spacingXL}>
          <Flex flex={3}>
            <ListEntriesTableContainerThirdGen
              predictionList={predictionList}
            />
          </Flex>

          <Flex flex={1}>
            <ListDetailsPageSidePanelThirdGen predictionList={predictionList} />
          </Flex>
        </Flex>
      </Content>
    </PageWrapper>
  )
}

export default ListDetailsPageThirdGen
