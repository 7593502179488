import { ReactNode } from 'react'
import { colorHaze } from '@signifyd/colors'
import {
  CheckpointActionTag,
  CheckpointActionTagThirdGen,
  i18nInstance,
  Text,
} from '@signifyd/components'
import {
  CHECKPOINT,
  CHECKPOINT_ACTION,
  RULE_STATUS,
  RuleResponse,
} from '@signifyd/http'
import {
  formatNumber,
  POLICY_REPORTING_TYPE,
} from 'core/components/PolicyReporting/PolicyReporting.utils'
import RuleRank from 'core/components/RuleRank'
import RuleStatusBadge from 'core/components/RuleStatusBadge'
import { checkpointActionIconMap, isDeletedPolicy } from 'core/constants'
import RuleName from 'core/containers/RuleName'
import RuleNameThirdGen from 'core/containers/RuleName/thirdGen/RuleNameThirdGen'
import RuleSchedule from 'core/containers/RuleSchedule'
import RuleDropdown, {
  OnClickRuleMenuItem,
} from 'pages/DashboardPage/containers/RuleDropdown'
import { POLICY_GROUP_TYPE } from 'pages/DashboardPage/DashboardPage.types'
import { ColumnProps } from 'antd/es/table'
import HeaderTooltip from './components/HeaderTooltip'
import { EnhancedPolicy } from '../DashboardContainer/queries/useGetDashboardContainerData'

const renderRuleRank = (rankInActiveRuleSet: number | null): ReactNode => (
  <RuleRank rank={rankInActiveRuleSet} />
)

const renderRuleName = (_text: any, rule: RuleResponse): ReactNode => (
  <RuleName rule={rule} />
)

const renderRuleNameThirdGen = (_text: any, rule: RuleResponse): ReactNode => (
  <RuleNameThirdGen rule={rule} />
)

const renderRuleAction = (
  recommendedAction: CHECKPOINT_ACTION,
  policy: RuleResponse
): ReactNode => (
  <CheckpointActionTag
    action={recommendedAction}
    icon={checkpointActionIconMap[policy.checkpoint]}
    hasTooltip
  />
)

const renderRuleActionThirdGen = (
  recommendedAction: CHECKPOINT_ACTION
): ReactNode => (
  <CheckpointActionTagThirdGen action={recommendedAction} hasTooltip />
)

const renderSchedule = (rule: RuleResponse): ReactNode => (
  <RuleSchedule rule={rule} />
)

const renderStatus = (rule: RuleResponse): ReactNode => {
  if (rule.isDeleted) {
    return <RuleStatusBadge ruleStatus={RULE_STATUS.DELETED} />
  }

  return <RuleStatusBadge ruleStatus={rule.ruleStatus} />
}

const renderPercentage = (percentage?: number): ReactNode =>
  percentage != null ? <Text size="sm">{percentage?.toFixed(2)}%</Text> : null

const renderDollars = (value?: number): ReactNode =>
  value != null ? (
    <Text size="sm">{formatNumber(value, POLICY_REPORTING_TYPE.gmv)}</Text>
  ) : null

interface RenderDropdownIconProps {
  onClickEditRuleDetails: OnClickRuleMenuItem
  onClickManageSchedule: OnClickRuleMenuItem
  onClickDeletePolicyDetails: OnClickRuleMenuItem
}
const renderDropdownIcon =
  ({
    onClickEditRuleDetails,
    onClickManageSchedule,
    onClickDeletePolicyDetails,
  }: RenderDropdownIconProps) =>
  (rule: RuleResponse): ReactNode =>
    isDeletedPolicy(rule) ? null : (
      <RuleDropdown
        rule={rule}
        onClickEditRuleDetails={onClickEditRuleDetails}
        onClickManageSchedule={onClickManageSchedule}
        onClickDeletePolicy={onClickDeletePolicyDetails}
      />
    )

interface GetColumnProps {
  checkpoint: CHECKPOINT
  policyGroupType: POLICY_GROUP_TYPE
  onClickEditRuleDetails: OnClickRuleMenuItem
  onClickManageSchedule: OnClickRuleMenuItem
  onClickDeletePolicyDetails: OnClickRuleMenuItem
  styles: Record<string, string>
  isThirdGen: boolean
}

type ColumnConfig = ColumnProps<EnhancedPolicy>

const sort = (a?: number | null, b?: number | null): number =>
  (a ?? Infinity) - (b ?? Infinity)

const getColumns = ({
  checkpoint,
  policyGroupType,
  onClickEditRuleDetails,
  onClickManageSchedule,
  onClickDeletePolicyDetails,
  styles,
  isThirdGen,
}: GetColumnProps): Array<ColumnConfig> => {
  const columnPrefix = 'ruleDashboardPage.ruleTable.columnTitles'
  const tooltipPrefix = 'ruleDashboardPage.ruleTable.tooltips'

  // PolicyTable only appears on checkout and return checkpoints
  const getTranslatedCheckpointItem = (count: number): string => {
    return checkpoint === CHECKPOINT.RETURN
      ? i18nInstance.t(`${columnPrefix}.translatedCheckpoints.returns`)
      : i18nInstance.t(`${columnPrefix}.translatedCheckpoints.order`, { count })
  }

  const rankCol: ColumnConfig = {
    title: i18nInstance.t(`${columnPrefix}.rank`),
    key: 'rankInActiveRuleSet',
    dataIndex: 'rankInActiveRuleSet',
    render: renderRuleRank,
    width: 68,
    sorter: ({ rankInActiveRuleSet: a }, { rankInActiveRuleSet: b }) =>
      sort(a, b),
    showSorterTooltip: false,
  }

  const nameCol: ColumnConfig = {
    title: i18nInstance.t(`${columnPrefix}.name`),
    key: 'name',
    render: isThirdGen ? renderRuleNameThirdGen : renderRuleName,
    width: 328,
  }

  const actionCol: ColumnConfig = {
    title: i18nInstance.t(`${columnPrefix}.action`),
    key: 'ruleRecommendedAction',
    dataIndex: ['ruleOutcome', 'ruleRecommendedAction'],
    render: isThirdGen ? renderRuleActionThirdGen : renderRuleAction,
    width: 120,
  }

  const hitsByCountCol: ColumnConfig = {
    title: (
      <HeaderTooltip
        title={i18nInstance.t(`${columnPrefix}.hitsByCount`)}
        tooltipText={i18nInstance.t(`${tooltipPrefix}.hitsByCount`, {
          translatedCheckpointItem:
            getTranslatedCheckpointItem(2).toLocaleLowerCase(),
        })}
      />
    ),
    key: 'hitCount',
    dataIndex: ['policyReport', 'hitsByCount'],
    width: 118,
    sorter: ({ policyReport: a }, { policyReport: b }) =>
      sort(a?.hitsByCount, b?.hitsByCount),
    showSorterTooltip: false,
  }

  const totalOrdersPercentageCol: ColumnConfig = {
    title: (
      <HeaderTooltip
        title={i18nInstance.t(`${columnPrefix}.totalPercentage`, {
          translatedCheckpointItem: getTranslatedCheckpointItem(1),
        })}
        tooltipText={i18nInstance.t(`${tooltipPrefix}.totalPercentage`, {
          translatedCheckpointItem:
            getTranslatedCheckpointItem(1).toLocaleLowerCase(),
        })}
      />
    ),
    key: 'totalOrdersPercentage',
    width: 180,
    dataIndex: ['policyReport', 'percentageOfTotalCount'],
    render: renderPercentage,
    sorter: ({ policyReport: a }, { policyReport: b }) =>
      sort(a?.percentageOfTotalCount, b?.percentageOfTotalCount),
    showSorterTooltip: false,
  }

  const affectedOrdersPercentageCol: ColumnConfig = {
    title: (
      <HeaderTooltip
        title={i18nInstance.t(`${columnPrefix}.affectedPercentage`, {
          translatedCheckpointItem: getTranslatedCheckpointItem(2),
        })}
        tooltipText={i18nInstance.t(`${tooltipPrefix}.affectedPercentage`, {
          translatedCheckpointItem:
            getTranslatedCheckpointItem(2).toLocaleLowerCase(),
        })}
      />
    ),
    key: 'affectedOrdersPercentage',
    width: 241,
    dataIndex: ['policyReport', 'percentageOfTotalAffectedByPolicies'],
    render: renderPercentage,
    sorter: ({ policyReport: a }, { policyReport: b }) =>
      sort(
        a?.percentageOfTotalAffectedByPolicies,
        b?.percentageOfTotalAffectedByPolicies
      ),
    showSorterTooltip: false,
  }

  const hitsByGMVCol: ColumnConfig = {
    title: (
      <HeaderTooltip
        title={i18nInstance.t(`${columnPrefix}.hitsByGMV`)}
        tooltipText={i18nInstance.t(`${tooltipPrefix}.hitsByGMV`, {
          translatedCheckpointItem:
            getTranslatedCheckpointItem(1).toLocaleLowerCase(),
        })}
      />
    ),
    key: 'hitsByGMV',
    width: 118,
    dataIndex: ['policyReport', 'hitsByGmv'],
    render: renderDollars,
    sorter: ({ policyReport: a }, { policyReport: b }) =>
      sort(a?.hitsByGmv, b?.hitsByGmv),
    showSorterTooltip: false,
  }

  const totalOrderGMVCol: ColumnConfig = {
    title: (
      <HeaderTooltip
        title={i18nInstance.t(`${columnPrefix}.totalGMV`, {
          translatedCheckpointItem: getTranslatedCheckpointItem(1),
        })}
        tooltipText={i18nInstance.t(`${tooltipPrefix}.totalGMV`, {
          translatedCheckpointItem:
            getTranslatedCheckpointItem(2).toLocaleLowerCase(),
        })}
      />
    ),
    key: 'totalOrderGMV',
    width: 154,
    dataIndex: ['policyReport', 'percentageOfTotalGmv'],
    render: renderPercentage,
    sorter: ({ policyReport: a }, { policyReport: b }) =>
      sort(a?.percentageOfTotalGmv, b?.percentageOfTotalGmv),
    showSorterTooltip: false,
  }

  const affectedGMVPercentageCol: ColumnConfig = {
    title: (
      <HeaderTooltip
        title={i18nInstance.t(`${columnPrefix}.affectedGMVPercentage`, {
          translatedCheckpointItem: getTranslatedCheckpointItem(2),
        })}
        tooltipText={i18nInstance.t(`${tooltipPrefix}.affectedGMVPercentage`)}
      />
    ),
    key: 'affectedGMVPercentage',
    width: 212,
    dataIndex: ['policyReport', 'percentageOfTotalGmvAffectedByPolicies'],
    render: renderPercentage,
    sorter: ({ policyReport: a }, { policyReport: b }) =>
      sort(
        a?.percentageOfTotalAffectedByPolicies,
        b?.percentageOfTotalAffectedByPolicies
      ),
    showSorterTooltip: false,
  }

  const scheduleCol: ColumnConfig = {
    title: i18nInstance.t(`${columnPrefix}.schedule`),
    key: 'schedule',
    render: renderSchedule,
    width: 180,
  }

  const totalHitsCol: ColumnConfig = {
    title: (
      <HeaderTooltip
        title={i18nInstance.t(`${columnPrefix}.totalHits`)}
        tooltipText={i18nInstance.t(`${tooltipPrefix}.totalHits`)}
      />
    ),
    key: 'totalHits',
    width: 184,
    dataIndex: 'hitCount',
    sorter: (a, b) => a.hitCount - b.hitCount,
    showSorterTooltip: false,
    className: styles.highlight,
    onHeaderCell: () => ({
      style: {
        backgroundColor: colorHaze,
      },
    }),
  }

  const statusCol: ColumnConfig = {
    title: i18nInstance.t(`${columnPrefix}.status`),
    key: 'status',
    render: renderStatus,
    width: 120,
  }

  const ruleSettingsCol: ColumnConfig = {
    key: 'dropdownIcon',
    render: renderDropdownIcon({
      onClickEditRuleDetails,
      onClickDeletePolicyDetails,
      onClickManageSchedule,
    }),
    fixed: 'right',
    width: 60,
  }

  const columnArray = [
    rankCol,
    nameCol,
    actionCol,
    hitsByCountCol,
    totalOrdersPercentageCol,
    affectedOrdersPercentageCol,
    hitsByGMVCol,
    totalOrderGMVCol,
    affectedGMVPercentageCol,
    scheduleCol,
    totalHitsCol,
    statusCol,
    ruleSettingsCol,
  ]

  if (policyGroupType === POLICY_GROUP_TYPE.others) {
    columnArray.shift()
  }

  return columnArray
}

export default getColumns
