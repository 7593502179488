import { Layout, Tabs, Flex } from 'antd'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { colorBondi, colorMarengo } from '@signifyd/colors'
import { PageSpinner, Space } from '@signifyd/components'
import { CHECKPOINT } from '@signifyd/http'
import PublishedRuleSetInfo from 'core/containers/PublishedRuleSetInfo'
import useGetPilotSimulations from 'core/hooks/useGetPilotSimulations/useGetPilotSimulations'
import { usePublishedRuleset } from 'core/hooks/usePublishedRuleSet/usePublishedRuleSet'
import useTimeframe from 'core/hooks/useTimeframe'
import useUserTimeZone from 'core/hooks/useUserTimeZone'
import { PilotDashboardBanner } from 'pages/DashboardPage/components/PilotDashboardBanner/PilotDashboardBanner'
import PolicyReportingChartThirdGen from 'pages/DashboardPage/components/PolicyReporting/PolicyReportingChartThirdGen'
import {
  RuleCreationSuccessModal,
  RuleEditSuccessModal,
} from 'pages/DashboardPage/containers/RuleSuccessModal'
import PolicyTable from 'pages/DashboardPage/containers/RuleTable'
import { POLICY_GROUP_TYPE } from 'pages/DashboardPage/DashboardPage.types'
import { getCheckpointTabsData } from 'pages/DashboardPage/DashboardPage.utils'
import { useDashboardPageFilters } from 'pages/DashboardPage/hooks/useDashboardPageFilters'
import styles from './DashboardContainer.less'
import useGetDashboardContainerData from './queries/useGetDashboardContainerData'

const { Content } = Layout

const { TabPane } = Tabs
const TAB_BAR_STYLE = {
  borderBottom: 'none',
  marginBottom: 0,
}

const TAB_BAR_GUTTER_SPACING = 64

interface Props {
  selectedTeamCheckpoints: Array<CHECKPOINT>
  setCreateRuleModalVisible: (visible: boolean) => void
}

const DashboardContainerThirdGen: FC<Props> = ({
  setCreateRuleModalVisible,
  selectedTeamCheckpoints,
}) => {
  const { t } = useTranslation()

  const [{ showNewRules, teamId, checkpoint, editedPolicyId }, setFilters] =
    useDashboardPageFilters()
  const { timeframe, updateTimeframe } = useTimeframe()

  const [policyGroupType, setPolicyGroupType] = useState(
    POLICY_GROUP_TYPE.published
  )

  const userTimeZone = useUserTimeZone()
  const { data: pilotSimulation } = useGetPilotSimulations(teamId, checkpoint)

  const { enhancedPolicies, aggregatedPolicyReport } =
    useGetDashboardContainerData(teamId, checkpoint, timeframe)

  const { policies, isFetching: policiesAreLoading, isError } = enhancedPolicies
  const { data: aggregatedReport } = aggregatedPolicyReport

  const { publishedPolicies, otherPolicies } = policies

  const otherTabLabel = t('ruleDashboardPage.othersTab', {
    count: otherPolicies.length,
  })

  const publishedTabLabel = t('ruleDashboardPage.publishedTab', {
    count: publishedPolicies.length,
  })

  const policyTypeTabs = [
    {
      testId: 'published-tab',
      key: POLICY_GROUP_TYPE.published,
      label: publishedTabLabel,
    },
    {
      testId: 'others-tab',
      key: POLICY_GROUP_TYPE.others,
      label: otherTabLabel,
    },
  ]

  const checkpointTabsData = getCheckpointTabsData()
  const filteredCheckpointsTabData = checkpointTabsData.filter(({ key }) =>
    selectedTeamCheckpoints?.includes(key)
  )

  const { data: ruleSet } = usePublishedRuleset({
    teamId,
    checkpoint,
  })

  if (!teamId || !checkpoint || !selectedTeamCheckpoints) {
    return <PageSpinner />
  }

  return (
    <div className={styles.content}>
      <div className={styles.section}>
        <div className={styles.tabs}>
          <Tabs
            activeKey={checkpoint}
            onTabClick={(activeKey) => {
              const checkpoint = activeKey as CHECKPOINT

              setFilters({ checkpoint })
            }}
            tabBarStyle={TAB_BAR_STYLE}
            tabBarGutter={TAB_BAR_GUTTER_SPACING}
          >
            {filteredCheckpointsTabData.map(({ Icon, key, label }) => (
              <TabPane
                key={key}
                tab={
                  <div
                    className={styles.checkpointTab}
                    data-test-id={`${key.toLowerCase()}Tab`}
                  >
                    <Icon
                      className={styles.icon}
                      fill={key === checkpoint ? colorBondi : colorMarengo}
                    />
                    {label}
                  </div>
                }
              />
            ))}
          </Tabs>
        </div>
      </div>

      <Flex justify="center">
        <PilotDashboardBanner
          teamId={teamId}
          pilotSimulation={pilotSimulation}
          checkpoint={checkpoint}
          includePilotLink
        />
      </Flex>

      <div className={styles.section}>
        <PolicyReportingChartThirdGen
          checkpoint={checkpoint}
          data={aggregatedReport}
          isLoading={aggregatedPolicyReport.isFetching}
          policies={policies}
          policyGroupType={policyGroupType}
          setTimeframe={updateTimeframe}
          timeframe={timeframe}
        />
      </div>

      <div className={styles.publishedTabSection}>
        <Tabs
          items={policyTypeTabs}
          onTabClick={(activeKey) =>
            setPolicyGroupType(activeKey as POLICY_GROUP_TYPE)
          }
          tabBarExtraContent={
            <PublishedRuleSetInfo
              ruleSet={ruleSet ?? null}
              userTimeZone={userTimeZone}
            />
          }
        />
      </div>

      <Content className={styles.policySection}>
        <PolicyTable
          currentCheckpoint={checkpoint}
          policyGroupType={policyGroupType}
          policies={policies}
          isLoading={policiesAreLoading}
          isError={isError}
          timeframe={timeframe}
        />
        <Space size="lg" />
      </Content>

      {showNewRules === 'true' && (
        <RuleCreationSuccessModal
          checkpoint={checkpoint}
          teamId={teamId.toString()}
          onClickCreateRule={() => {
            setFilters({ showNewRules: undefined })
            setCreateRuleModalVisible(true)
          }}
          onClickLater={() => setFilters({ showNewRules: undefined })}
        />
      )}

      {editedPolicyId && (
        <RuleEditSuccessModal
          checkpoint={checkpoint}
          teamId={teamId.toString()}
          onClickLater={() => setFilters({ editedPolicyId: undefined })}
          editedPolicyId={editedPolicyId}
          onClickCreateRule={() => {
            setFilters({ editedPolicyId: undefined })
            setCreateRuleModalVisible(true)
          }}
        />
      )}
    </div>
  )
}

export default DashboardContainerThirdGen
