import { DefinedUseQueryResult } from '@tanstack/react-query'
import {
  AggregatePolicyReportResponse,
  CHECKPOINT,
  PolicyReportResponse,
  RuleResponse,
} from '@signifyd/http'
import { TIMEFRAME } from 'core/components/PolicyReporting/PolicyReporting.utils'
import useGetAggregatePolicyReport from 'pages/DashboardPage/queries/useGetAggregatePolicyReport'
import useGetDashboardPolicies from 'pages/DashboardPage/queries/useGetDashboardPolicies/useGetDashboardPolicies'
import { mapToDashboardPoliciesWithReports } from './utils/mapToPolicyWithReport'

export interface EnhancedPolicy extends RuleResponse {
  policyReport?: PolicyReportResponse
}

export interface EnhancedPolicies {
  publishedPolicies: Array<EnhancedPolicy>
  otherPolicies: Array<EnhancedPolicy>
}

interface DashboardContainerData {
  aggregatedPolicyReport: DefinedUseQueryResult<AggregatePolicyReportResponse>
  enhancedPolicies: {
    policies: EnhancedPolicies
    isFetching: boolean
    isError: boolean
  }
}

const useGetDashboardContainerData = (
  teamId?: number | null,
  checkpoint?: CHECKPOINT | null,
  timeframe?: TIMEFRAME
): DashboardContainerData => {
  const aggregatedPolicyReportQuery = useGetAggregatePolicyReport(
    teamId,
    checkpoint,
    timeframe
  )
  const {
    data: dashboardPolicies,
    isFetching,
    isError,
  } = useGetDashboardPolicies()

  const { data: aggregatedPolicyReport } = aggregatedPolicyReportQuery

  const { otherPolicies, publishedPolicies } =
    mapToDashboardPoliciesWithReports(
      dashboardPolicies,
      aggregatedPolicyReport.policies
    )

  return {
    aggregatedPolicyReport: aggregatedPolicyReportQuery,
    enhancedPolicies: {
      isFetching,
      isError,
      policies: {
        otherPolicies,
        publishedPolicies,
      },
    },
  }
}

export default useGetDashboardContainerData
