import { FC, ReactNode, ComponentType } from 'react'
import {
  SearchResultsIcon,
  IconProps,
  StretchToPageBottom,
} from '@signifyd/components'
import NoResults from 'core/components/NoResults'
import { PAGE_PADDING } from 'core/constants'
import styles from './StretchedNoResults.less'

interface Props {
  icon?: ComponentType<IconProps>
  title: ReactNode
  description: ReactNode
}

const StretchedNoResults: FC<Props> = ({
  icon = SearchResultsIcon,
  title,
  description,
}) => (
  <StretchToPageBottom
    className={styles.wrapper}
    centerChildren
    marginBottom={PAGE_PADDING}
  >
    <NoResults icon={icon} title={title} description={description} />
  </StretchToPageBottom>
)

export default StretchedNoResults
