import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { message } from 'antd'
import { i18nInstance } from '@signifyd/components'
import { RuleResponse, RuleSet, updateRuleSet } from '@signifyd/http'
import { getHTTPErrorStatus } from 'core/http'
import { GET_RULE_SET } from 'core/queries/useGetRuleSet/useGetRuleSet'
import { LIST_ALL_RULES } from 'pages/PublishWithSimulatorPage/hooks/useGetAllRules/useGetAllRules'
import { maxRuleLimitExceededMessage } from 'locales/utils/maxRuleExceededMessage'
import { getUpdateRuleSetPayload } from './utils'

export type UpdateRuleSetType = UseMutationResult<
  RuleSet,
  unknown,
  {
    ruleSet: RuleSet
    publishedRules: Array<RuleResponse>
    otherRules: Array<RuleResponse>
  }
>

const useUpdateRuleSet = (): UpdateRuleSetType => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ ruleSet, publishedRules, otherRules }) => {
      const updateRuleSetPayload = getUpdateRuleSetPayload(publishedRules)
      queryClient.setQueriesData(
        [LIST_ALL_RULES],
        [...publishedRules, ...otherRules]
      )

      const { data } = await updateRuleSet(
        ruleSet.ruleSetId,
        updateRuleSetPayload
      )

      return data
    },
    onSuccess: async (ruleSet) => {
      await queryClient.invalidateQueries([GET_RULE_SET, ruleSet.ruleSetId])

      const { excessRuleCount, maxRuleLimit } = ruleSet

      if (excessRuleCount) {
        message.error({
          content: maxRuleLimitExceededMessage(maxRuleLimit, excessRuleCount),
          duration: 0,
        })
      }
    },
    onError: (error) => {
      const status = getHTTPErrorStatus(
        error,
        i18nInstance.t('publishWithSimulatorPage.fallbackErrorMessage.fallback')
      )

      message.error({ content: status.error })
    },
  })
}

export default useUpdateRuleSet
