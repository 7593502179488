import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Layout, T1 } from '@signifyd/components'
import AppNavBar from 'core/containers/AppNavBar'
import styles from './VariableGroupPage.less'
import VariableGroupTable from './VariableGroupTable/VariableGroupTable'

const { Content, NavBar } = Layout

const VariableGroupPage: FC = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <NavBar>
        <AppNavBar />
      </NavBar>
      <Content>
        <div className={styles.contentContainer}>
          <T1>{t('variableGroupPage.title')}</T1>
          <Link to="/admin/variables">
            {t('variableGroupPage.variablePageLink')}
          </Link>
        </div>
        <VariableGroupTable />
      </Content>
    </Layout>
  )
}

export default VariableGroupPage
