import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { message } from 'antd'
import { AxiosError, AxiosResponse } from 'axios'
import { i18nInstance } from '@signifyd/components'
import {
  PREDICTION_LIST_TYPE,
  replacePredictionListEntriesWithForm,
} from '@signifyd/http'
import { getHTTPErrorStatus } from 'core/http'
import {
  CSV_REPLACE_PREDICTION_LIST_ENTRY_QUERY_KEY,
  PREDICTION_LIST_ENTRIES_QUERY_KEY,
} from 'core/queries/queryKeys'
import { RcFile } from 'antd/lib/upload'

export interface ReplacePredictionListEntriesWithCSVParams {
  predictionListId: number
  file: RcFile
  type: PREDICTION_LIST_TYPE
  onSuccessCallback?: () => void
}

export const useReplacePredictionListEntriesWithCSV = (): UseMutationResult<
  AxiosResponse<Array<number>>,
  AxiosError,
  ReplacePredictionListEntriesWithCSVParams
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [CSV_REPLACE_PREDICTION_LIST_ENTRY_QUERY_KEY],
    mutationFn: async ({ predictionListId, file, type }) => {
      const form = new FormData()

      form.append('file', file)
      form.append('type', type)

      return replacePredictionListEntriesWithForm(predictionListId, form)
    },
    onSuccess: (_, { predictionListId, onSuccessCallback }) => {
      queryClient.invalidateQueries([
        PREDICTION_LIST_ENTRIES_QUERY_KEY,
        predictionListId.toString(),
      ])

      onSuccessCallback?.()
    },
    onError: (error) => {
      const status = getHTTPErrorStatus(
        error,
        i18nInstance.t('listCommon.fallbackErrorMessage.createListEntries')
      )

      message.error({ content: status.error })
    },
  })
}

export default useReplacePredictionListEntriesWithCSV
