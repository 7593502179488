import { EditOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@signifyd/components'
import { PREDICTION_LIST_TYPE } from '@signifyd/http'
import useUserPermissions from 'core/hooks/useUserPermissions'
import styles from './ListExpiration.less'
import { ExpirationDisplayDetails } from './listExpirationUtils'

interface Props {
  expirations: Array<ExpirationDisplayDetails>
  predictionListType: PREDICTION_LIST_TYPE
  editExpiration: (expiration: ExpirationDisplayDetails) => void
}

const DisplayExpirations: FC<Props> = ({
  expirations,
  predictionListType,
  editExpiration,
}) => {
  const { t } = useTranslation()
  const { userCanEdit } = useUserPermissions()

  return (
    <div data-test-id="listHasExpirations">
      <div className={styles.addedExpirationsTooltip}>
        {t('listDetailsPage.sidePanel.addedExpiration')}
        <Tooltip
          placement="bottom"
          title={
            <Text className={styles.expirationTooltip}>
              {t('listDetailsPage.sidePanel.expirationFormTooltip')}
            </Text>
          }
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
      {expirations.map(({ type, value }) => (
        <div key={type} className={styles.expirations}>
          {t('listDetailsPage.sidePanel.listWillExpireAfter', {
            listName: t(`listCommon.listTypeTag.${predictionListType}`),
            count: value,
            type: t(
              `listDetailsPage.sidePanel.expirationTypesLowerCase.${type}`,
              {
                count: value,
              }
            ),
          })}

          {userCanEdit && (
            <Button
              type="link"
              className={styles.editListExpirationButton}
              onClick={() => editExpiration({ value, type })}
              data-test-id="editListExpirationButton"
            >
              <EditOutlined />
            </Button>
          )}
        </div>
      ))}
    </div>
  )
}

export default DisplayExpirations
