import { Button, message } from 'antd'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'stores'
import { useQueryParams } from 'use-query-params'
import { SIMULATION_STATUS } from '@signifyd/http'
import PublishedRuleSetInfo from 'core/containers/PublishedRuleSetInfo'
import { usePublishedRuleset } from 'core/hooks/usePublishedRuleSet/usePublishedRuleSet'
import useUserTimeZone from 'core/hooks/useUserTimeZone'
import useGetRuleSet from 'core/queries/useGetRuleSet/useGetRuleSet'
import usePublishRuleSet from 'core/queries/usePublishRuleSet/usePublishRuleSet'
import { useTypedRouteParams } from 'pages/PublishWithSimulatorPage/hooks'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import CancelPublishModal from '../CancelPublishModal'
import styles from './HeaderExtra.less'
import PublishButton from './PublishButton'

const RULE_SET_WRAPPER_STYLE = {
  color: '#fff',
}

const HeaderExtra: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { teamId, checkpoint } = useTypedRouteParams()
  const [{ editedPolicyId, ruleSetId }] = useQueryParams(PublishPageQueryParams)

  const [cancelPublishModalVisible, setCancelPublishModalVisible] =
    useState(false)
  const userTimeZone = useUserTimeZone()

  const { data: ruleSet } = useGetRuleSet(ruleSetId)

  const { data: publishedRuleSet } = usePublishedRuleset({
    teamId,
    checkpoint,
  })
  const publishRuleSet = usePublishRuleSet(editedPolicyId)

  const simulation = useStoreState(
    (state) => state.simulationModel.ruleSimulation
  )
  const { cancelRuleSimulation } = useStoreActions(
    (actions) => actions.simulationModel
  )

  const navigateToDashboard = (): void => {
    navigate(`/policies/dashboard?teamId=${teamId}&checkpoint=${checkpoint}`)
  }

  const handleConfirmCancelSimulation = async (): Promise<void> => {
    if (simulation?.status === SIMULATION_STATUS.RUNNING) {
      await cancelRuleSimulation(simulation.simulationId)
    }

    message.destroy()
    navigateToDashboard()
  }

  const onClickPublishButton = (): void => {
    if (!ruleSet?.ruleSetId || !teamId) {
      return
    }

    publishRuleSet.mutate(
      { ruleSet },
      {
        onSuccess: () => {
          navigateToDashboard()

          const translationSuffix = editedPolicyId
            ? 'updatePolicyAndPublishRuleSet'
            : 'publishRuleSet'

          message.success({
            content: t(`ruleCommon.successMessage.${translationSuffix}`),
            duration: 10,
          })
        },
      }
    )
  }

  if (!teamId) {
    return null
  }

  return (
    <div className={styles.extra}>
      {publishedRuleSet && (
        <PublishedRuleSetInfo
          ruleSet={publishedRuleSet}
          userTimeZone={userTimeZone}
          style={RULE_SET_WRAPPER_STYLE}
        />
      )}

      <Button
        ghost
        className={styles.btn}
        onClick={() => {
          setCancelPublishModalVisible(true)
        }}
        data-analytics-id="cancel-publish-ruleset-button"
      >
        {t('publishWithSimulatorPage.header.cancelBtn')}
      </Button>

      <CancelPublishModal
        visible={cancelPublishModalVisible}
        isEditing={!!editedPolicyId}
        onOk={handleConfirmCancelSimulation}
        onCancel={() => setCancelPublishModalVisible(false)}
      />

      <PublishButton
        onClick={onClickPublishButton}
        publishRuleSetLoading={publishRuleSet.isLoading}
        publishRuleSetError={publishRuleSet.error}
        ruleSet={ruleSet}
      />
    </div>
  )
}

export default HeaderExtra
