import { Button, Divider, Flex } from 'antd'
import moment from 'moment-timezone'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'stores'
import { DecodedValueMap, useQueryParams } from 'use-query-params'
import {
  SimulationIcon,
  T4,
  Space,
  StretchToPageBottom,
  useIsThirdGen,
  TitleThirdGen,
} from '@signifyd/components'
import { RuleSimulation, SIMULATION_TYPE } from '@signifyd/http'
import SimulationDatePicker from 'pages/PublishWithSimulatorPage/components/SimulationDatePicker'
import { useTypedRouteParams } from 'pages/PublishWithSimulatorPage/hooks'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import { simulationDateFormat } from 'stores/simulation'
import { ConfigurePilot } from './ConfigurePilot'
import styles from './CreateSimulationContainer.less'
import { SimulationDatePickerProps } from '../SimulationResultHeader/SimulationResultHeader'

interface Props extends SimulationDatePickerProps {
  pilotSimulation?: RuleSimulation
  createSimulationForTeam: (
    filters: DecodedValueMap<typeof PublishPageQueryParams>,
    simulationType: SIMULATION_TYPE | undefined,
    teamId: number,
    startDate?: string,
    endDate?: string,
    emailAddresses?: Array<string>
  ) => void
}

const CreateSimulationContainer: FC<Props> = ({
  pilotSimulation,
  dateRangeValue,
  setDateRange,
  userTimeZone,
  createSimulationForTeam,
}) => {
  const { t } = useTranslation()
  const isThirdGen = useIsThirdGen()
  const { teamId, checkpoint } = useTypedRouteParams()
  const [filters] = useQueryParams(PublishPageQueryParams)
  const { ruleSetId } = filters

  const isLoading = useStoreState(
    (state) => state.simulationModel.isLoading.createRuleSimulation
  )

  const [simulationStartDate, simulationEndDate] = dateRangeValue

  if (!teamId || !checkpoint) {
    return null
  }

  const isRunSimulationButtonDisabled =
    !(simulationStartDate && simulationEndDate) ||
    isLoading ||
    !ruleSetId ||
    !teamId

  return (
    <StretchToPageBottom centerChildren className={styles.container}>
      <Flex gap={48}>
        <div data-test-id="createSimulationContainer">
          <SimulationIcon className={styles.icon} />
          <Space size="lg" />
          {isThirdGen ? (
            <>
              <TitleThirdGen level={3}>
                {t('publishWithSimulatorPage.createSimulation.title')}
              </TitleThirdGen>
              <Space size="sm" />
            </>
          ) : (
            <T4>{t('publishWithSimulatorPage.createSimulation.title')}</T4>
          )}
          <div className={styles.description}>
            {t('publishWithSimulatorPage.createSimulation.description')}
          </div>
          <Space size="lg" />
          <SimulationDatePicker
            className={styles.rangePicker}
            value={dateRangeValue}
            onChange={(dateRange) => setDateRange(dateRange)}
            userTimeZone={userTimeZone}
          />
          <Space size="lg" />
          <Button
            className={styles.button}
            type="primary"
            loading={isLoading}
            disabled={isRunSimulationButtonDisabled}
            onClick={() => {
              if (!simulationStartDate || !simulationEndDate) {
                return
              }

              createSimulationForTeam(
                filters,
                SIMULATION_TYPE.CUSTOMER_POLICY,
                teamId!,
                simulationStartDate
                  .utc()
                  .startOf('day')
                  .format(simulationDateFormat),
                simulationEndDate
                  .utc()
                  .endOf('day')
                  .format(simulationDateFormat)
              )
            }}
            data-test-id="runSimulationButton"
            data-analytics-id="run-simulation-button"
          >
            {t('publishWithSimulatorPage.createSimulation.runSimulationBtn')}
          </Button>
        </div>
        <Divider
          className={styles.divider}
          orientation="center"
          type="vertical"
        />
        <ConfigurePilot
          pilotSimulation={pilotSimulation}
          createPilot={(endDate, emailAddresses) => {
            if (!endDate) {
              return
            }

            createSimulationForTeam(
              filters,
              SIMULATION_TYPE.PILOT_POLICY,
              teamId!,
              moment().utc().startOf('day').format(simulationDateFormat),
              endDate,
              emailAddresses
            )
          }}
        />
      </Flex>
    </StretchToPageBottom>
  )
}

export default CreateSimulationContainer
