import moment from 'moment-timezone'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'stores'
import { DatePicker, RangePickerValue } from '@signifyd/components'
import { SIMULATION_TYPE } from '@signifyd/http'
import { rangePickerDateFormat } from 'stores/simulation/simulation.constants'
import { isDateDisabled } from './SimulationDatePicker.util'

interface Props {
  value: RangePickerValue | null
  className?: string
  onChange: (dateRange: RangePickerValue | null) => void
  userTimeZone: string
}

const { RangePicker } = DatePicker

const SimulationDatePicker: FC<Props> = ({
  className,
  value,
  onChange,
  userTimeZone,
}) => {
  const { t } = useTranslation()
  const { ruleSimulation } = useStoreState((store) => store.simulationModel)

  const { simulationType, startDate, endDate } = ruleSimulation ?? {}

  const isPilot = simulationType === SIMULATION_TYPE.PILOT_POLICY

  const now = moment().tz(userTimeZone)
  const oneMonthAgo = moment().subtract(1, 'months')
  const endOfToday = moment().endOf('day')
  const sevenDaysAgo = moment().subtract(7, 'days')

  return (
    <RangePicker
      disabled={isPilot}
      className={className}
      disabledDate={(date, { from }) =>
        isDateDisabled(date, value, {
          endOfToday,
          oneMonthAgo,
          from,
        })
      }
      onCalendarChange={onChange}
      presets={[
        {
          label: t(
            'publishWithSimulatorPage.createSimulation.presetRangeToday'
          ),
          value: [now, now],
        },
        {
          label: t(
            'publishWithSimulatorPage.createSimulation.presetRangeLast7Days'
          ),
          value: [sevenDaysAgo, now],
        },
      ]}
      value={isPilot ? [moment(startDate), moment(endDate)] : value}
      format={rangePickerDateFormat}
      onChange={onChange}
      data-analytics-id="simulation-date-range-picker"
    />
  )
}

export default SimulationDatePicker
