import { Alert, message, Modal, Select, Tooltip } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { InputLabel, useUserContext, Space } from '@signifyd/components'
import {
  createRule,
  RuleResponse,
  RULE_STAGE,
  updateRule,
} from '@signifyd/http'
import getPolicyDuplicationPayload from 'core/utils/policyDuplication/getPolicyDuplicationPayload'
import { isPredicateUsingPredictionListFeatures } from 'stores/conditionTree'
import styles from './DuplicatePolicyModal.less'

export interface DuplicatePolicyModalProps {
  isOpen: boolean
  allowedTeamIds: Array<number>
  policy: RuleResponse
  onDuplicateAndRedirect: (teamId: number) => void
  onClose: () => void
}

const DuplicatePolicyModal: FC<DuplicatePolicyModalProps> = ({
  isOpen,
  allowedTeamIds,
  policy,
  onDuplicateAndRedirect,
  onClose,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'common.duplicatePolicyModal',
  })

  const navigate = useNavigate()

  const { teams } = useUserContext()

  const [hasFailed, setHasFailed] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState<Array<number>>([])

  const hasList =
    !!policy?.predicate &&
    isPredicateUsingPredictionListFeatures(policy.predicate)

  useEffect(() => {
    if (!hasList || !policy?.teamId) {
      return
    }

    setSelectedOptions([policy.teamId])
  }, [hasList, policy])

  const options = teams
    ?.filter(({ active }) => !!active)
    .map(({ teamId, teamName }) => {
      const isCurrent = teamId === policy?.teamId
      const isDisabled = !allowedTeamIds.includes(teamId)
      const testIdSuffix = isDisabled ? '-disabled' : ''
      const analyticsIdSuffix = isCurrent ? '-active' : ''

      return {
        label: isCurrent
          ? `${teamName} ${t('select.currentSuffix')}`
          : teamName,
        value: teamId,
        disabled: isDisabled,
        'data-test-id': `duplicatePolicyModalOption-${teamId}${testIdSuffix}`,
        'data-analytics-id': `duplicate-policy-modal-option${analyticsIdSuffix}`,
      }
    })

  const handleClose = (): void => {
    setHasFailed(false)
    setSelectedOptions([])
    onClose()
  }

  const handleDuplicate = async (): Promise<void> => {
    if (!policy) {
      return
    }

    if (hasList || selectedOptions.length === 1) {
      const [teamId] = selectedOptions

      onDuplicateAndRedirect(teamId)
      handleClose()

      return
    }

    // FET-2705: Used a create -> update here as passing some fields to create resulted in BE errors
    // This at least matches the existing flow more closely but feels strange.
    const duplicated = await Promise.allSettled(
      selectedOptions.map(async (teamId) => {
        const {
          data: { ruleId },
        } = await createRule({
          ...getPolicyDuplicationPayload(policy),
          teamId,
        })

        return updateRule(ruleId, {
          ruleStage: RULE_STAGE.NEW,
          nonMatchingPolicyMatchIds: policy.nonMatchingPolicyMatchIds,
          matchingPolicyMatchIds: policy.matchingPolicyMatchIds,
        })
      })
    )

    const rejectedIndices = selectedOptions.filter(
      (_, i) => duplicated[i].status === 'rejected'
    )

    const needsRetry = rejectedIndices.length > 0

    setHasFailed(needsRetry)

    if (needsRetry) {
      setSelectedOptions(rejectedIndices)

      return
    }

    navigate('/policies/dashboard')
    handleClose()
    message.success(t('successMessage'))
  }

  const submitButtonAnalyticsIdSuffix = hasList ? '-with-list' : ''

  return (
    <Modal
      open={isOpen}
      title={t('title')}
      okText={t('okText')}
      okButtonProps={{
        type: 'link',
        disabled: selectedOptions.length === 0,
        'data-test-id': 'duplicatePolicyModalSubmitButton',
        'data-analytics-id': `duplicate-policy-modal-submit${submitButtonAnalyticsIdSuffix}`,
      }}
      cancelButtonProps={{
        type: 'link',
        'data-test-id': 'duplicatePolicyModalCancelButton',
        'data-analytics-id': 'duplicate-policy-modal-cancel',
      }}
      closable={false}
      onOk={handleDuplicate}
      onCancel={handleClose}
      wrapProps={{ onClick: (event: Event) => event.stopPropagation() }}
    >
      {hasList && (
        <>
          <Alert
            type="warning"
            message={t('listAlert')}
            data-test-id="duplicatePolicyModalListAlert"
          />
          <Space size="xs" />
        </>
      )}
      {hasFailed && (
        <>
          <Alert
            type="error"
            message={t('failureAlert')}
            data-test-id="duplicatePolicyModalFailureAlert"
          />
          <Space size="xs" />
        </>
      )}
      <InputLabel type="secondary">{t('select.label')}</InputLabel>
      <Tooltip title={hasFailed && t('select.tryAgainTooltip')} placement="top">
        <Select
          mode="multiple"
          className={styles.select}
          placeholder={t('select.placeholder')}
          options={options}
          value={selectedOptions}
          onChange={(values) => setSelectedOptions(values.map(Number))}
          optionFilterProp="label"
          disabled={hasFailed || hasList}
          data-test-id="duplicatePolicyModalSelect"
          optionRender={(option) => (
            <Tooltip
              title={!!option.data.disabled && t('select.disabledOption')}
              placement="topLeft"
            >
              {option.label}
            </Tooltip>
          )}
        />
      </Tooltip>
    </Modal>
  )
}

export default DuplicatePolicyModal
