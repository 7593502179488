import { CaretRightOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Spin } from 'antd'
import axios from 'axios'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Text } from '@signifyd/components'
import { ArrayOneOrMore } from '@signifyd/utils'
import useListRules from 'core/queries/useListRules/useListRules'
import styles from './ListRuleDropdown.less'
import MenuItemText from './MenuItemText'

interface Props {
  ruleIds: ArrayOneOrMore<number>
}

const ListRuleDropdown: FC<Props> = ({ ruleIds }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [visible, setVisible] = useState(false)

  const {
    data: listRules,
    isLoading,
    error,
  } = useListRules(
    { ruleIds: ruleIds.toString() },
    { enabled: !!ruleIds.length }
  )

  let menuItems
  if (isLoading) {
    menuItems = (
      <Menu.Item
        key="spinner"
        className={styles.loadingMenuItem}
        data-test-id="loadingMenuItem"
      >
        <Spin size="small" />
      </Menu.Item>
    )
  } else if (listRules) {
    menuItems = listRules.map((rule) => (
      <Menu.Item
        key={rule.ruleId}
        onClick={() =>
          navigate(`/policies/summary/${rule.ruleId}`, {
            state: { backToPreviousPage: true },
          })
        }
        data-test-id="menuItem"
      >
        <MenuItemText>{rule.name}</MenuItemText>
      </Menu.Item>
    ))
  } else if (error && axios.isAxiosError(error)) {
    menuItems = error.response?.data.messages.map((message: string) => (
      <Menu.Item key="error" disabled data-test-id="errorMenuItem">
        <MenuItemText type="danger">{message}</MenuItemText>
      </Menu.Item>
    ))
  }

  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item key="header" disabled data-test-id="ruleDropdownTitle">
            <MenuItemText type="secondary">
              {t('listDetailsPage.sidePanel.ruleDropdownTitle')}
            </MenuItemText>
          </Menu.Item>
          {menuItems}
        </Menu>
      }
      trigger={['click']}
      placement="bottomCenter"
      getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
      open={visible}
      onOpenChange={setVisible}
    >
      <Text size="sm">
        <CaretRightOutlined
          className={
            visible
              ? `${styles.dropDownIcon} ${styles.rotated}`
              : styles.dropDownIcon
          }
        />
      </Text>
    </Dropdown>
  )
}

export default ListRuleDropdown
