import { DefinedUseQueryResult, useQuery } from '@tanstack/react-query'
import { i18nInstance } from '@signifyd/components'
import {
  VariableGroup,
  Variable,
  getDecisionCenterVariableGroups,
} from '@signifyd/http'

interface EnhancedVariableGroup {
  id: number
  name: string
  description: string
  items: Array<Variable>
}

export type VariableGroups = Record<string, EnhancedVariableGroup>

const useListVariableGroups = (
  policyFeatures: Array<Variable>
): DefinedUseQueryResult<VariableGroups> => {
  const { t } = i18nInstance

  const uniqueGroupIds = [
    ...new Set(policyFeatures?.map((features) => features.groupIds).flat(1)),
  ]

  const allVariables = {
    allVariables: {
      id: 0,
      name: 'allVariables',
      description: t('ruleConditionsPage.allVariables'),
      items: policyFeatures,
    },
  }

  return useQuery({
    queryKey: ['listVariableGroups', uniqueGroupIds, policyFeatures],
    enabled: !!uniqueGroupIds?.length,
    initialData: allVariables,
    queryFn: async () => {
      const { data: variables } = await getDecisionCenterVariableGroups(
        uniqueGroupIds.join(',')
      )

      const { data } = variables

      const groups: VariableGroups = data.reduce(
        (groups: VariableGroups, currentValue: VariableGroup) => {
          const items = policyFeatures?.filter((feature) => {
            return feature.groupIds?.includes(currentValue.id)
          })

          // We don't need groups with no items
          if (!items?.length) {
            return groups
          }

          return {
            ...groups,
            [currentValue.name]: {
              ...currentValue,
              description: currentValue.name,
              items,
            },
          }
        },
        {}
      )

      return {
        ...allVariables,
        ...groups,
      }
    },
  })
}

export default useListVariableGroups
