import { Tooltip } from 'antd'
import cx from 'classnames'
import { FC } from 'react'
import { Text } from '@signifyd/components'
import { GroupListItem } from '../../GroupFilterSelect'
import styles from './GroupContent.less'

export interface GroupContentProps {
  items: Array<GroupListItem>
  selectedItem: GroupListItem | null
  handleClick: (item: GroupListItem) => void
  truncateLength?: number
}

const GroupContent: FC<GroupContentProps> = ({
  items,
  selectedItem,
  truncateLength = 48,
  handleClick,
}) => {
  if (!items.length) {
    return null
  }

  return (
    <ul className={styles.list}>
      {items.map((item) => {
        const isActive = selectedItem?.description === item.description

        const shouldTruncate = item.description?.length > truncateLength

        return (
          <li
            className={cx(styles.listItem, isActive && styles.active)}
            key={item.name}
            data-analytics-id={`listItem-${item.id}`}
            onClick={() => handleClick(item)}
          >
            {shouldTruncate ? (
              <Tooltip
                title={
                  <Text
                    weight={isActive ? 'semibold' : 'normal'}
                    className={styles.tooltipText}
                  >
                    {item.description}
                  </Text>
                }
                placement="right"
              >
                <Text
                  weight={isActive ? 'semibold' : 'normal'}
                  className={styles.itemText}
                >
                  {item.description}
                </Text>
              </Tooltip>
            ) : (
              <Text
                weight={isActive ? 'semibold' : 'normal'}
                className={styles.itemText}
              >
                {item.description}
              </Text>
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default GroupContent
