import { Col, Input, Row } from 'antd'
import { FC, useEffect, useState } from 'react'
import { i18nInstance, Text } from '@signifyd/components'
import { isPositiveDecimal } from 'core/utils/number'
import { ConditionTreeLeafNodeValue } from 'stores/conditionTree'
import { ConditionProps } from './ConditionElement'
import styles from './ConditionElement.less'
import { SubConditionNode } from '../ConditionTreeRule/ConditionTreeRule.types'

const setArrayValue = (
  value: string,
  arrayIndex: number,
  leafNode: SubConditionNode,
  onChangeValue: (value: ConditionTreeLeafNodeValue) => void
): void => {
  if (value === '' || isPositiveDecimal(value)) {
    const currentFromValue = leafNode.value?.[0] ?? ''
    const currentToValue = leafNode.value?.[1] ?? ''

    const newFromValue = arrayIndex === 0 ? value : currentFromValue
    const newToValue = arrayIndex === 1 ? value : currentToValue

    onChangeValue([newFromValue, newToValue])
  }
}

export const BetweenElement: FC<ConditionProps> = ({
  onChangeValue,
  leafNode,
}) => {
  const { t } = i18nInstance
  const [error, setError] = useState(false)

  useEffect(() => {
    if (leafNode.value) {
      const [firstValue, secondValue] = leafNode.value

      const valuesMatch = firstValue === secondValue
      const valuesEmpty = firstValue === '' && secondValue === ''

      setError(valuesMatch && !valuesEmpty)
    }
  }, [leafNode.value])

  return (
    <Row gutter={12} justify="space-between" align={error ? 'top' : 'middle'}>
      <Col span={24} xxl={11}>
        <Input
          placeholder={t(
            'ruleConditionsPage.conditionInputs.numberInput.placeholder'
          )}
          status={error ? 'error' : ''}
          className={styles.input}
          value={leafNode.value?.[0] ?? ''}
          onChange={({ target: { value } }) => {
            setArrayValue(value, 0, leafNode, onChangeValue)
          }}
          data-test-id="numberArrayInputValue1"
          data-analytics-id="number-array-input-value-1"
        />
      </Col>
      {t('ruleConditionsPage.conditionInputs.numberInput.and')}
      <Col span={24} xxl={11}>
        <Input
          placeholder={t(
            'ruleConditionsPage.conditionInputs.numberInput.placeholder'
          )}
          status={error ? 'error' : ''}
          className={styles.input}
          value={leafNode.value?.[1] ?? ''}
          onChange={({ target: { value } }) => {
            setArrayValue(value, 1, leafNode, onChangeValue)
          }}
          data-test-id="numberArrayInputValue2"
          data-analytics-id="number-array-input-value-2"
        />
      </Col>
      {error && (
        <Text type="danger" size="sm" className={styles.errorText}>
          {t('ruleConditionsPage.conditionInputs.numberInput.betweenError')}
        </Text>
      )}
    </Row>
  )
}
