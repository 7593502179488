import {
  DeleteOutlined,
  DownloadOutlined,
  ToolOutlined,
} from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, message, Popconfirm } from 'antd'
import cx from 'classnames'
import moment from 'moment-timezone'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'stores'
import { useQueryParams } from 'use-query-params'
import { colorLilac } from '@signifyd/colors'
import {
  GlobeIcon,
  Space,
  Text,
  useTokenDownload,
  useUserContext,
} from '@signifyd/components'
import {
  getRuleSimulationDownloadToken,
  SIMULATION_STATUS,
  SIMULATION_TYPE,
} from '@signifyd/http'
import { GET_PILOT_SIMULATIONS_QUERY_KEY_PREFIX } from 'core/hooks/useGetPilotSimulations/useGetPilotSimulations'
import useUpdatePilotSimulation from 'core/hooks/useUpdatePilotSimulation/useUpdatePilotSimulation'
import useGetRuleSet from 'core/queries/useGetRuleSet/useGetRuleSet'
import usePublishRuleSet from 'core/queries/usePublishRuleSet/usePublishRuleSet'
import { PilotPreferenceModal } from 'pages/PublishWithSimulatorPage/components/PilotPreferenceModal/PilotPreferenceModal'
import { useTypedRouteParams } from 'pages/PublishWithSimulatorPage/hooks'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import { simulationDateFormat } from 'stores/simulation'
import { hasMatchingRuleIds } from 'stores/simulation/simulation.store.utils'
import styles from './HeaderExtra.less'

interface Props {
  isEditingPilot: boolean
}

const PilotHeaderExtra: FC<Props> = ({ isEditingPilot }) => {
  const { t } = useTranslation()
  const { teamId, checkpoint } = useTypedRouteParams()
  const queryClient = useQueryClient()
  const [filters, setFilters] = useQueryParams(PublishPageQueryParams)

  const [isModalOpen, setModalOpen] = useState(false)

  const navigate = useNavigate()
  const { user } = useUserContext()
  const { data: ruleSet } = useGetRuleSet(filters.ruleSetId)
  const { data: draftPilotRuleSet } = useGetRuleSet(filters.draftPilotRuleSetId)
  const publishRuleSet = usePublishRuleSet()
  const updateSimulation = useUpdatePilotSimulation()
  const { ruleSimulation } = useStoreState((store) => store.simulationModel)
  const { cancelRuleSimulation, createRuleSimulation, getRuleSimulation } =
    useStoreActions((store) => store.simulationModel)

  const hasChangedPilotRules = !hasMatchingRuleIds(
    ruleSimulation,
    filters.draftPilotRuleSetId ? draftPilotRuleSet : ruleSet
  )
  const isSimulationFinished =
    ruleSimulation?.status === SIMULATION_STATUS.FINISHED

  const {
    downloadSearchResults,
    isBackgroundDownloadActive,
    isDownloadPending,
  } = useTokenDownload<number>({
    getTokenFunction: getRuleSimulationDownloadToken,
    getTokenPayload: filters.simulationId!,
    baseDownloadUrl: `${BASE_URL}/v2/ruleSimulations/download/${user.userId}`,
  })

  const navigateToDashboard = (): void => {
    navigate(`/policies/dashboard?teamId=${teamId}&checkpoint=${checkpoint}`)
  }

  const handlePublish = async (): Promise<void> => {
    if (!ruleSet?.ruleSetId || !ruleSimulation || !teamId) {
      return
    }

    if (ruleSimulation.status === SIMULATION_STATUS.RUNNING) {
      await updateSimulation.mutateAsync({
        simulationId: ruleSimulation.simulationId,
        payload: { status: SIMULATION_STATUS.FINISHED },
      })
    }

    await publishRuleSet.mutateAsync({ ruleSet })

    queryClient.invalidateQueries([
      GET_PILOT_SIMULATIONS_QUERY_KEY_PREFIX,
      teamId,
      checkpoint,
    ])

    navigateToDashboard()

    message.success({
      content: t('ruleCommon.successMessage.publishRuleSet'),
      duration: 10,
    })
  }

  const handleSave = async (): Promise<void> => {
    if (!ruleSimulation || !filters.draftPilotRuleSetId) {
      return
    }

    await cancelRuleSimulation(ruleSimulation.simulationId)

    queryClient.invalidateQueries([
      GET_PILOT_SIMULATIONS_QUERY_KEY_PREFIX,
      teamId,
      checkpoint,
    ])

    await createRuleSimulation({
      ruleSetId: filters.draftPilotRuleSetId,
      teamId,
      startDate: ruleSimulation.startDate,
      endDate: ruleSimulation.endDate,
      simulationType: SIMULATION_TYPE.PILOT_POLICY,
      emailAddresses: ruleSimulation.emailAddresses,
    })

    setFilters({
      ruleSetId: filters.draftPilotRuleSetId,
      simulationId: ruleSimulation.simulationId,
      draftPilotRuleSetId: undefined,
    })
  }

  const handleUpdate = (endDate: string): void => {
    if (!ruleSimulation) {
      return
    }

    updateSimulation.mutate(
      {
        simulationId: ruleSimulation.simulationId,
        payload: { endDate: moment(endDate).format(simulationDateFormat) },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            GET_PILOT_SIMULATIONS_QUERY_KEY_PREFIX,
            teamId,
            checkpoint,
          ])
          getRuleSimulation(ruleSimulation.simulationId)
          setModalOpen(false)
        },
      }
    )
  }

  const handleCancel = async (): Promise<void> => {
    if (!ruleSimulation) {
      return
    }

    await cancelRuleSimulation(ruleSimulation.simulationId)
    queryClient.invalidateQueries([
      GET_PILOT_SIMULATIONS_QUERY_KEY_PREFIX,
      teamId,
      checkpoint,
    ])
    navigateToDashboard()
  }

  return (
    <div className={styles.extra} data-test-id="pilotSimulationHeader">
      {isEditingPilot && !isSimulationFinished && (
        <Popconfirm
          icon={null}
          onConfirm={handleCancel}
          okButtonProps={{
            type: 'link',
            'data-analytics-id': 'confirm-end-pilot-btn',
            'data-test-id': 'confirmEndPilot',
          }}
          cancelButtonProps={{ type: 'link' }}
          placement="bottomRight"
          title={
            <>
              <Space size="sm" />
              <Text
                size="lg"
                weight="semibold"
                className={styles.popConfirmTitle}
              >
                {t(
                  'publishWithSimulatorPage.pilotSimulation.header.buttons.endPilotPopover.title'
                )}
              </Text>
            </>
          }
          description={
            <div className={styles.popConfirmContainer}>
              <Text className={styles.popConfirmDescription}>
                {t(
                  'publishWithSimulatorPage.pilotSimulation.header.buttons.endPilotPopover.description'
                )}
              </Text>
              <Space size="sm" />
            </div>
          }
        >
          <Button
            data-analytics-id="end-pilot-btn"
            ghost
            color="default"
            variant="solid"
            icon={<DeleteOutlined />}
            className={cx([styles.btn, styles.linkButton])}
          >
            {t(
              'publishWithSimulatorPage.pilotSimulation.header.buttons.endPilot'
            )}
          </Button>
        </Popconfirm>
      )}
      {!isSimulationFinished && (
        <Button
          data-analytics-id="pilot-preferences-btn"
          icon={<ToolOutlined />}
          ghost
          color="default"
          variant="solid"
          className={cx([styles.btn, styles.linkButton])}
          type="link"
          onClick={() => setModalOpen(true)}
        >
          {t(
            'publishWithSimulatorPage.pilotSimulation.header.buttons.preferences'
          )}
        </Button>
      )}
      <Button
        data-analytics-id="back-to-policies-btn"
        ghost
        color="default"
        className={styles.btn}
        variant="solid"
        onClick={navigateToDashboard}
      >
        {t(
          'publishWithSimulatorPage.pilotSimulation.header.buttons.backToPolicies'
        )}
      </Button>

      {isEditingPilot && !isSimulationFinished && (
        <Popconfirm
          icon={null}
          onConfirm={handleSave}
          okButtonProps={{
            type: 'link',
            disabled: !hasChangedPilotRules,
            'data-analytics-id': 'confirm-save-pilot-btn',
            'data-test-id': 'confirmSavePilot',
          }}
          cancelButtonProps={{ type: 'link' }}
          placement="bottomRight"
          title={
            <>
              <Space size="sm" />
              <Text
                size="lg"
                weight="semibold"
                className={styles.popConfirmTitle}
              >
                {t(
                  'publishWithSimulatorPage.pilotSimulation.header.buttons.savePilotPopover.title'
                )}
              </Text>
            </>
          }
          description={
            <div className={styles.popConfirmContainer}>
              <Text className={styles.popConfirmDescription}>
                {t(
                  'publishWithSimulatorPage.pilotSimulation.header.buttons.savePilotPopover.description'
                )}
              </Text>
              <Space size="sm" />
              <Button
                data-analytics-id="download-pilot-btn"
                className={styles.downloadButton}
                onClick={downloadSearchResults}
                disabled={
                  isBackgroundDownloadActive ||
                  moment(ruleSimulation?.startDate).isBefore(
                    moment(moment.now()).subtract(1, 'month')
                  )
                }
                loading={isDownloadPending}
                icon={<DownloadOutlined />}
              >
                {t(
                  'publishWithSimulatorPage.pilotSimulation.header.buttons.savePilotPopover.download'
                )}
              </Button>
            </div>
          }
        >
          <Button
            data-analytics-id="save-pilot-btn"
            className={cx([
              styles.btn,
              { [styles.filledButtons]: hasChangedPilotRules },
            ])}
            disabled={!hasChangedPilotRules}
          >
            {t(
              'publishWithSimulatorPage.pilotSimulation.header.buttons.savePilot'
            )}
          </Button>
        </Popconfirm>
      )}

      {isSimulationFinished ? (
        <Button
          data-analytics-id="publish-pilot-btn"
          onClick={handlePublish}
          className={cx([styles.btn, styles.filledButtons])}
          disabled={hasChangedPilotRules}
          icon={<GlobeIcon fill={colorLilac} />}
        >
          {t(
            'publishWithSimulatorPage.pilotSimulation.header.buttons.publishSimulation'
          )}
        </Button>
      ) : (
        <Popconfirm
          icon={null}
          onConfirm={handlePublish}
          okButtonProps={{
            type: 'link',
            'data-analytics-id': 'confirm-publish-pilot-btn',
            'data-test-id': 'confirmPublishPilot',
          }}
          cancelButtonProps={{ type: 'link' }}
          placement="bottomRight"
          title={
            <>
              <Space size="sm" />
              <Text
                size="lg"
                weight="semibold"
                className={styles.popConfirmTitle}
              >
                {t(
                  'publishWithSimulatorPage.pilotSimulation.header.buttons.savePilotPopover.title'
                )}
              </Text>
            </>
          }
          description={
            <div className={styles.popConfirmContainer}>
              <Text className={styles.popConfirmDescription}>
                {t(
                  'publishWithSimulatorPage.pilotSimulation.header.buttons.publishSimulationPopover.description'
                )}
              </Text>
            </div>
          }
        >
          <Button
            className={cx([styles.btn, styles.filledButtons])}
            disabled={hasChangedPilotRules}
            icon={<GlobeIcon fill={colorLilac} />}
          >
            {t(
              'publishWithSimulatorPage.pilotSimulation.header.buttons.publishSimulation'
            )}
          </Button>
        </Popconfirm>
      )}
      <PilotPreferenceModal
        showModal={isModalOpen}
        isSaving={updateSimulation.isLoading}
        onCancel={() => setModalOpen(false)}
        onOk={handleUpdate}
      />
    </div>
  )
}

export default PilotHeaderExtra
