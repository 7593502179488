import { Form } from 'antd'
import cx from 'classnames'
import { FC } from 'react'
import { FormItemProps } from 'antd/lib'
import styles from './Form.less'

const { Item } = Form

const FormItem: FC<FormItemProps> = ({ label, ...props }) => {
  return (
    <Item
      label={label}
      className={cx([styles.formItem, props.className])}
      {...props}
    />
  )
}

export default FormItem
