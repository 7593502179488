import { Modal } from 'antd'
import { FC, useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useIsThirdGen } from '@signifyd/components'
import {
  UpdatePredictionListPayload,
  PREDICTION_LIST_UPDATABLE_FIELD,
} from '@signifyd/http'
import ListDetailsForm from 'core/components/ListDetailsForm'
import { SIG_MODAL_PROPS } from 'core/constants'
import { HTTPStatus } from 'core/http'

interface Props {
  visible: boolean
  httpStatus: HTTPStatus
  onOk: (param: UpdatePredictionListPayload) => void
  onCancel: () => void
  currentName: string
  currentDescription: string | undefined
  usedInPolicy: boolean
}

const EditListDetailsModal: FC<Props> = ({
  visible,
  httpStatus,
  onOk,
  onCancel,
  currentName,
  currentDescription,
  usedInPolicy,
}) => {
  const { t } = useTranslation()
  const [name, setName] = useState<string>(currentName)
  const [description, setDescription] = useState<string | undefined>(
    currentDescription
  )
  const isThirdGen = useIsThirdGen()

  const trimmedName = name.trim()
  const trimmedDescription = description?.trim()

  const resetState = useCallback((): void => {
    setName(currentName)
    setDescription(currentDescription)
  }, [currentName, currentDescription, setName, setDescription])

  useEffect(() => {
    setName(currentName)
    setDescription(currentDescription)
  }, [currentName, currentDescription, setName, setDescription])

  return (
    <Modal
      {...SIG_MODAL_PROPS}
      title={t('listCommon.editListDetailsModal.title')}
      open={visible}
      okText={t('listCommon.editListDetailsModal.ok')}
      cancelText={t('listCommon.editListDetailsModal.cancel')}
      closable={false}
      okButtonProps={{
        type: isThirdGen ? 'primary' : 'link',
        disabled:
          !name ||
          !trimmedName ||
          (trimmedName === currentName &&
            trimmedDescription === currentDescription),
        loading: httpStatus.pending,
      }}
      onCancel={() => {
        onCancel()
        resetState()
      }}
      onOk={() => {
        if (
          trimmedName !== currentName &&
          trimmedDescription !== currentDescription
        ) {
          onOk({
            updateMask: [
              PREDICTION_LIST_UPDATABLE_FIELD.NAME,
              PREDICTION_LIST_UPDATABLE_FIELD.DESCRIPTION,
            ],
            name,
            description,
          })
        } else if (
          trimmedName !== currentName &&
          trimmedDescription === currentDescription
        ) {
          onOk({
            updateMask: [PREDICTION_LIST_UPDATABLE_FIELD.NAME],
            name,
          })
        } else if (
          trimmedName === currentName &&
          trimmedDescription !== currentDescription
        ) {
          onOk({
            updateMask: [PREDICTION_LIST_UPDATABLE_FIELD.DESCRIPTION],
            description,
          })
        }
      }}
    >
      <ListDetailsForm
        disabled={httpStatus.pending}
        name={name}
        description={description}
        onChangeName={setName}
        onChangeDescription={setDescription}
        errorMsg={httpStatus.error}
        usedInPolicy={usedInPolicy}
      />
    </Modal>
  )
}

export default EditListDetailsModal
