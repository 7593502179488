import { Form as AntForm } from 'antd'
import { FC } from 'react'
import { FormProps } from 'antd/lib'

const Form: FC<FormProps> = (props) => {
  return (
    <AntForm
      colon={false}
      onKeyDown={(e) => {
        // Stop propagating keydown event to ant Tabs/rc-tabs.
        // rc-tabs calls e.preventDefault on keydown, which will prevent left
        // and right arrow keys from working properly in other components.
        e.stopPropagation()
      }}
      {...props}
    />
  )
}

export default Form
