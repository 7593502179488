import { AreaChartOutlined, TableOutlined } from '@ant-design/icons'
import { Card, Radio, Flex } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from 'use-query-params'
import { Space, Text, TitleThirdGen, useIsThirdGen } from '@signifyd/components'
import { PublishPageQueryParams } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.config'
import { DISTRIBUTION_TYPE } from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.types'
import DistributionSankey from '../DistributionSankey'
import SimulationDistributionTable from '../SimulationDistributionTable'
import styles from './DistributionCard.less'

interface Props {
  pilotHasNoData: boolean
}

const DistributionCard: FC<Props> = ({ pilotHasNoData }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'publishWithSimulatorPage',
  })
  const isThirdGen = useIsThirdGen()
  const [filters, setFilters] = useQueryParams(PublishPageQueryParams)

  if (pilotHasNoData) {
    return (
      <Card title={t('distributionCard.title')}>
        <Flex justify="center">
          <Text size="lg">{t('pilotSimulation.incompletePilotData')}</Text>
        </Flex>
      </Card>
    )
  }

  return (
    <Card
      title={
        isThirdGen ? (
          <TitleThirdGen level={2}>{t('distributionCard.title')}</TitleThirdGen>
        ) : (
          t('distributionCard.title')
        )
      }
      extra={
        <Radio.Group
          value={filters?.distributionType}
          onChange={(e) => {
            const distributionType = e.target.value

            setFilters({ distributionType })
          }}
        >
          <Radio.Button
            value={DISTRIBUTION_TYPE.GRAPH}
            data-analytics-id="graph-chart-radio"
          >
            <AreaChartOutlined />
          </Radio.Button>
          <Radio.Button
            value={DISTRIBUTION_TYPE.TABLE}
            data-analytics-id="table-chart-radio"
          >
            <TableOutlined />
          </Radio.Button>
        </Radio.Group>
      }
      className={styles.card}
    >
      <Space size="sm" />
      {filters.distributionType === DISTRIBUTION_TYPE.GRAPH ? (
        <DistributionSankey />
      ) : (
        <SimulationDistributionTable />
      )}
    </Card>
  )
}

export default DistributionCard
