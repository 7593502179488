import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { i18nInstance } from '@signifyd/components'
import {
  CreatePredictionListEntriesPayload,
  createPredictionListEntriesWithForm,
  createPredictionListEntriesWithJson,
  DUPLICATE_PREDICTION_LIST_ENTRY_ACTION,
  PREDICTION_LIST_TYPE,
} from '@signifyd/http'
import { getHTTPErrorStatus } from 'core/http'

export interface CreatePredictionListEntriesWithCSVParams {
  predictionListId: number
  file: File
  type: PREDICTION_LIST_TYPE
  duplicateEntryAction?: DUPLICATE_PREDICTION_LIST_ENTRY_ACTION
  onSuccessCallback?: () => void
}

export interface CreatePredictionListEntriesWithJSONParams {
  predictionListId: number
  payload: CreatePredictionListEntriesPayload
  onSuccessCallback?: () => void
}

export const useCreatePredictionListEntriesWithCSV = (): UseMutationResult<
  AxiosResponse<Array<number>>,
  AxiosError,
  CreatePredictionListEntriesWithCSVParams
> => {
  return useMutation({
    mutationFn: async ({
      predictionListId,
      file,
      type,
      duplicateEntryAction,
    }) => {
      const form = new FormData()

      form.append('file', file)
      form.append('type', type)
      form.append(
        'duplicateEntryAction',
        duplicateEntryAction ?? DUPLICATE_PREDICTION_LIST_ENTRY_ACTION.OVERWRITE
      )

      return createPredictionListEntriesWithForm(predictionListId, form)
    },
    onSuccess: (_, { onSuccessCallback }) => {
      onSuccessCallback?.()
    },
    onError: (error) => {
      const axiosError = error as AxiosError

      const status = getHTTPErrorStatus(
        axiosError,
        i18nInstance.t('listCommon.fallbackErrorMessage.createListEntries')
      )

      throw new Error(status.error)
    },
  })
}

export const useCreatePredictionListEntriesWithJSON = (): UseMutationResult<
  AxiosResponse<Array<number>>,
  AxiosError,
  CreatePredictionListEntriesWithJSONParams
> => {
  return useMutation({
    mutationFn: async ({ predictionListId, payload }) =>
      createPredictionListEntriesWithJson(predictionListId, {
        type: payload.type,
        values: payload.values,
        duplicateEntryAction: payload.duplicateEntryAction,
      }),
    onSuccess: (_, { onSuccessCallback }) => {
      onSuccessCallback?.()
    },
    onError: (error) => {
      const axiosError = error as AxiosError

      const status = getHTTPErrorStatus(
        axiosError,
        i18nInstance.t('listCommon.fallbackErrorMessage.createListEntries')
      )

      throw new Error(status.error)
    },
  })
}
