import { Button, Popconfirm } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'stores'
import { spacingSM } from '@signifyd/ant'
import { Space, TextThirdGen } from '@signifyd/components'
import { ButtonProps } from 'antd/es/button'
import styles from './HeaderExtra.less'

interface Props {
  onClick: () => void
  isEditing: boolean
}

const CancelButtonThirdGen: FC<Props> = ({ onClick, isEditing }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'publishWithSimulatorPage',
  })

  const loading = useStoreState(
    (state) => state.simulationModel.isLoading.cancelRuleSimulation
  )

  return (
    <Popconfirm
      title={
        <div data-test-id="cancelPublishPopConfirm">
          <TextThirdGen paragraph weight="semibold">
            {t('cancelPublishModal.title')}
          </TextThirdGen>
          <Space size={spacingSM} />
          <TextThirdGen
            weight="normal"
            data-test-id="cancelPublishModalDescription"
          >
            {t('cancelPublishModal.description', {
              context: isEditing ? 'edit' : 'create',
            })}
          </TextThirdGen>
        </div>
      }
      icon={null}
      onConfirm={onClick}
      okText={t('cancelPublishModal.ok')}
      cancelText={t('cancelPublishModal.cancel')}
      placement="bottomLeft"
      okButtonProps={
        {
          loading,
          'data-analytics-id': 'confirm-cancel-publish-button',
        } as ButtonProps
      }
      cancelButtonProps={
        {
          loading,
          'data-analytics-id': 'discard-cancel-publish-button',
          type: 'link',
        } as ButtonProps
      }
    >
      <Button
        type="default"
        className={styles.btn}
        data-test-id="cancelPublishRulesetButton"
        data-analytics-id="cancel-publish-ruleset-button"
      >
        {t('header.cancelBtn')}
      </Button>
    </Popconfirm>
  )
}

export default CancelButtonThirdGen
