import { partition } from 'lodash'
import moment from 'moment-timezone'
import { useMemo } from 'react'
import { RuleResponse } from '@signifyd/http'
import useGetRuleSet from 'core/queries/useGetRuleSet/useGetRuleSet'
import useGetAllRules from '../useGetAllRules'
import { useTypedRouteParams } from '../useTypedRouteParams/useTypedRouteParams'

interface UseGetRulesByRuleSetResult {
  publishedRules: Array<RuleResponse>
  otherRules: Array<RuleResponse>
  expiredRules: Array<RuleResponse>
  isFetching: boolean
}

export const useGetRulesByRuleSet = (
  ruleSetId?: number | null
): UseGetRulesByRuleSetResult => {
  const { teamId, checkpoint } = useTypedRouteParams()

  const { data: rules, isFetching: areRulesFetching } = useGetAllRules(
    teamId,
    checkpoint
  )
  const { data: ruleSet, isFetching: isRuleSetFetching } =
    useGetRuleSet(ruleSetId)

  const isFetching = areRulesFetching || isRuleSetFetching

  return useMemo(() => {
    const [publishedRules, otherRules] = partition(rules, (rule) =>
      ruleSet?.ruleIds.includes(rule.ruleId)
    )

    publishedRules.sort((a, b) => {
      const first =
        ruleSet?.ruleIds.findIndex((ruleId) => ruleId === a.ruleId) ?? 0
      const second =
        ruleSet?.ruleIds.findIndex((ruleId) => ruleId === b.ruleId) ?? 0

      return first - second
    })

    const expiredRules = publishedRules.filter(
      ({ scheduleEnd }) =>
        scheduleEnd && moment(scheduleEnd).isBefore(new Date(), 'second')
    )

    return {
      expiredRules,
      isFetching,
      otherRules,
      publishedRules,
      ruleSet,
    }
  }, [isFetching, rules, ruleSet])
}

export default useGetRulesByRuleSet
