import { InfoCircleOutlined } from '@ant-design/icons'
import { Modal, Tooltip } from 'antd'
import { Dispatch, FC, ReactNode, SetStateAction } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  Space,
  Text,
  StandardListIcon,
  AbusePreventionIcon,
  i18nInstance,
  useIsThirdGen,
} from '@signifyd/components'
import GridSelect from 'core/components/GridSelect'
import { SIG_MODAL_PROPS } from 'core/constants'
import styles from './CreateListModal.less'
import { STEP } from './CreateListModal.utils'

export const getStepTrackingText = (step: STEP): ReactNode => {
  return (
    <Text size="xs" className={styles.stepText}>
      {i18nInstance.t('listCommon.createListModal.currentStep', {
        currentStep: step,
      })}
    </Text>
  )
}

interface Props {
  visible: boolean
  onOk: () => void
  onCancel: () => void
  listType?: string
  setListType: Dispatch<SetStateAction<string | undefined>>
}

const CreateListModal: FC<Props> = ({
  visible,
  onOk,
  onCancel,
  listType,
  setListType,
}) => {
  const { t } = useTranslation()
  const isThirdGen = useIsThirdGen()

  return (
    <div>
      <Modal
        {...SIG_MODAL_PROPS}
        title={
          <>
            {getStepTrackingText(STEP.one)}
            <div>{t('listCommon.createListModal.title')}</div>
          </>
        }
        open={visible}
        okText={t('listCommon.createListModal.next')}
        cancelText={t('listCommon.createListModal.cancel')}
        closable={false}
        okButtonProps={{
          disabled: !listType,
          type: isThirdGen ? 'primary' : 'link',
        }}
        onCancel={onCancel}
        onOk={onOk}
        className={styles.createListModal}
      >
        <Text size="sm" block weight="semibold" className={styles.modalText}>
          {t('listCommon.createListModal.subTitle')}
        </Text>
        <Space size="sm" />

        <Text size="sm" type="secondary" className={styles.modalText}>
          <Trans i18nKey="listCommon.createListModal.standardDescription" />
        </Text>
        <Tooltip
          trigger="hover"
          placement="topRight"
          className={styles.helpIcon}
          arrow={{ pointAtCenter: true }}
          title={t('listCommon.createListModal.standardDescriptionTooltip')}
        >
          <InfoCircleOutlined
            style={{ fontSize: '10px' }}
            data-test-id="standardSelectTooltip"
            data-analytics-id="standard-select-tooltip"
          />
        </Tooltip>

        <Space size="sm" />
        <Text size="sm" type="secondary" className={styles.modalText}>
          <Trans i18nKey="listCommon.createListModal.abuseDescription" />
        </Text>
        <Tooltip
          trigger="hover"
          placement="topRight"
          className={styles.helpIcon}
          arrow={{ pointAtCenter: true }}
          title={t('listCommon.createListModal.abuseDescriptionTooltip')}
        >
          <InfoCircleOutlined
            style={{ fontSize: '10px' }}
            data-test-id="abuseSelectTooltip"
            data-analytics-id="abuse-select-tooltip"
          />
        </Tooltip>

        <Space size="lg" />
        <GridSelect
          centered
          value={listType}
          items={[
            {
              key: 'standard',
              label: t('listCommon.createListModal.standard'),
              icon: StandardListIcon,
              testId: 'standardListGridTile',
            },
            {
              key: 'abusePrevention',
              label: t('listCommon.createListModal.abusePrevention'),
              icon: AbusePreventionIcon,
              testId: 'abusePreventionListGridTile',
            },
          ]}
          onChange={setListType}
          span={12}
          width="Full"
        />
      </Modal>
    </div>
  )
}

export default CreateListModal
