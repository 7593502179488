import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { message } from 'antd'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { i18nInstance } from '@signifyd/components'
import { deletePredictionList } from '@signifyd/http'
import { getHTTPErrorStatus } from 'core/http'
import { useDashboardPageFilters } from 'pages/DashboardPage/hooks/useDashboardPageFilters'

export const PREDICTION_LIST_DETAILS_QUERY_KEY = 'predictionListDetails'

const useDeletePredictionList = (): UseMutationResult<
  number,
  AxiosError,
  number
> => {
  const navigate = useNavigate()

  const [query] = useDashboardPageFilters()

  return useMutation({
    mutationFn: async (predictionListId) => {
      await deletePredictionList(predictionListId)

      return predictionListId
    },
    onSuccess: async () => {
      navigate(`/lists?teamId=${query.teamId}`)
    },
    onError: (error) => {
      const status = getHTTPErrorStatus(
        error,
        i18nInstance.t('listCommon.fallbackErrorMessage.deleteList')
      )

      message.error({ content: status.error })
    },
  })
}

export default useDeletePredictionList
