import { message } from 'antd'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryParams } from 'use-query-params'
import { PageSpinner } from '@signifyd/components'
import { SIMULATION_TYPE } from '@signifyd/http'
import TestTeamBanner from 'core/components/TestTeamBanner'
import PublishWithSimulatorPageContainer from './containers/PublishWithSimulatorPageContainer'
import {
  useInitializePublishWithSimulation,
  useTypedRouteParams,
} from './hooks'
import { PublishPageQueryParams } from './PublishWithSimulatorPage.config'
import { getExpiredListMessage } from './PublishWithSimulatorPage.utils'

const PublishWithSimulatorPage: FC = () => {
  const { t } = useTranslation()
  const { teamId } = useTypedRouteParams()
  const [{ simulationType }] = useQueryParams(PublishPageQueryParams)

  const { ruleSet, expiredRules, isInitialLoading } =
    useInitializePublishWithSimulation()

  // If any expired rules on page load, fire off same error message as API
  const expiryMessage = getExpiredListMessage(expiredRules)

  useEffect(() => {
    if (expiryMessage) {
      message.error(expiryMessage)
    }
  }, [expiryMessage])

  if (isInitialLoading && simulationType === SIMULATION_TYPE.PILOT_POLICY) {
    return <PageSpinner />
  }

  return (
    <div data-test-id="publishWithSimulatorPage">
      <TestTeamBanner
        teamId={teamId}
        text={t('common.testTeamBanner.policy')}
      />
      <PublishWithSimulatorPageContainer
        ruleSet={ruleSet}
        isLoading={isInitialLoading || !ruleSet?.ruleSetId}
        expiredRules={expiredRules}
      />
    </div>
  )
}

export default PublishWithSimulatorPage
