import { Alert } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Space } from '@signifyd/components'

interface Props {
  simulationSizeLimit: number
  notMatchingRuleIds: boolean
}

const SimulationResultInfoMessage: FC<Props> = ({
  simulationSizeLimit,
  notMatchingRuleIds,
}) => {
  const { t, i18n } = useTranslation()

  return (
    <>
      <Alert
        message={t('publishWithSimulatorPage.simulationResult.infoMessage', {
          simulationSizeLimit: simulationSizeLimit.toLocaleString(
            i18n.language
          ),
        })}
        type="info"
        showIcon
        data-test-id="simulationResultInfoMessage"
      />
      {notMatchingRuleIds && <Space size="md" />}
    </>
  )
}

export default SimulationResultInfoMessage
