import {
  EXPIRATION_TYPES,
  PREDICTION_LIST_UPDATABLE_FIELD,
} from '@signifyd/http'
import useUpdatePredictionList from 'core/hooks/useUpdatePredictionList'
import { HTTPStatus } from 'core/http'
import {
  calculateExpirationUpdatableFields,
  ExpirationDisplayDetails,
  getExpirationTypeUpdatableField,
} from './listExpirationUtils'

export interface ListExpirationFormTypes {
  submitExpiration: (expiration: ExpirationDisplayDetails) => Promise<void>
  deleteExpiration: (type: EXPIRATION_TYPES) => Promise<void>
  updatePredictionListHTTPStatus: HTTPStatus
}

interface ListExpirationFormParamTypes {
  predictionListId: number
}

const useListExpirationForm = ({
  predictionListId,
}: ListExpirationFormParamTypes): ListExpirationFormTypes => {
  const {
    mutateAsync: updatePredictionList,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useUpdatePredictionList()

  const updatePredictionListHTTPStatus: HTTPStatus = {
    pending: isLoading,
    success: isSuccess,
    error: error?.message,
  }

  const deleteExpiration = async (
    expirationType: EXPIRATION_TYPES
  ): Promise<void> => {
    const expirationTypeField = getExpirationTypeUpdatableField(expirationType)

    const updateMask = [expirationTypeField]

    if (
      expirationTypeField ===
      PREDICTION_LIST_UPDATABLE_FIELD.SECONDS_BEFORE_EXPIRATION_PER_ENTRY
    ) {
      updateMask.push(PREDICTION_LIST_UPDATABLE_FIELD.EXPIRATION_DISPLAY_UNIT)
    }

    await updatePredictionList({
      listId: predictionListId,
      payload: { updateMask, expiration: {} },
    })
  }

  const submitExpiration = async (
    expiration: ExpirationDisplayDetails
  ): Promise<void> => {
    const expirationUpdatableFields =
      calculateExpirationUpdatableFields(expiration)

    await updatePredictionList({
      listId: predictionListId,
      payload: {
        expiration: expirationUpdatableFields,
        updateMask: [
          PREDICTION_LIST_UPDATABLE_FIELD.MAX_ORDERS_PER_ENTRY,
          PREDICTION_LIST_UPDATABLE_FIELD.MAX_CHARGEBACKS_PER_ENTRY,
          PREDICTION_LIST_UPDATABLE_FIELD.SECONDS_BEFORE_EXPIRATION_PER_ENTRY,
          PREDICTION_LIST_UPDATABLE_FIELD.EXPIRATION_DISPLAY_UNIT,
        ],
      },
    })

    if (isError) {
      throw error
    }
  }

  return {
    submitExpiration,
    deleteExpiration,
    updatePredictionListHTTPStatus,
  }
}

export default useListExpirationForm
