import { Collapse } from 'antd'
import { FC } from 'react'
import { colorSnow } from '@signifyd/colors'
import { CollapsePanelProps } from 'antd/es/collapse'

const { Panel } = Collapse

export type Theme = 'default' | 'transparent'

interface Props extends CollapsePanelProps {
  theme?: Theme
}

export const PANEL_STYLE_BY_THEME = {
  default: {
    background: colorSnow,
    border: 0,
  },
  transparent: {
    background: 'none',
    border: 0,
    padding: 0,
  },
}

const SigPanel: FC<Props> = ({ theme = 'default', ...props }) => (
  <Panel
    {...props}
    style={PANEL_STYLE_BY_THEME[theme]}
    data-test-id="collapsePanel"
  />
)

export default SigPanel
