import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Routes, Route, Navigate } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { useUserContext, useIsThirdGen } from '@signifyd/components'
import { USER_ROLE } from '@signifyd/http'
import { Protected } from 'core/components/Protected/Protected'
import { UnauthorizedLayout } from 'core/components/UnauthorizedLayout/UnauthorizedLayout'
import { CATCH_ALL_PATH } from 'core/constants'
import useUserPermissions from 'core/hooks/useUserPermissions'
import useUserTeams from 'core/hooks/useUserTeams'
import VariableGroupPage from 'pages/AdminPages/VariableGroupPage/VariableGroupPage'
import VariableManagerPage from 'pages/AdminPages/VariableManagerPage/VariableManagerPage'
import DashboardPage from 'pages/DashboardPage'
import ListDetailsPage from 'pages/ListDetailsPage'
import ListDetailsPageThirdGen from 'pages/ListDetailsPage/ListDetailsPageThirdGen'
import ListPage from 'pages/ListPage'
import PolicyValidationPage from 'pages/NewPolicyValidationPage'
import PolicyConditionsPage from 'pages/PolicyConditionsPage'
import PolicySummaryPage from 'pages/PolicySummaryPage'
import PolicySummaryPageThirdGen from 'pages/PolicySummaryPage/PolicySummaryPageThirdGen'
import PublishWithSimulatorPage from 'pages/PublishWithSimulatorPage'

const AppRoutes: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const isThirdGen = useIsThirdGen()

  const { user } = useUserContext()
  const { liveTeams } = useUserTeams()
  const { isAdmin, isSuperAdmin } = useUserPermissions()

  const roles = user?.roles ?? []

  const canLoadRulesPage =
    roles.includes(USER_ROLE.RULES_BUILDER) || isAdmin || isSuperAdmin
  const canLoadListsPage =
    roles.includes(USER_ROLE.DECISION_CENTER_LISTS) || isAdmin || isSuperAdmin
  const canLoadAdminPage =
    roles.includes(USER_ROLE.SUPER_ADMIN) ||
    roles.includes(USER_ROLE.PRODUCT_MANAGER)

  if (!liveTeams?.length && !isAdmin && !isSuperAdmin) {
    return <UnauthorizedLayout message={t('common.unauthorized.noTeams')} />
  }

  if (!canLoadRulesPage && !canLoadListsPage && !canLoadAdminPage) {
    return <UnauthorizedLayout message={t('common.unauthorized.default')} />
  }

  return (
    <Routes>
      <Route
        path="/policies/summary/:ruleId"
        element={
          isThirdGen ? <PolicySummaryPageThirdGen /> : <PolicySummaryPage />
        }
      />
      <Route
        path="/policies"
        element={<Protected isUserAuthorized={canLoadRulesPage} />}
      >
        <Route path="dashboard" element={<DashboardPage />} />
        <Route
          path="create/:ruleId/conditions"
          element={
            <PolicyConditionsPage
              isEditing={false}
              onDiscard={() => navigate(CATCH_ALL_PATH)}
              onDenyEditRule={() => navigate(CATCH_ALL_PATH, { replace: true })}
            />
          }
        />
        <Route
          path="edit/:ruleId/conditions"
          element={
            <PolicyConditionsPage
              isEditing
              onDiscard={() => navigate(CATCH_ALL_PATH)}
              onDenyEditRule={() => navigate(CATCH_ALL_PATH, { replace: true })}
            />
          }
        />
        <Route
          path="create/:ruleId/validation"
          element={
            <PolicyValidationPage
              isEditing={false}
              onDenyEditRule={() => navigate(CATCH_ALL_PATH, { replace: true })}
              onDiscard={() => navigate(CATCH_ALL_PATH)}
            />
          }
        />
        <Route
          path="edit/:ruleId/validation"
          element={
            <PolicyValidationPage
              isEditing
              onDenyEditRule={() => navigate(CATCH_ALL_PATH, { replace: true })}
              onDiscard={() => navigate(CATCH_ALL_PATH)}
            />
          }
        />
        <Route
          path="publish/:teamId/:checkpoint"
          element={<PublishWithSimulatorPage />}
        />
        <Route index element={<Navigate to="/policies/dashboard" />} />
      </Route>

      <Route
        path="/lists"
        element={<Protected isUserAuthorized={canLoadListsPage} />}
      >
        <Route index element={<ListPage />} />
        <Route
          index
          path=":listId"
          element={
            isThirdGen ? <ListDetailsPageThirdGen /> : <ListDetailsPage />
          }
        />
      </Route>

      <Route
        path="/admin"
        element={<Protected isUserAuthorized={canLoadAdminPage} />}
      >
        <Route path="variables" element={<VariableManagerPage />} />
        <Route path="groups" element={<VariableGroupPage />} />
      </Route>

      <Route
        path="*"
        element={
          <Navigate to={canLoadRulesPage ? '/policies/dashboard' : '/lists'} />
        }
      />
    </Routes>
  )
}

export default AppRoutes
