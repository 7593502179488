import {
  DecodedValueMap,
  SetQuery,
  StringParam,
  useQueryParams,
} from 'use-query-params'
import { CHECKPOINT } from '@signifyd/http'
import { createEnumParam } from '@signifyd/utils'

const variableManagerQueryConfig = {
  teamId: StringParam,
  checkpoint: createEnumParam(CHECKPOINT),
  sortBy: StringParam,
}

const useVariableManagerQueryParams = (): [
  DecodedValueMap<typeof variableManagerQueryConfig>,
  SetQuery<typeof variableManagerQueryConfig>,
] => {
  const [query, setQuery] = useQueryParams(variableManagerQueryConfig)

  return [query, setQuery]
}

export default useVariableManagerQueryParams
