import { Button, Card } from 'antd'
import { FC } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useStoreState } from 'stores'
import { colorSnow } from '@signifyd/colors'
import { Text, Space } from '@signifyd/components'
import { CHECKPOINT_ACTION } from '@signifyd/http'
import RuleRank from 'core/components/RuleRank'
import RuleRecommendedActionDisplay from 'core/components/RuleRecommendedActionDisplay'
import useDisplayOnlyConditionTree from 'core/hooks/useDisplayOnlyConditionTree/useDisplayOnlyConditionTree'
import { ConditionTreeNode } from 'stores/conditionTree'
import styles from './RuleSummaryCard.less'
import RenderRules from '../RenderRules'

interface Props {
  isRuleValid: boolean
  conditionTree: ConditionTreeNode
  recommendedAction: CHECKPOINT_ACTION | null
  rankInActiveRuleSet?: number | null
  linkToRuleConditions?: string
  editReason?: string
}

const RULE_BODY_STYLE = { backgroundColor: colorSnow, height: '100%' }

const RuleSummaryCard: FC<Props> = ({
  isRuleValid,
  conditionTree,
  recommendedAction,
  rankInActiveRuleSet,
  linkToRuleConditions,
  editReason,
}) => {
  const { t } = useTranslation()
  const { policy, policyFeaturesByName, predictionListMap } = useStoreState(
    (state) => state.ruleModel
  )

  /**
   * NOTE - displayOnlyConditionTree should NOT be used as an input to any API calls(esp POST/PUT requests)
   */
  const displayOnlyConditionTree = useDisplayOnlyConditionTree(
    policy,
    conditionTree,
    policyFeaturesByName,
    predictionListMap
  )

  return (
    <Card
      data-test-id="ruleSummaryCard"
      bordered={false}
      styles={{
        body: RULE_BODY_STYLE,
      }}
      className={styles.card}
    >
      <Text weight="semibold" block>
        {t('ruleValidationPage.ruleSummaryCard.title')}
      </Text>
      <Space size="sm" />
      {isRuleValid ? (
        <>
          <RenderRules conditionTree={displayOnlyConditionTree} />
          <Text size="sm" block>
            {t('ruleCommon.ruleSummaryCard.then')}{' '}
            <RuleRecommendedActionDisplay action={recommendedAction} />
          </Text>
        </>
      ) : (
        <Text data-test-id="rule-not-valid" size="sm" block>
          {t('ruleCommon.ruleSummaryCard.notValid')}
        </Text>
      )}
      {rankInActiveRuleSet && (
        <>
          <Space size="sm" />
          <Text size="sm" block>
            <span className={styles.rankLabel}>
              {t('ruleCommon.ruleSummaryCard.rank')}
            </span>{' '}
            <RuleRank rank={rankInActiveRuleSet} />
          </Text>
        </>
      )}
      {linkToRuleConditions && (
        <>
          <Space size="lg" />
          <Button
            type="link"
            size="small"
            className={styles.linkToRuleConditions}
            href={linkToRuleConditions}
            data-test-id="editConditionsLink"
          >
            {t('ruleCommon.ruleSummaryCard.editConditions')}
          </Button>
        </>
      )}
      {editReason && (
        <>
          <Space size="lg" />
          <Text size="sm" type="secondary">
            <Trans
              i18nKey="ruleCommon.ruleSummaryCard.editReason"
              components={{ bold: <Text weight="semibold" type="secondary" /> }}
              values={{ editReason }}
            />
          </Text>
        </>
      )}
    </Card>
  )
}

export default RuleSummaryCard
