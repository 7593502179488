import { Modal } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'stores'
import { Text } from '@signifyd/components'
import { SIG_MODAL_PROPS } from 'core/constants'
import { ButtonProps } from 'antd/es/button'

interface Props {
  visible: boolean
  isEditing: boolean
  onOk: () => void
  onCancel: () => void
}

const CancelPublishModal: FC<Props> = ({
  visible,
  isEditing,
  onOk,
  onCancel,
}) => {
  const { t } = useTranslation()

  const loading = useStoreState(
    (state) => state.simulationModel.isLoading.cancelRuleSimulation
  )

  return (
    <Modal
      {...SIG_MODAL_PROPS}
      open={visible}
      title={t('publishWithSimulatorPage.cancelPublishModal.title')}
      okText={t('publishWithSimulatorPage.cancelPublishModal.ok')}
      cancelText={t('publishWithSimulatorPage.cancelPublishModal.cancel')}
      closable={false}
      onCancel={onCancel}
      onOk={onOk}
      okButtonProps={
        {
          loading,
          'data-analytics-id': 'confirm-cancel-publish-button',
        } as ButtonProps
      }
      cancelButtonProps={
        {
          loading,
          'data-analytics-id': 'discard-cancel-publish-button',
          type: 'link',
        } as ButtonProps
      }
    >
      <Text data-test-id="cancelPublishModalDescription">
        {t('publishWithSimulatorPage.cancelPublishModal.description', {
          context: isEditing ? 'edit' : 'create',
        })}
      </Text>
    </Modal>
  )
}

export default CancelPublishModal
