import {
  BooleanParam,
  NumberParam,
  QueryParamConfig,
  withDefault,
} from 'use-query-params'
import { SIMULATION_TYPE } from '@signifyd/http'
import { validateNullableEnum } from 'core/utils/zod'
import { EnumLike } from 'core/utils/zod/validateEnum'
import { SELECTED_RULE_ACTION } from 'pages/DashboardPage/DashboardPage.types'
import {
  VIEW_TYPE,
  DISTRIBUTION_TYPE,
  POLICY_IMPACT_SORT_BY,
  POLICY_IMPACT_SORT_DIRECTION,
  SPLIT_SCREEN_STEP,
  SearchParams,
} from 'pages/PublishWithSimulatorPage/PublishWithSimulatorPage.types'

type EnumParam<T extends EnumLike, K extends T[keyof T]> = QueryParamConfig<
  K | null | undefined,
  T[keyof T] | null | undefined
>

/**
 * A custom interface to serialize / deserialize enums inside useQueryParams, with built in validation & support for defaults
 */
const createEnumParam = <T extends EnumLike, K extends T[keyof T]>(
  baseEnum: T,
  defaultValue?: K
): QueryParamConfig<
  K | null | undefined,
  K | Exclude<T[keyof T], null | undefined> | undefined
> => {
  const enumParam: EnumParam<T, K> = {
    encode(value) {
      return value?.toString()
    },

    decode(value) {
      const parsedValue = validateNullableEnum<T, K>(
        baseEnum,
        defaultValue
      ).safeParse(value)

      return parsedValue.success ? parsedValue.data : null
    },
  }

  return withDefault(enumParam, defaultValue, true)
}

/**
 * Typed & validated query params for the publish page - `useQueryParams(PublishPageQueryParams)`
 */
export const PublishPageQueryParams = {
  simulationType: createEnumParam(
    SIMULATION_TYPE,
    SIMULATION_TYPE.CUSTOMER_POLICY
  ),
  viewType: createEnumParam(VIEW_TYPE, VIEW_TYPE.COUNT),
  step: createEnumParam(SPLIT_SCREEN_STEP, SPLIT_SCREEN_STEP.STEP_ONE),
  ruleSetId: NumberParam,
  selectedRuleId: NumberParam,
  selectedRuleAction: createEnumParam(SELECTED_RULE_ACTION),
  distributionType: createEnumParam(DISTRIBUTION_TYPE, DISTRIBUTION_TYPE.GRAPH),
  policyImpactSortBy: createEnumParam(
    POLICY_IMPACT_SORT_BY,
    POLICY_IMPACT_SORT_BY.CHANGE
  ),
  policyImpactSortDirection: createEnumParam(
    POLICY_IMPACT_SORT_DIRECTION,
    POLICY_IMPACT_SORT_DIRECTION.DESCEND
  ),
  editedPolicyId: NumberParam,
  simulationId: NumberParam,
  editPilot: BooleanParam,
  draftPilotRuleSetId: NumberParam,
}

export interface PublishWithSimulatorPageFilters {
  viewType: VIEW_TYPE
  distributionType: DISTRIBUTION_TYPE
  policyImpactSortBy: POLICY_IMPACT_SORT_BY
  policyImpactSortDirection: POLICY_IMPACT_SORT_DIRECTION
  step: SPLIT_SCREEN_STEP
  ruleSetId: number | null
  selectedRuleId: number | null
  selectedRuleAction: SELECTED_RULE_ACTION | null
}

export const DEFAULT_FILTERS: PublishWithSimulatorPageFilters = {
  viewType: VIEW_TYPE.COUNT,
  step: SPLIT_SCREEN_STEP.STEP_ONE,
  ruleSetId: null,
  selectedRuleId: null,
  selectedRuleAction: null,
  distributionType: DISTRIBUTION_TYPE.GRAPH,
  policyImpactSortBy: POLICY_IMPACT_SORT_BY.CHANGE,
  policyImpactSortDirection: POLICY_IMPACT_SORT_DIRECTION.DESCEND,
}

export const DEFAULT_SEARCH_PARAMS: SearchParams = {
  step: SPLIT_SCREEN_STEP.STEP_ONE,
  viewType: VIEW_TYPE.COUNT,
  distributionType: DISTRIBUTION_TYPE.GRAPH,
  policyImpactSortBy: POLICY_IMPACT_SORT_BY.CHANGE,
  policyImpactSortDirection: POLICY_IMPACT_SORT_DIRECTION.DESCEND,
}
