import { Button } from 'antd'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState, useStoreActions } from 'stores'
import { colorGlitter } from '@signifyd/colors'
import { T2, SigTag, Text, Space } from '@signifyd/components'
import EditPolicyModal from 'pages/DashboardPage/containers/EditPolicyModal'
import styles from './NewRulePageHeader.less'

interface Props {
  isEditing: boolean
}
const RulePageHeader: FC<Props> = ({ isEditing }) => {
  const { t } = useTranslation()
  const [modalVisible, setModalVisible] = useState(false)
  const policy = useStoreState((state) => state.ruleModel.policy)
  const { updatePolicyDetails } = useStoreActions(
    (actions) => actions.ruleModel
  )

  if (!policy) {
    return null
  }

  return (
    <div className={styles.header} data-test-id="newRulePageHeader">
      <T2 className={styles.title}>
        <SigTag type="primary" color={colorGlitter} className={styles.tag}>
          <Text size="sm">
            {t('common.checkpoint.label')}:{' '}
            {t(`common.checkpoint.${policy.checkpoint}`)}
          </Text>
        </SigTag>
        <span>{policy.name}</span>
        {!isEditing && (
          <Button
            type="link"
            className={styles.editBtn}
            onClick={() => setModalVisible(true)}
          >
            {t('ruleCommon.editRuleModal.triggerBtnText')}
          </Button>
        )}
      </T2>
      <Space size="lg" />
      <EditPolicyModal
        policyToEdit={policy}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onSubmit={({ name, description }) => {
          updatePolicyDetails({ name, description }).then(() => {
            setModalVisible(false)
          })
        }}
      />
    </div>
  )
}

export default RulePageHeader
