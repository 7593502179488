import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useStoreActions, useStoreState } from 'stores'
import { useQueryParams } from 'use-query-params'
import {
  RULE_SET_STAGE,
  RuleResponse,
  RuleSet,
  RuleSimulation,
  SIMULATION_STATUS,
  SIMULATION_TYPE,
} from '@signifyd/http'
import useCreateRuleSet from 'core/queries/useCreateRuleSet/useCreateRuleSet'
import useGetRuleSet from 'core/queries/useGetRuleSet/useGetRuleSet'
import useUpdateRuleSet from 'core/queries/useUpdateRuleSet/useUpdateRuleSet'
import { PublishPageQueryParams } from '../../PublishWithSimulatorPage.config'
import { SPLIT_SCREEN_STEP } from '../../PublishWithSimulatorPage.types'
import useGetAllRules from '../useGetAllRules'
import useGetRulesByRuleSet from '../useGetRulesByRuleSet/useGetRulesByRuleSet'
import { useTypedRouteParams } from '../useTypedRouteParams/useTypedRouteParams'

interface UseInitializePublishWithSimulationResult {
  expiredRules: Array<RuleResponse>
  isInitialLoading: boolean
  rules?: Array<RuleResponse>
  ruleSet?: RuleSet
  ruleSimulation: RuleSimulation | null
}

export const useInitializePublishWithSimulation =
  (): UseInitializePublishWithSimulationResult => {
    const navigate = useNavigate()

    const { teamId, checkpoint } = useTypedRouteParams()
    const [queryParams, setQueryParams] = useQueryParams(PublishPageQueryParams)
    const {
      editedPolicyId,
      editPilot,
      draftPilotRuleSetId,
      ruleSetId,
      simulationId,
      simulationType,
      step,
    } = queryParams

    const { isLoading: isLoadingCreateRuleSet, mutate: mutateCreateRuleSet } =
      useCreateRuleSet()

    const { data: rules, isInitialLoading: isLoadingAllRules } = useGetAllRules(
      teamId,
      checkpoint
    )
    const { data: ruleSet, isInitialLoading: isLoadingRuleSet } =
      useGetRuleSet(ruleSetId)
    const { expiredRules } = useGetRulesByRuleSet(ruleSetId)
    const { isError: updateRuleSetError } = useUpdateRuleSet()

    const { ruleSimulation, isLoading: isLoadingSimulation } = useStoreState(
      (state) => state.simulationModel
    )
    const {
      stopPollAction,
      resetState: resetSimulationStore,
      getRuleSimulation,
    } = useStoreActions((state) => state.simulationModel)

    const isInitialLoading =
      isLoadingAllRules ||
      isLoadingRuleSet ||
      isLoadingCreateRuleSet ||
      isLoadingSimulation.getRuleSimulation
    const isEditing = !!editedPolicyId

    useEffect(() => {
      // Actually put the defaults into the query string on page load (have to do this manually for now)
      // https://github.com/pbeshai/use-query-params/issues/138
      setQueryParams(queryParams, 'replaceIn')
    }, [queryParams, setQueryParams])

    useEffect(() => {
      return () => {
        // resetState all stores and stop polling when navigate to another page
        setQueryParams({ simulationId: undefined })
        stopPollAction()
        resetSimulationStore()
      }
    }, [resetSimulationStore, setQueryParams, stopPollAction])

    useEffect(() => {
      if (!teamId || isInitialLoading) {
        return
      }

      const shouldCreateRuleSetForNewSimulation =
        !ruleSimulation &&
        !simulationId &&
        !ruleSetId &&
        rules &&
        !isLoadingCreateRuleSet

      const shouldCreateDraftRuleSetForPilotSimulation =
        ruleSimulation &&
        editPilot &&
        ruleSimulation.status !== SIMULATION_STATUS.FINISHED &&
        !draftPilotRuleSetId &&
        rules &&
        ruleSet &&
        !isLoadingCreateRuleSet

      if (shouldCreateRuleSetForNewSimulation) {
        mutateCreateRuleSet(
          {
            teamId,
            checkpoint,
            rules,
          },
          {
            onSuccess: (ruleSet) => {
              setQueryParams(
                {
                  ruleSetId: ruleSet.ruleSetId,
                  selectedRuleAction: undefined,
                },
                'replaceIn'
              )
            },
          }
        )
      }

      if (shouldCreateDraftRuleSetForPilotSimulation) {
        mutateCreateRuleSet(
          {
            rules: ruleSimulation.ruleIds.map(
              (ruleId) => rules.find((rule) => rule.ruleId === +ruleId)!
            ),
            teamId,
            checkpoint,
            isForPilotSimulation: true,
          },
          {
            onSuccess: (draftPilotRuleSet) => {
              setQueryParams(
                {
                  draftPilotRuleSetId: draftPilotRuleSet.ruleSetId,
                },
                'replaceIn'
              )
            },
          }
        )
      }
    }, [
      checkpoint,
      draftPilotRuleSetId,
      editPilot,
      isInitialLoading,
      isLoadingCreateRuleSet,
      mutateCreateRuleSet,
      rules,
      ruleSet,
      ruleSetId,
      ruleSimulation,
      setQueryParams,
      simulationId,
      teamId,
    ])

    useEffect(() => {
      if (
        !teamId ||
        !checkpoint ||
        ruleSet?.ruleSetStage === RULE_SET_STAGE.PUBLISHED
      ) {
        navigate(
          `/policies/dashboard${!!teamId && !!checkpoint ? `?checkpoint=${checkpoint}&teamId=${teamId}` : ''}`
        )

        return
      }

      const isLoadingExistingSimulation =
        !!ruleSimulation && (!simulationId || !ruleSetId)

      if (isLoadingExistingSimulation) {
        setQueryParams({
          ruleSetId: ruleSimulation.ruleSetId,
          simulationId: ruleSimulation.simulationId,
        })

        return
      }

      if (
        ruleSimulation &&
        !!simulationId &&
        simulationId !== ruleSimulation.simulationId
      ) {
        setQueryParams({
          ruleSetId: ruleSimulation.ruleSetId,
          draftPilotRuleSetId: undefined,
          simulationId: ruleSimulation.simulationId,
        })

        return
      }

      const isLoadingActivePilot =
        simulationType === SIMULATION_TYPE.PILOT_POLICY &&
        !!simulationId &&
        !ruleSimulation &&
        !isLoadingSimulation.getRuleSimulation

      const isLiveSimulationRunning =
        simulationType !== SIMULATION_TYPE.PILOT_POLICY &&
        ruleSimulation?.status === SIMULATION_STATUS.RUNNING &&
        step === SPLIT_SCREEN_STEP.STEP_ONE

      const cannotRunSimulation =
        updateRuleSetError && step === SPLIT_SCREEN_STEP.STEP_TWO

      const isSimulationMismatch =
        ruleSimulation?.simulationType === SIMULATION_TYPE.CUSTOMER_POLICY &&
        simulationType === SIMULATION_TYPE.PILOT_POLICY

      const hasNoSimulationWithPilot =
        !isLoadingSimulation.getRuleSimulation &&
        !isLoadingSimulation.createRuleSimulation &&
        !simulationId &&
        simulationType === SIMULATION_TYPE.PILOT_POLICY

      if (isSimulationMismatch || hasNoSimulationWithPilot) {
        setQueryParams({
          ...queryParams,
          simulationType: SIMULATION_TYPE.CUSTOMER_POLICY,
        })

        return
      }

      if (isLoadingActivePilot) {
        getRuleSimulation(simulationId)
        setQueryParams({
          step: SPLIT_SCREEN_STEP.STEP_TWO,
          editPilot: true,
        })
      } else if (isLiveSimulationRunning || isEditing) {
        setQueryParams({ step: SPLIT_SCREEN_STEP.STEP_TWO })
      } else if (cannotRunSimulation) {
        setQueryParams({ step: SPLIT_SCREEN_STEP.STEP_ONE })
      }
    }, [
      checkpoint,
      isEditing,
      isLoadingSimulation.getRuleSimulation,
      isLoadingSimulation.createRuleSimulation,
      getRuleSimulation,
      navigate,
      queryParams,
      ruleSet,
      ruleSetId,
      ruleSimulation,
      setQueryParams,
      simulationId,
      simulationType,
      step,
      teamId,
      updateRuleSetError,
    ])

    return {
      isInitialLoading,
      expiredRules,
      rules,
      ruleSet,
      ruleSimulation,
    }
  }

export default useInitializePublishWithSimulation
