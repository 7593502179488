import { PlusOutlined } from '@ant-design/icons'
import { Select, Divider } from 'antd'
import { FC, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState, useStoreActions } from 'stores'
import { Text } from '@signifyd/components'
import { DUPLICATE_PREDICTION_LIST_ENTRY_ACTION } from '@signifyd/http'
import useCreatePredictionList from 'core/hooks/useCreatePredictionList'
import {
  useCreatePredictionListEntriesWithCSV,
  useCreatePredictionListEntriesWithJSON,
} from 'core/hooks/useCreatePredictionListEntries'
import useSelectedPredictionListName from 'core/hooks/useSelectedPredictionListName/useSelectedPredictionListName'
import { getPredictionListParamsForTeam } from 'core/http'
import CreateListFromPolicyModal, {
  PredictionListOperationStatuses,
} from 'pages/ListPage/containers/CreateListModal/CreateListFromPolicyModal'
import { RcFile } from 'antd/es/upload'
import { ConditionTreeLeafNode } from 'stores/conditionTree'
import usePredictionListOptions from './usePredictionListOptions'
import styles from '../ConditionElement.less'

export interface Props {
  leafNode: ConditionTreeLeafNode
  onChangeValue: (value: string | Array<string>) => void
  ruleTeamId: number
}

const ValueForListSelect: FC<Props> = ({
  leafNode,
  onChangeValue,
  ruleTeamId,
}) => {
  const [modalVisible, setModalVisible] = useState(false)

  const [listId, setListId] = useState<number>()

  const { predictionListMap } = useStoreState((state) => state.ruleModel)
  const selectedPredictionListName = useSelectedPredictionListName(
    predictionListMap,
    leafNode
  )

  const { listPredictionLists } = useStoreActions(
    (actions) => actions.ruleModel
  )

  const { t } = useTranslation()
  const mounted = useRef(false)

  const createPredictionList = useCreatePredictionList(false)

  const createEntriesWithCSV = useCreatePredictionListEntriesWithCSV()

  const createEntriesWithJSON = useCreatePredictionListEntriesWithJSON()

  const listStatuses: PredictionListOperationStatuses = {
    createPredictionList: {
      isLoading: createPredictionList.isLoading,
      errorMessage: createPredictionList.error?.message,
    },
    createEntriesCsv: {
      isLoading: createEntriesWithCSV.isLoading,
      errorMessage: createEntriesWithCSV.error?.message,
    },
    createEntriesJson: {
      isLoading: createEntriesWithJSON.isLoading,
      errorMessage: createEntriesWithJSON.error?.message,
    },
  }

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  const predictionListFeature = leafNode.ruleFeature?.predictionListFeature

  const options = usePredictionListOptions(predictionListFeature)

  if (!predictionListFeature || !options) {
    return null
  }

  return (
    <>
      <Select
        placeholder={t(
          'ruleConditionsPage.conditionInputs.predictionListSelect.placeholder'
        )}
        notFoundContent={t(
          'ruleConditionsPage.conditionInputs.predictionListSelect.notFoundContent'
        )}
        optionLabelProp="value"
        data-test-id="valueForListSelect"
        value={selectedPredictionListName}
        onSelect={(listId: string) => {
          onChangeValue(listId)
        }}
        className={styles.input}
        dropdownRender={(menu) => {
          return (
            <div>
              <Text type="secondary">
                {t(
                  `listCommon.listTypeUpperCase.${predictionListFeature.type}`
                )}
              </Text>
              {menu}
              <Divider />
              <div
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => setModalVisible(true)}
              >
                <PlusOutlined />
                <Text data-test-id="createNewList">
                  {t(
                    'ruleConditionsPage.conditionInputs.predictionListSelect.createList'
                  )}
                </Text>
              </div>
            </div>
          )
        }}
        options={options}
      />
      <CreateListFromPolicyModal
        listType={predictionListFeature.type}
        visible={modalVisible}
        listId={listId}
        predictionListOperationStatuses={listStatuses}
        onCreateList={async (params) => {
          const payload = {
            ...params,
            teamId: ruleTeamId,
          }

          const listId = await createPredictionList.mutateAsync(payload)

          setListId(listId)

          const listPredictionListsParams =
            getPredictionListParamsForTeam(ruleTeamId)

          listPredictionLists(listPredictionListsParams)
        }}
        onUploadWithJSON={async (values) => {
          if (listId) {
            await createEntriesWithJSON.mutateAsync({
              predictionListId: listId,
              payload: {
                type: predictionListFeature.type,
                values,
                duplicateEntryAction:
                  DUPLICATE_PREDICTION_LIST_ENTRY_ACTION.OVERWRITE,
              },
            })

            if (!createEntriesWithJSON.isLoading && mounted.current) {
              setModalVisible(false)
            }
          }
        }}
        onUploadWithCSV={async (file: RcFile) => {
          if (listId) {
            await createEntriesWithCSV.mutateAsync({
              predictionListId: listId,
              type: predictionListFeature.type,
              file,
            })

            if (mounted.current) {
              setModalVisible(false)
            }
          }
        }}
        onClose={() => {
          if (listId) {
            onChangeValue(`${listId}`)
          }

          setListId(undefined)

          setModalVisible(false)
        }}
      />
    </>
  )
}

export default ValueForListSelect
